import React, {useState} from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import './i18n';


import LandingPage from '../src/LandingPage/LandingPage';

// const Dashboard = lazy(()=>import('./Views/User/Dashboard/Dashboard'));
import Dashboard  from './Views/User/Dashboard/Dashboard';

// const Reels = lazy(()=> import('./Views/User/Reels/Reels'));
import Reels  from './Views/User/Reels/Reels';

// const Food = lazy(()=>import('./Views/User/searchscreen/SearchFood'));

import  Cart  from './Views/User/Cart/cart';
// const Cart = lazy(()=>import('./Views/User/Cart/cart'));



// const Profilescreen = lazy(()=>import('./Views/User/profilepage/Profilescreen'));

import  Profilescreen  from './Views/User/profilepage/Profilescreen';


// const Profilesettings = lazy(()=>import('./Views/User/profilepage/Profilesettings'));

import  Profilesettings  from './Views/User/profilepage/Profilesettings';

// const SearchHomeScreen = lazy(()=>import('./Views/User/searchscreen/SearchHomeScreen'));


// const Restaurant = lazy(()=>import('./Views/User/searchscreen/Restaurants'));

// const Offers = lazy(()=>import('./Views/User/searchscreen/Offers'));


// const ReelsAndPosts = lazy(()=>import('./Views/User/searchscreen/ReelsAndPosts'));


// const Bloggers = lazy(()=>import('./Views/User/searchscreen/Bloggers'));


// const PostDetail = lazy(()=>import('./Views/User/profilepage/PostDetail'));

import  PostDetail  from './Views/User/profilepage/PostDetail';


// const ReelDetail = lazy(()=>import('./Views/User/profilepage/ReelDetail'));
import  ReelDetail  from './Views/User/profilepage/ReelDetail';

// const SavedItemDetail = lazy(()=>import('./Views/User/profilepage/SavedItemDetail'));
import  SavedItemDetail  from './Views/User/profilepage/SavedItemDetail';

// const Inbox = lazy(()=>import('./Views/User/Inbox/Inbox'));

import  Inbox  from './Views/User/Inbox/Inbox';

// const Payment = lazy(()=>import('./Views/User/Payment/Payment'));

import  Payment  from './Views/User/Payment/Payment';

// const PaymentSuccess = lazy(()=>import('./Views/User/Payment/PaymentSuccess'));

import  PaymentSuccess  from './Views/User/Payment/PaymentSuccess';

// const PaymentFail = lazy(()=>import('./Views/User/Payment/PaymentFail'));

import  PaymentFail  from './Views/User/Payment/PaymentFail';


// const GoogleMap = lazy(()=>import('./Views/User/Tracking/GoogleMap'));

import  GoogleMap  from './Views/User/Tracking/GoogleMap';


// const QRCodeScanner = lazy(()=>import('./Views/User/QR Code Scanner/QRCodeScanner'));

import  QRCodeScanner  from './Views/User/QR Code Scanner/QRCodeScanner';


// const RestaurantPage = lazy(()=>import('./Views/User/Reasaurant page/RestaurantPage'));

import  RestaurantPage  from './Views/User/Reasaurant page/RestaurantPage';

// const FoodDescription = lazy(()=>import('./Views/User/Reasaurant page/FoodDescription'));

import  FoodDescription  from './Views/User/Reasaurant page/FoodDescription';

// const  Editprofile = lazy(()=>import('./Views/User/profilepage/Editprofile'));

import  Editprofile  from './Views/User/profilepage/Editprofile';

// const  ChangePassword = lazy(()=>import('./Views/User/profilepage/Change Password'));

import  ChangePassword  from './Views/User/profilepage/Change Password';

// const  Changelanguage = lazy(()=>import('./Views/User/profilepage/changelanguage'));
import  Changelanguage  from './Views/User/profilepage/changelanguage';

// const  UpdateAddresses = lazy(()=>import('./Views/User/profilepage/Update Addresses'));
import  UpdateAddresses  from './Views/User/profilepage/Update Addresses';

// const  Switchaccount = lazy(()=>import('./Views/User/profilepage/Switchaccount'));

import  Switchaccount  from './Views/User/profilepage/Switchaccount';


// import Tabs from './Views/User/FakeSearch/Tabs';
// const  Tabs = lazy(()=>import('./Views/User/Search/Tabs'));
import  Tabs  from './Views/User/Search/Tabs';

// const  Foods = lazy(()=>import('./Views/User/Search/Food'));
import  Foods  from './Views/User/Search/Food';


// const  Restaurants = lazy(()=>import('./Views/User/Search/Restaurant'));
import  Restaurants  from './Views/User/Search/Restaurant';

// const  Post = lazy(()=>import('./Views/User/Search/Post'));
import  Post  from './Views/User/Search/Post';


// const  Blogger = lazy(()=>import('./Views/User/Search/Blogger'));
import  Blogger  from './Views/User/Search/Blogger';

// const  Offer = lazy(()=>import('./Views/User/Search/Offer'));
import  Offer  from './Views/User/Search/Offer';

// const PageNotFound = lazy(()=>import('./Views/User/PageNotFound/PageNotFound'));

import  PageNotFound  from './Views/User/PageNotFound/PageNotFound';


import { CartProvider } from './CartProvider'; // Import the CartContext






function Userrouter() {


    const [tabName,setTabName] = useState('');
    const [searchQuery,setSearchQuery] = useState('');

    // console.log(typeof setSearchQuery);

    // tabName={tabName} searchQuery={searchQuery} setSearchQuery={setSearchQuery}
    // setTabName={setTabName} searchQuery={searchQuery}

    return (
        <div>
            
            <BrowserRouter>

                <CartProvider>
       
                    <Routes>
                        
                        <Route path='/' element={<LandingPage></LandingPage>}></Route>
                        <Route path='/Dashboard' element={<Dashboard></Dashboard>}></Route>
                        <Route path='/reels' element={<Reels />}></Route>

                        <Route path='/Inbox' element={<Inbox></Inbox>}></Route>


                        <Route path='/Cart' element={<Cart></Cart>}></Route>
                        <Route path='/Payment' element={<Payment></Payment>}></Route>
                        <Route path='/PaymentSuccess' element={<PaymentSuccess></PaymentSuccess>}></Route>
                        <Route path='/PaymentFail' element={<PaymentFail></PaymentFail>}></Route>
                        <Route path='/GoogleMap' element={<GoogleMap></GoogleMap>}></Route>
                        <Route path='/QRCodeScanner' element={<QRCodeScanner></QRCodeScanner>}></Route>
                        <Route path='/RestaurantPage' element={<RestaurantPage></RestaurantPage>}></Route>
                        <Route path='/FoodDescription' element={<FoodDescription></FoodDescription>}></Route>

                        <Route path='/Profilescreen' element={<Profilescreen></Profilescreen>}></Route>
                        <Route path='/profilesettings' element={<Profilesettings></Profilesettings>}></Route>
                        {/* <Route path='/SearchHomescreen' element={<SearchHomeScreen></SearchHomeScreen>}></Route>
                    <Route path='/Restaurant' element={<Restaurant></Restaurant>}></Route>
                    <Route path='/Offers' element={<Offers></Offers>}></Route>
                    <Route path='/Food' element={<Food></Food>}></Route>
                    <Route path='/ReelsAndPosts' element={<ReelsAndPosts></ReelsAndPosts>}></Route>
                    <Route path='/Bloggers' element={<Bloggers></Bloggers>}></Route> */}
                        <Route path='/post/:postId' element={<PostDetail />} /> 
                        <Route path='/reel/:reelId' element={<ReelDetail />} />
                        <Route path='/saved/:savedId' element={<SavedItemDetail />} />

                        <Route path='/edit-profile' element={<Editprofile />} />
                        <Route path='/change-password' element={<ChangePassword />} />
                        <Route path='/change-language' element={<Changelanguage/>} />
                        <Route path='/update-addresses' element={<UpdateAddresses/>} />
                        <Route path='/switch-account' element={<Switchaccount/>} />

                        <Route path='/Search' element={<Tabs tabName={tabName} setTabName={setTabName} searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>} />
                        <Route path='/Foods' element={<Foods/>} />
                        <Route path='/Restaurants' element={<Restaurants/>} />
                        <Route path='/Post' element={<Post/>} />
                        <Route path='/Blogger' element={<Blogger/>} />
                        <Route path='/Offer' element={<Offer/>} />

                        <Route path='*' element={<PageNotFound/>}/>

                  
  

                    </Routes>

                </CartProvider>
            </BrowserRouter>
           
        </div>
    );
}

export default Userrouter;

