import React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';

import './../../../Styles/Cart/AddressListModel.css';
import AddNewAddress from './AddAddress';

function AddAddressModel({
    handleCloseAddress,
    open,
    handleAddAddress,
    getAddress,
}) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleCloseAddress}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="styleAddressModel">
                    <AddNewAddress
                        handleClose={handleCloseAddress}
                        handleAddAddress={handleAddAddress}
                        getAddress={getAddress}
                    />
                </Box>
            </Modal>
        </div>
    );
}

export default AddAddressModel;
