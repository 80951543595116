// import VendorSideMenuBar from './VendorLayouts/VendorSideMenuBar';
// import VendorTopMenuBar from './VendorLayouts/VendorTopMenuBar';
// import React, { useState } from 'react';
// import '../../VendorStyles/VendorDashboard.css';
// import NewOrders from './NewOrders';
// import Preparing from './Preparing';
// import Delivered from './Delivered';
// import Declined from './Declined';
// import Menu from './Menu';
// import Dashboard from './Dashboard';
// import NewOrdersRightbar from './NewOrdersRightbar';
// import PreparingRightbar from './PreparingRightbar';
// import DeliveredRightbar from './DeliveredRightbar';
// import DeclinedRightbar from './DeclinedRightbar';
// import MenuRightbar from './MenuRightbar';
// import DashboardRightbar from './DashboardRightbar';
// import filtericon from '../../assets/Filtericon.png';
// import { Button } from '@mui/material';
// import '../../VendorStyles/VendorOrders.css';
// import OrderRightbar from './OrderRightbar';

// function VendorOrders() {
//     const [activeTab, setActiveTab] = useState(null);
//     const orders = [
//         { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' },
//         { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Declined', price: '$ 10' },
//         { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' },
//         { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' },
//         { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Declined', price: '$ 10' },
//         { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' },
//         { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' },
//         { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' }
//     ];
//     const tabContents = [
//         <NewOrders key="new-orders" />,
//         <Preparing key="preparing" />,
//         <Delivered key="delivered" />,
//         <Declined key="declined" />,
//         <Menu key="menu" />,
//         <Dashboard key="dashboard" />
//     ];

//     const sidebarContents = [
//         <NewOrdersRightbar key="new-orders-rightbar" />,
//         <PreparingRightbar key="preparing-rightbar" />,
//         <DeliveredRightbar key="delivered-rightbar" />,
//         <DeclinedRightbar key="declined-rightbar" />,
//         <MenuRightbar key="menu-rightbar" />,
//         <DashboardRightbar key="dashboard-rightbar" />
//     ];

//     return (
//         <div>
//             <VendorSideMenuBar setActiveTab={setActiveTab}/>
//             <VendorTopMenuBar />
//             <div className="dashboard-container">
//                 <div className="dashboard-left">
//                     <div className="tabs-container">
//                         <button style={{ borderRadius: '5px 0px 0px 5px' }} className={`tab-button ${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}><h5 className='tabs-text'>New Orders</h5></button>
//                         <button className={`tab-button ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}><h5 className='tabs-text'>Preparing</h5></button>
//                         <button className={`tab-button ${activeTab === 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}><h5 className='tabs-text'>Delivered</h5></button>
//                         <button className={`tab-button ${activeTab === 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}><h5 className='tabs-text'>Declined</h5></button>
//                         <button className={`tab-button ${activeTab === 4 ? 'active' : ''}`} onClick={() => setActiveTab(4)}><h5 className='tabs-text'>Menu</h5></button>
//                         <button style={{ borderRadius: '0px 5px 5px 0px' }} className={`tab-button ${activeTab === 5 ? 'active' : ''}`} onClick={() => setActiveTab(5)}><h5 className='tabs-text'>Dashboard</h5></button>
//                     </div>
//                     {activeTab === null ? (
//                         <div className="vendororders-main2-cont">
//                             <div className='vendororders-filter-container'>
//                                 <Button className='vendororders-filter'
//                                     startIcon={<img src={filtericon} width="20px" height="18px" alt="filter icon" />}
//                                     sx={{
//                                         borderRadius: '22px',
//                                         textTransform: 'none',
//                                         fontWeight: 'bold',
//                                         backgroundColor: '#FFFFFF',
//                                         color: '#50555C',
//                                         height: '35px',
//                                         border: '#000',
//                                         boxShadow: '0px 0px 4px 0px #0000001A',
//                                         width: '90px',
//                                     }}
//                                 >
//                                     Filter
//                                 </Button>
//                             </div>
//                             <div className='vendor-orders-main-container'>
//                                 {orders.map((order, index) => (
//                                     <div key={index} className='vendor-orders-single-container'>
//                                         <div className='vendor-orders-single-top1'>
//                                             <p className='vendor-orders-order-id-text'>Order ID: <span className='vendor-orders-order-id'>{order.id}</span></p>
//                                             <div className='vendor-orders-order-date-and-time'>
//                                                 <p className='vendor-orders-order-time'>{order.time}</p>
//                                                 <p className='vendor-orders-order-date'>{order.date}</p>
//                                             </div>
//                                         </div>
//                                         <div className="vendor-orders-dotted-line"></div>
//                                         <div className='vendor-order-items-container'>
//                                             <p className='vendor-order-items'>{order.item1}</p>
//                                             <p className='vendor-order-items'>{order.item2}</p>
//                                             <p className='vendor-order-items'>{order.item3}</p>
//                                             <p className='vendor-order-items'>{order.item4}</p>
//                                         </div>
//                                         <div className="vendor-orders-dotted-line"></div>
//                                         <div className='vendor-order-bottom-container'>
//                                             <div className='vendor-order-bottom-container1'>
//                                                 {order.status === 'Delivered' ? (
//                                                     <div className='vendor-orders-delivered-circle'>
//                                                         <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                             <path d="M1 4L4 7L10 1" stroke="white" strokeWidth="2" strokeLinecap="round" />
//                                                         </svg>
//                                                     </div>
//                                                 ) : (
//                                                     <div className='vendor-orders-declined-circle'>
//                                                         <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                             <path d="M2.8587 3.50005L0.133037 0.774253C-0.0443449 0.596951 -0.0443449 0.310278 0.133037 0.132976C0.310335 -0.0443255 0.597001 -0.0443255 0.7743 0.132976L3.50004 2.85878L6.2257 0.132976C6.40308 -0.0443255 6.68967 -0.0443255 6.86696 0.132976C7.04435 0.310278 7.04435 0.596951 6.86696 0.774253L4.1413 3.50005L6.86696 6.22585C7.04435 6.40315 7.04435 6.68983 6.86696 6.86713C6.77861 6.95557 6.66243 7 6.54633 7C6.43024 7 6.31414 6.95557 6.2257 6.86713L3.50004 4.14133L0.7743 6.86713C0.685858 6.95557 0.569763 7 0.453668 7C0.337573 7 0.221478 6.95557 0.133037 6.86713C-0.0443449 6.68983 -0.0443449 6.40315 0.133037 6.22585L2.8587 3.50005Z" fill="white" />
//                                                         </svg>
//                                                     </div>
//                                                 )}
//                                                 <p className='vendor-order-status-text'>
//                                                     {order.status}
//                                                 </p>
//                                             </div>
//                                             <p className='vendor-order-price-text'>
//                                                 {order.price}
//                                             </p>
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>
//                         </div>
//                     ) : (
//                         tabContents[activeTab]
//                     )}
//                 </div>
//                 <div className="rightsidebar-container">
//                     {activeTab === null ? (
//                         <OrderRightbar></OrderRightbar>
//                     ) : (
//                         sidebarContents[activeTab]
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default VendorOrders;


import VendorSideMenuBar from './VendorLayouts/VendorSideMenuBar';
import VendorTopMenuBar from './VendorLayouts/VendorTopMenuBar';
import React, { useState , useEffect} from 'react';
import '../../VendorStyles/VendorDashboard.css';
import NewOrders from './NewOrders';
import Preparing from './Preparing';
import Delivered from './Delivered';
import Declined from './Declined';
import Menu from './Menu';
import Dashboard from './Dashboard';
import NewOrdersRightbar from './NewOrdersRightbar';
import PreparingRightbar from './PreparingRightbar';
import DeliveredRightbar from './DeliveredRightbar';
import DeclinedRightbar from './DeclinedRightbar';
import MenuRightbar from './MenuRightbar';
import DashboardRightbar from './DashboardRightbar';
import filtericon from '../../assets/Filtericon.png';
import { Button } from '@mui/material';
import '../../VendorStyles/VendorOrders.css';
import OrderRightbar from './OrderRightbar';
import axios from 'axios';
function VendorOrders() {
    const [activeTab, setActiveTab] = useState(null);
    // const orders = [
    //     { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' },
    //     { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Declined', price: '$ 10' },
    //     { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' },
    //     { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' },
    //     { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Declined', price: '$ 10' },
    //     { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' },
    //     { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' },
    //     { id: 6354, time: '10:30 AM', date: '22 Mar', item1: '1X Chicken Soft Noodles', item2: '1X Chicken Soft Noodles', item3: '3X Mutton Biryani', item4: '3X Mutton Biryani', status: 'Delivered', price: '$ 10' }
    // ];
    const [orders, setOrders] = useState([]);
    const price = 10;
    const items=[{id:'1',itemname:'Chicken Soft Noodles',qty:1,price:10},
        {id:'2',itemname:'Chicken Soft Noodles',qty:2,price:20},
        {id:'3',itemname:'Chicken Soft Noodles',qty:6,price:60},
        {id:'4',itemname:'Chicken Soft Noodles',qty:1,price:10}
    ];
    useEffect(() => {
        // Fetch data from the API
        axios.get('http://localhost:5002/api/order/vendor/1')
            .then(response => {
                const formattedOrders = response.data.allOrdersDetails
                    .filter(order => order.Status_id === 3 || order.Status_id === 5) // Filter orders with Status_id === 4 or 5
                    .map(order => {
                        const createdAt = new Date(order.Created_at);
                        const time = createdAt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                        const date = `${createdAt.getDate()} ${createdAt.toLocaleString('en-US', { month: 'short' })}`;
                        const status = order.Status_id === 3 ? 'Declined' : 'Delivered';
 
                        return {
                            id: order.ID,
                            time,
                            date,
                            status
                        };
                    });
                setOrders(formattedOrders);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const tabContents = [
        <NewOrders key="new-orders" />,
        <Preparing key="preparing" />,
        <Delivered key="delivered" />,
        <Declined key="declined" />,
        <Menu key="menu" />,
        <Dashboard key="dashboard" />
    ];

    const sidebarContents = [
        <NewOrdersRightbar key="new-orders-rightbar" />,
        <PreparingRightbar key="preparing-rightbar" />,
        <DeliveredRightbar key="delivered-rightbar" />,
        <DeclinedRightbar key="declined-rightbar" />,
        <MenuRightbar key="menu-rightbar" />,
        <DashboardRightbar key="dashboard-rightbar" />
    ];

    return (
        <div>
            <VendorSideMenuBar />
            <VendorTopMenuBar />
            <div className="dashboard-container">
                <div className="dashboard-left">
                    <div className="tabs-container">
                        <button style={{ borderRadius: '5px 0px 0px 5px' }} className={`tab-button ${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}><h5 className='tabs-text'>New Orders</h5></button>
                        <button className={`tab-button ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}><h5 className='tabs-text'>Preparing</h5></button>
                        <button className={`tab-button ${activeTab === 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}><h5 className='tabs-text'>Delivered</h5></button>
                        <button className={`tab-button ${activeTab === 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}><h5 className='tabs-text'>Declined</h5></button>
                        <button className={`tab-button ${activeTab === 4 ? 'active' : ''}`} onClick={() => setActiveTab(4)}><h5 className='tabs-text'>Menu</h5></button>
                        <button style={{ borderRadius: '0px 5px 5px 0px' }} className={`tab-button ${activeTab === 5 ? 'active' : ''}`} onClick={() => setActiveTab(5)}><h5 className='tabs-text'>Dashboard</h5></button>
                    </div>
                    {activeTab === null ? (
                        <div className="vendororders-main2-cont">
                            <div className='vendororders-filter-container'>
                                <Button className='vendororders-filter'
                                    startIcon={<img src={filtericon} width="20px" height="18px" alt="filter icon" />}
                                    sx={{
                                        borderRadius: '22px',
                                        textTransform: 'none',
                                        fontWeight: 'bold',
                                        backgroundColor: '#FFFFFF',
                                        color: '#50555C',
                                        height: '35px',
                                        border: '#000',
                                        boxShadow: '0px 0px 4px 0px #0000001A',
                                        width: '90px',
                                    }}
                                >
                                    Filter
                                </Button>
                            </div>
                            <div className='vendor-orders-main-container'>
                                {orders.map((order, index) => (
                                    <div key={index} className='vendor-orders-single-container'>
                                        <div className='vendor-orders-single-top1'>
                                            <p className='vendor-orders-order-id-text'>Order ID: <span className='vendor-orders-order-id'>{order.id}</span></p>
                                            <div className='vendor-orders-order-date-and-time'>
                                                <p className='vendor-orders-order-time'>{order.time}</p>
                                                <p className='vendor-orders-order-date'>{order.date}</p>
                                            </div>
                                        </div>
                                        <div className="vendor-orders-dotted-line"></div>
                                        <div className='vendor-order-items-container'>
                                            {items.map((item, index) => (

                                                <p key={index} className='vendor-order-items'>{item.qty}X {item.itemname}</p>
                                                /* <p className='vendor-order-items'>{order.item2}</p>
                                            <p className='vendor-order-items'>{order.item3}</p>
                                            <p className='vendor-order-items'>{order.item4}</p> */
                                            ))}
                                        </div>
                                        <div className="vendor-orders-dotted-line"></div>
                                        <div className='vendor-order-bottom-container'>
                                            <div className='vendor-order-bottom-container1'>
                                                {order.status === 'Delivered' ? (
                                                    <div className='vendor-orders-delivered-circle'>
                                                        <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 4L4 7L10 1" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                                        </svg>
                                                    </div>
                                                ) : (
                                                    <div className='vendor-orders-declined-circle'>
                                                        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.8587 3.50005L0.133037 0.774253C-0.0443449 0.596951 -0.0443449 0.310278 0.133037 0.132976C0.310335 -0.0443255 0.597001 -0.0443255 0.7743 0.132976L3.50004 2.85878L6.2257 0.132976C6.40308 -0.0443255 6.68967 -0.0443255 6.86696 0.132976C7.04435 0.310278 7.04435 0.596951 6.86696 0.774253L4.1413 3.50005L6.86696 6.22585C7.04435 6.40315 7.04435 6.68983 6.86696 6.86713C6.77861 6.95557 6.66243 7 6.54633 7C6.43024 7 6.31414 6.95557 6.2257 6.86713L3.50004 4.14133L0.7743 6.86713C0.685858 6.95557 0.569763 7 0.453668 7C0.337573 7 0.221478 6.95557 0.133037 6.86713C-0.0443449 6.68983 -0.0443449 6.40315 0.133037 6.22585L2.8587 3.50005Z" fill="white" />
                                                        </svg>
                                                    </div>
                                                )}
                                                <p className='vendor-order-status-text'>
                                                    {order.status}
                                                </p>
                                            </div>
                                            <p className='vendor-order-price-text'>
                                                {price}$
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        tabContents[activeTab]
                    )}
                </div>
                <div className="rightsidebar-container">
                    {activeTab === null ? (
                        <OrderRightbar></OrderRightbar>
                    ) : (
                        sidebarContents[activeTab]
                    )}
                </div>
            </div>
        </div>
    );
}

export default VendorOrders;
