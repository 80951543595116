import React from 'react';

import './SearchHomePage.css';
import OffersSearchHome from './OffersSearchHome';
import SearchRestaurantNearYou from './SearchRestaurantNearYou';

function SearchHomePage() {
    return (
        <div className="main-container-search-home">
            <OffersSearchHome />

            <SearchRestaurantNearYou />
        </div>
    );
}

export default SearchHomePage;
