



import React, { useEffect, useState, useCallback, useRef } from 'react';
import { GoogleMap, useJsApiLoader, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import FoodStepper from './FoodStepper';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import '../../../Styles/Tracking/GoogleMap.css';

const containerStyle = {
    width: '100%',
    height: '400px',
};

const MapWithUserLocation = () => {
    const [userLocation, setUserLocation] = useState(null);
    const [response, setResponse] = useState(null);
    const mapRef = useRef(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY, // Replace with your API key
    });

    // Set the destination coordinates (you can customize this)
    const destination = { lat: 17.369894, lng: 78.514892 };

    const success = useCallback((position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ lat: latitude, lng: longitude });
    }, []);

    const error = useCallback((error) => {
        console.error('Error getting location', error);
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            const watchId = navigator.geolocation.watchPosition(success, error);
            return () => navigator.geolocation.clearWatch(watchId);
        } else {
            console.error('Geolocation not supported');
        }
    }, [success, error]);

    const directionsCallback = (result, status) => {
        if (status === 'OK') {
            setResponse(result);
        } else {
            console.error('Directions request failed due to ' + status);
        }
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div>
            <div className="topmenubar">
                <SideMenuBar />
                <div className="top-bar-mobile">
                    <TopMenuBar />
                </div>
            </div>

            <div className="google-map-container">
                {userLocation ? (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={userLocation}
                        zoom={15}
                        onLoad={(map) => (mapRef.current = map)}
                       
                    >
                        
                        {!response && (
                            <DirectionsService
                                options={{
                                    origin: userLocation, // Using live location as the origin
                                    destination: destination,
                                    travelMode: 'DRIVING',
                                }}
                               
                                callback={directionsCallback}
                            />
                        )}
                        {response && (
                            <DirectionsRenderer
                                options={{ directions: response }}
                            />
                        )}
                    </GoogleMap>
                ) : (
                    <p style={{ textAlign: 'center' }}>Loading map...</p>
                )}
                <FoodStepper />
            </div>

            <div className="right-side-bar">
                <RightSideBar />
            </div>
        </div>
    );
};

export default MapWithUserLocation;

