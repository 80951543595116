import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Button,
} from '@mui/material';
import './Profilesettings.css';

import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes } from '../../../Redux/Contants/action-types';

function Switchaccount() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const role = useSelector((state) => state.ItemReducer.userRole);

    const handleConfirm = (role) => {
        dispatch({
            type: ActionTypes.SET_USER_ROLE,
            payload: role,
        });

        console.log(role);
        navigate('/');
    };

    const { t } = useTranslation();
    const [value, setValue] = useState('');

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <>
            <div className="switchacc-top1-layout">
                <Link to="/profilesettings">
                    <div className="ico1">
                        <svg
                            width="22"
                            height="25"
                            viewBox="0 0 42 78"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M38.9909 3L3 39L39 75"
                                stroke="#464B59"
                                strokeWidth="5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </Link>
                <h5 className="switch-account-txt">
                    {t('switchaccount.switch-account-title')}
                </h5>
            </div>
            <div className="Switch-acc-main">
                <FormControl className="fieldset">
                    <RadioGroup
                        aria-label="Switch Account"
                        name="Switch Account"
                        onChange={handleChange}
                    >
                        {role !== 'user' && (
                            <FormControlLabel value="user" control={<Radio />} label="User" />
                        )}
                        {role !== 'restaurantOwner' && (
                            <FormControlLabel
                                value="restaurantOwner"
                                control={<Radio />}
                                label={t('switchaccount.restaurant-owner-title')}
                            />
                        )}

                        <FormControlLabel
                            value="deliverypartner"
                            control={<Radio />}
                            label={t('switchaccount.delivery-partner-title')}
                        />

                        <FormControlLabel
                            value="Ad Promotions"
                            control={<Radio />}
                            label={t('switchaccount.ad-promotions-title')}
                        />
                    </RadioGroup>
                    <Button
                        variant="contained"
                        className="confirm-but"
                        style={{
                            backgroundColor: 'var(--color4)',
                            color: '#FFFFFF',
                            width: '90%',
                            height: '45px',
                            marginTop: '20px',
                        }}
                        onClick={() => handleConfirm(value)}
                    >
                        {t('switchaccount.confirm-button')}
                    </Button>
                </FormControl>
            </div>
        </>
    );
}

export default Switchaccount;
