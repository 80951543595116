import React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';

import PostDetail from './PostDetail';
import './PostModal.css';

function PostModal({
    handleClose,
    open,
    posts,
    selectedImageIndex,
    handleDeletePost,
}) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="post-modal">
                    <PostDetail
                        posts={posts}
                        handleClose={handleClose}
                        selectedImageIndex={selectedImageIndex}
                        handleDeletePost={handleDeletePost}
                    />
                </Box>
            </Modal>
        </div>
    );
}

export default PostModal;
