import '../../VendorStyles/DeclinedRightbar.css';
function Customrange(){
    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear; i >= 2000; i--) {
            years.push(i);
        }
        return years.map((year, index) => (
            <option key={index} value={year}>
                {year}
            </option>
        ));
    };

    const generateMonthOptions = () => {
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        return months.map((month, index) => (
            <option key={index} value={index + 1}>
                {month}
            </option>
        ));
    };

    const generateDateOptions = () => {
        const dates = [];
        for (let i = 1; i <= 31; i++) {
            dates.push(i);
        }
        return dates.map((date, index) => (
            <option key={index} value={date}>
                {date}
            </option>
        ));
    };
    return(
        <div className='declined-container2'>
            <h3 className='custom-range-head'>Custom Range</h3>
            <div className='from-cont'>
                <p className='from-text'>From</p>
                <div className='from-input-container'>
                    <select className="declined-custom-select">
                        {generateYearOptions()}
                    </select>
                    <select className="declined-custom-select">
                        {generateMonthOptions()}
                    </select>
                    <select className="declined-custom-select">
                        {generateDateOptions()}
                    </select>
                </div>
            </div>
            <div className='from-cont'>
                <p className='from-text'>To</p>
                <div className='from-input-container'>
                    <select className="declined-custom-select">
                        {generateYearOptions()}
                    </select>
                    <select className="declined-custom-select">
                        {generateMonthOptions()}
                    </select>
                    <select className="declined-custom-select">
                        {generateDateOptions()}
                    </select>
                </div>
            </div>
            <button className='declined-submit-btn'>Submit</button>


        </div>
    );
}
export default Customrange;