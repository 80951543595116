// import '../../VendorStyles/Neworders.css';
// import React, { useState } from 'react';

// function NewOrders(){
//     const [showPopup, setShowPopup] = useState(false); 
//     const [selectedOrder, setSelectedOrder] = useState(null); 
//     const [showSecondPopup, setShowSecondPopup] = useState(false);
//     const [time, setTime] = useState(20); // Initial time in minutes
//     const orders = [
//         {  
//             id:6354,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6355,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6356,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6357,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6358,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6359,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6360,
//             time:'10:30 AM',
//             date:'22 Mar'

//         }
//     ];
//     const items=[{id:'1',itemname:'Chicken Soft Noodles',qty:1,price:10},
//         {id:'2',itemname:'Chicken Soft Noodles',qty:2,price:20},
//         {id:'3',itemname:'Chicken Soft Noodles',qty:6,price:60},
//         {id:'4',itemname:'Chicken Soft Noodles',qty:1,price:10}
//     ];

//     // const [orders, setOrders] = useState([]);

//     // const items=[{id:'1',itemname:'Chicken Soft Noodles',qty:1,price:10},
//     //     {id:'2',itemname:'Chicken Soft Noodles',qty:2,price:20},
//     //     {id:'3',itemname:'Chicken Soft Noodles',qty:6,price:60},
//     //     {id:'4',itemname:'Chicken Soft Noodles',qty:1,price:10}
//     // ];
//     // useEffect(() => {
//     //     // Fetch data from the API
//     //     fetch('http://localhost:5002/api/order/vendor/1')
//     //         .then(response => response.json())
//     //         .then(data => {
//     //             const formattedOrders = data.address.map(order => {
//     //                 const createdAt = new Date(order.Created_at);
//     //                 const time = createdAt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
//     //                 const date = `${createdAt.getDate()} ${createdAt.toLocaleString('en-US', { month: 'short' })}`;
//     //                 return {
//     //                     id: order.id,
//     //                     time,
//     //                     date
//     //                 };
//     //             });
//     //             setOrders(formattedOrders);
//     //         })
//     //         .catch(error => console.error('Error fetching data:', error));
//     // }, []);


//     const handleOrderClick = (order) => {
//         setSelectedOrder(order);
//         setShowPopup(true);
//     };
//     const handleAcceptOrder = () => {
//         setShowPopup(false);
//         setShowSecondPopup(true);
       
//     };
//     const decreaseTime = () => {
//         if (time > 0) {
//             setTime(time - 1);
//         }
//     };

//     const increaseTime = () => {
//         setTime(time + 1);
//     };
//     return (
//         <div className="new-orders-main">
//             {orders.map(order => (
//                 <div key={order.id} className="single-order" onClick={() => handleOrderClick(order)}>
//                     <div className='orderid'>
//                         <p className='order-details1'>Order ID:<span className='order-id'>{order.id}</span></p>
//                     </div>
//                     <div className='date-and-time'>
//                         <p className='order-details2'>{order.time}</p>
//                         <p className='order-details3'>{order.date}</p>
//                     </div>
//                 </div>
//             ))}

//             {showPopup && selectedOrder && (
//                 <div className="new-orders-popup-container">
//                     <svg
//                         width="26"
//                         height="26"
//                         viewBox="0 0 36 36"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="new-orders-popup-cross-icon"
//                         onClick={() => setShowPopup(false)}
//                     >
//                         <path
//                             d="M21.2981 18.0003L35.3158 3.98187C36.2281 3.07003 36.2281 1.59572 35.3158 0.683879C34.404 -0.22796 32.9297 -0.22796 32.0179 0.683879L17.9998 14.7023L3.98211 0.683879C3.06986 -0.22796 1.596 -0.22796 0.684185 0.683879C-0.228062 1.59572 -0.228062 3.07003 0.684185 3.98187L14.7019 18.0003L0.684185 32.0187C-0.228062 32.9305 -0.228062 34.4048 0.684185 35.3167C1.1386 35.7715 1.73609 36 2.33315 36C2.93021 36 3.52727 35.7715 3.98211 35.3167L17.9998 21.2983L32.0179 35.3167C32.4727 35.7715 33.0698 36 33.6669 36C34.2639 36 34.861 35.7715 35.3158 35.3167C36.2281 34.4048 36.2281 32.9305 35.3158 32.0187L21.2981 18.0003Z"
//                             fill="white"
//                         />
//                     </svg>

//                     <div className="new-orders-popup">
//                         <div className='new-orders-popup-top1-background'>
//                             <p className='new-orders-popup-order-id-text'>Order ID: <span className='new-orders-popup-order-id'>{selectedOrder.id}</span></p>
//                             <div className='new-orders-popup-top1-right'>
//                                 <p className='new-orders-popup-time'>{selectedOrder.time}</p>
//                                 <p className='new-orders-popup-time'>{selectedOrder.date}</p>
//                             </div>
//                         </div>
//                         <div className='new-orders-popup-top2'>
//                             <div className='new-orders-popup-headings-container'>
//                                 <div className='new-orders-popup-item-container'><p className='new-orders-popup-item-text'>Item</p></div>
//                                 <div className='new-orders-popup-qty-container'>
//                                     <p className='new-orders-popup-qty-text'>Qty</p>
//                                     <p className='new-orders-popup-price-text'>Price</p>
//                                 </div>
//                             </div>
//                             <div className='new-orders-popup-horizontal-line'></div>
//                             <div className='new-orders-popup-items-container'>
//                                 {items.map(item => (
//                                     <div key={item.id} className='new-orders-popup-item-container' >
//                                         <p  className='new-orders-popup-item-id'>{item.id}. {item.itemname}</p>
//                                         <div className='new-orders-popup-qty-container'>
//                                             <p className='new-orders-popup-item-qty'>{item.qty}</p>
//                                             <p className='new-orders-popup-item-price'>{item.price} $</p>
//                                         </div>
//                                     </div>
//                                 ))}
//                             </div>
//                             <div className='new-orders-popup-horizontal-line'></div>
//                             <h6 className='new-orders-popup-instructions-heading'>Instructions</h6>
//                             <p className='new-orders-popup-instructions-content'>Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make</p>
//                             <div className='new-orders-popup-btns-container'>
//                                 <button className='new-orders-popup-decline-btn' onClick={() => setShowPopup(false)}>Decline</button>
//                                 <button className='new-orders-popup-accept-order-btn' onClick={handleAcceptOrder}>Accept Order</button>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="new-orders-blur-background"></div>
//                 </div>
//             )}

//             {showSecondPopup && (
//                 <><div className="second-popup-container">
//                     <svg
//                         width="26"
//                         height="26"
//                         viewBox="0 0 36 36"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                         className="new-orders-second-popup-cross-icon"
//                         onClick={() => setShowSecondPopup(false)}
//                     >
//                         <path
//                             d="M21.2981 18.0003L35.3158 3.98187C36.2281 3.07003 36.2281 1.59572 35.3158 0.683879C34.404 -0.22796 32.9297 -0.22796 32.0179 0.683879L17.9998 14.7023L3.98211 0.683879C3.06986 -0.22796 1.596 -0.22796 0.684185 0.683879C-0.228062 1.59572 -0.228062 3.07003 0.684185 3.98187L14.7019 18.0003L0.684185 32.0187C-0.228062 32.9305 -0.228062 34.4048 0.684185 35.3167C1.1386 35.7715 1.73609 36 2.33315 36C2.93021 36 3.52727 35.7715 3.98211 35.3167L17.9998 21.2983L32.0179 35.3167C32.4727 35.7715 33.0698 36 33.6669 36C34.2639 36 34.861 35.7715 35.3158 35.3167C36.2281 34.4048 36.2281 32.9305 35.3158 32.0187L21.2981 18.0003Z"
//                             fill="white"
//                         />
//                     </svg>
//                     <div className="second-popup">
//                         <h6 className='second-popup-heading'>Set approx time</h6>
//                         <div className='second-popup-btns-container2'>
//                             <button className='second-popup-minus-plus-btn' onClick={decreaseTime}>-</button>
//                             <button className='second-popup-time-btn'>{time} min</button>
//                             <button className='second-popup-minus-plus-btn' onClick={increaseTime}>+</button>
//                         </div>
//                         <button className='second-popup-settime-btn'>Set time</button>
                        
//                     </div>
//                     <div className="new-orders-blur-background">
//                     </div></div></>

//             )}
//         </div>
        
//     );
// }

// export default NewOrders;


import React, { useState ,useEffect} from 'react';
import '../../VendorStyles/Neworders.css';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';


function NewOrders(){

    // const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;
    const vendor_Base_URL = 'http://localhost:5003/api';
    
    const [showPopup, setShowPopup] = useState(false); 
    const [selectedOrder, setSelectedOrder] = useState(null); 
    console.log(selectedOrder);
    const [showSecondPopup, setShowSecondPopup] = useState(false);
    const [time, setTime] = useState(20); // Initial time in minutes

    const [page, setPage] = useState(1); // Track the current page
    const [loading, setLoading] = useState(false); // Loading state


    const [hasMoreOrders, setHasMoreOrders] = useState(true); // Track if more orders are available
    
   
   
    
   
    const [items, setItems] = useState([]);
    const [orders, setOrders] = useState([]);
    const pageSize = 10; // Items per page

    console.log(orders);

    // const fetchNewOrders = async ()=>{

    //     try {
          
    //         const response = await axios.get(`${vendor_Base_URL}/order/vendor/1?pageSize=10&pageNumber=${page}`);
            
    //         const  incommingData = response.data.allOrdersDetails;
            
    //         setOrders((prev)=>[...prev,...incommingData]);
    //         setLoading(false);


    //     } catch (error) {

    //         setLoading(false);

    //         console.error(error.message);
            
    //     }
    // };

    // const fetchNewOrders = async () => {
    //     if (!hasMoreOrders || loading) return; // Prevent fetching if there are no more orders or already loading

    //     setLoading(true); // Set loading state before fetching

    //     try {
    //         const response = await axios.get(`${vendor_Base_URL}/order/vendor/1?pageSize=${pageSize}&pageNumber=${page}`);
    //         const incomingData = response.data.allOrdersDetails;

    //         if (incomingData.length < pageSize) {
    //             // If the number of items fetched is less than the page size, no more pages to fetch
    //             setHasMoreOrders(false);
    //         }

           
    //         setOrders((prev) => [...prev, ...incomingData]); // Append new orders to the list
    //     } catch (error) {
    //         console.error(error.message);
    //     } finally {
    //         setLoading(false); // Always reset loading state
    //     }
    // };
     




    // Function to fetch orders based on page
    const fetchNewOrders = async () => {
        try {
            // setLoading(true);
            const response = await axios.get(`${vendor_Base_URL}/order/vendor/1?pageSize=${pageSize}&pageNumber=${page}`);
            const incomingData = response.data.allOrdersDetails.filter(order => order.Status_id === 1);

            if (incomingData.length < pageSize) {
                // If the number of items fetched is less than the page size, it means there are no more pages to fetch
                setHasMoreOrders(false);
            }

            // If it's the first page, replace the data, otherwise append
            setOrders((prev) => page === 1 ? [...incomingData] : [...prev, ...incomingData]);

            // setOrders((prev) => [...prev, ...incomingData]); // Append new orders to the list
            // setLoading(false);
        } catch (error) {
            console.error(error.message);
            setLoading(false);
        }
    };
    


    useEffect(() => {

        
        // axios.get(`${vendor_Base_URL}/order/vendor/1?pageSize=10&pageNumber=${page}`)
        //     .then(response => {
        //         const { allOrdersDetails } = response.data;  // Destructure response data
        //         console.log(allOrdersDetails);

               
        //         const formattedOrders = allOrdersDetails
        //             .filter(order => order.Status_id === 1)
        //             .map(order => {
        //                 const createdAt = new Date(order.Created_at);
        //                 const time = createdAt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        //                 const date = `${createdAt.getDate()} ${createdAt.toLocaleString('en-US', { month: 'short' })}`;
        //                 return {
        //                     id: order.ID,
        //                     time,
        //                     date,
        //                     customerId: order.Customer_id
        //                 };
        //             });

               
                    
        //         setOrders((prev)=>[...prev,...formattedOrders]);
        //         setLoading(false);

        //         // If it's the first page, replace orders; otherwise, append
        //         // if (page === 1) {
        //         //     setOrders(formattedOrders);
        //         //     setLoading(false);
        //         // } else {
        //         //     setOrders((prev) => [...prev, ...formattedOrders]);
        //         //     setLoading(false);
        //         // }


               

               
               
            
            
        //     })

        
        //     .catch(error => console.error('Error fetching data:', error));
       
               
        fetchNewOrders(); 
                
            
    }, [page]);

   
  

    


    // const handleInfiniteScroll= async ()=>{
    //     // console.log('scrollHeight' + document.documentElement.scrollHeight);
    //     // console.log('innerHeight' + window.innerHeight);
    //     // console.log('scrollTop' + document.documentElement.scrollTop);


    //     if (loading || !hasMoreOrders) return; // Prevent multiple calls if loading or no more orders
    //     try {
    //         if( window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight){
    //             setPage((prev)=> prev + 1);
    //             // setLoading(true);

               
               
                
               
                
    //         }

    //         // const {scrollTop,clientHeight,scrollHeight} = document.documentElement;

    //         // if(scrollTop + clientHeight >= scrollHeight - 10 && !loading){
    //         //     setLoading(true);
    //         //     setPage((prev)=>prev + 1);

    //         // }


            
    //     } catch (error) {
    //         console.log(error);
    //     }

    // };

  
    
    // useEffect(()=>{

    //     window.addEventListener('scroll', handleInfiniteScroll);
    //     return ()=> window.removeEventListener('scroll', handleInfiniteScroll);

    // },[loading,hasMoreOrders]);

    const fetchMoreOrders = () => {
        setPage((prevPage) => prevPage + 1); // Increment page number to fetch more data
    };




   

    const handleOrderClick = (order) => {
        console.log(order);
        setSelectedOrder(order);
        setShowPopup(true);
        axios.get(`${vendor_Base_URL}/Order/Vendor/1/Customer/${order.Customer_id}`)
            .then(response => {
                console.log(response);
                const selectedOrderDetails = response.data.orders.find(o => o.ID === order.ID);
                console.log(selectedOrderDetails);
                // if (selectedOrderDetails) {
                //     const formattedItems = selectedOrderDetails.Order_Info.map((item, index) => ({
                //         ...item,
                //         id: index + 1 
                //     }));
                //     console.log(formattedItems);
                //     setItems(formattedItems);
                // } else {
                //     setItems([]);  
                // }
                setItems(selectedOrderDetails.order_info);
                console.log(selectedOrderDetails.order_info);
            })
            .catch(error => console.error('Error fetching customer data:', error));
    };

    const handleAcceptOrder = () => {
        // /vendor/:vendor_id/order/:order_id/status
        if (selectedOrder) {
            axios.put(`${vendor_Base_URL}/Order/Vendor/1/order/${selectedOrder.ID}/status`, {
                status_id: 2
            })
                .then(() => {
                    setShowPopup(false);
                    setShowSecondPopup(true);
                    setOrders(prevOrders => prevOrders.filter(order => order.ID !== selectedOrder.ID));
                })
                .catch(error => console.error('Error updating order status:', error));
        }
        setShowPopup(false);

    };
    const handleDeclineOrder = () => {
        if (selectedOrder) {
            axios.put(`${vendor_Base_URL}/Order/Vendor/1/order/${selectedOrder.ID}/status`, {
                status_id: 3
            })
                .then(() => {
                    setShowPopup(false);
                    setShowSecondPopup(false);
                    setOrders(prevOrders => prevOrders.filter(order => order.ID !== selectedOrder.ID));
                })
                .catch(error => console.error('Error updating order status:', error));
        }
        
    };
    

    const decreaseTime = () => {
        if (time > 0) {
            setTime(time - 1);
        }
    };

    const increaseTime = () => {
        setTime(time + 1);
    };

     
    return (
        <div className="new-orders-main">

            {loading ? (
                <div>Loading...</div> // Initial loader
            ) : (

                <InfiniteScroll
                    dataLength={orders.length} // This is important field to render the next data
                    next={fetchMoreOrders} // Function to fetch more data
                    hasMore={hasMoreOrders} // Determines whether there's more data to load
                    loader={<div>Loading...</div>} // Displays a loading message while fetching data
                    endMessage={<div className='no-data-lazy-loading-text-infinite-scroll' style={{ textAlign: 'center', }}>No more orders to load.</div>} // Message shown when all data is loaded
                >
                    {orders.map((order) =>{ 

                    

                        const createdAt = new Date(order.Created_at);
                        const time = createdAt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                        const date = `${createdAt.getDate()} ${createdAt.toLocaleString('en-US', { month: 'short' })}`;
                
                
                        return (

                
                            <div key={order.ID} className="single-order" onClick={() => handleOrderClick(order)}>
                        
                                <div className='orderid'>
                                    <p className='order-details1'>Order ID:<span className='order-id'>{order.ID}</span></p>
                                </div>
                                <div className='date-and-time'>
                                    <p className='order-details2'>{time}</p>
                                    <p className='order-details3'>{date}</p>
                                </div>

                    
                            </div>
                        );})}

                

             
                    {/* {loading && <div>Loading...</div>}
                {!hasMoreOrders && <div>No more orders to load.</div>} Indicate when all data is loaded */}
          

                    {showPopup && selectedOrder && (
                        <div className="new-orders-popup-container">
                            <svg
                                width="26"
                                height="26"
                                viewBox="0 0 36 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="new-orders-popup-cross-icon"
                                onClick={() => setShowPopup(false)}
                            >
                                <path
                                    d="M21.2981 18.0003L35.3158 3.98187C36.2281 3.07003 36.2281 1.59572 35.3158 0.683879C34.404 -0.22796 32.9297 -0.22796 32.0179 0.683879L17.9998 14.7023L3.98211 0.683879C3.06986 -0.22796 1.596 -0.22796 0.684185 0.683879C-0.228062 1.59572 -0.228062 3.07003 0.684185 3.98187L14.7019 18.0003L0.684185 32.0187C-0.228062 32.9305 -0.228062 34.4048 0.684185 35.3167C1.1386 35.7715 1.73609 36 2.33315 36C2.93021 36 3.52727 35.7715 3.98211 35.3167L17.9998 21.2983L32.0179 35.3167C32.4727 35.7715 33.0698 36 33.6669 36C34.2639 36 34.861 35.7715 35.3158 35.3167C36.2281 34.4048 36.2281 32.9305 35.3158 32.0187L21.2981 18.0003Z"
                                    fill="white"
                                />
                            </svg>

                            <div className="new-orders-popup">
                                <div className='new-orders-popup-top1-background'>
                                    <p className='new-orders-popup-order-id-text'>Order ID: <span className='new-orders-popup-order-id'>{selectedOrder.id}</span></p>
                                    <div className='new-orders-popup-top1-right'>
                                        <p className='new-orders-popup-time'>{selectedOrder.time}</p>
                                        <p className='new-orders-popup-time'>{selectedOrder.date}</p>
                                    </div>
                                </div>
                                <div className='new-orders-popup-top2'>
                                    <div className='new-orders-popup-headings-container'>
                                        <div className='new-orders-popup-item-container'><p className='new-orders-popup-item-text'>Item</p></div>
                                        <div className='new-orders-popup-qty-container'>
                                            <p className='new-orders-popup-qty-text'>Qty</p>
                                            <p className='new-orders-popup-price-text'>Price</p>
                                        </div>
                                    </div>
                                    <div className='new-orders-popup-horizontal-line'></div>
                                    <div className='new-orders-popup-items-container'>
                                        {items.map((item,index) => (
                                            <div key={index} className='new-orders-popup-item-container' >
                                                <p  className='new-orders-popup-item-id'>{item.id}. {item.Item_name}</p>
                                                <div className='new-orders-popup-qty-container'>
                                                    <p className='new-orders-popup-item-qty'>{item.Quantity}</p>
                                                    <p className='new-orders-popup-item-price'>{item.Price} $</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='new-orders-popup-horizontal-line'></div>
                                    <h6 className='new-orders-popup-instructions-heading'>Instructions</h6>
                                    <p className='new-orders-popup-instructions-content'>Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make</p>
                                    <div className='new-orders-popup-btns-container'>
                                        <button className='new-orders-popup-decline-btn' onClick={ handleDeclineOrder}>Decline</button>
                                        <button className='new-orders-popup-accept-order-btn' onClick={handleAcceptOrder}>Accept Order</button>
                                    </div>
                                </div>
                            </div>
                            <div className="new-orders-blur-background"></div>
                        </div>
                    )}

                    {showSecondPopup && (
                        <><div className="second-popup-container">
                            <svg
                                width="26"
                                height="26"
                                viewBox="0 0 36 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="new-orders-second-popup-cross-icon"
                                onClick={() => setShowSecondPopup(false)}
                            >
                                <path
                                    d="M21.2981 18.0003L35.3158 3.98187C36.2281 3.07003 36.2281 1.59572 35.3158 0.683879C34.404 -0.22796 32.9297 -0.22796 32.0179 0.683879L17.9998 14.7023L3.98211 0.683879C3.06986 -0.22796 1.596 -0.22796 0.684185 0.683879C-0.228062 1.59572 -0.228062 3.07003 0.684185 3.98187L14.7019 18.0003L0.684185 32.0187C-0.228062 32.9305 -0.228062 34.4048 0.684185 35.3167C1.1386 35.7715 1.73609 36 2.33315 36C2.93021 36 3.52727 35.7715 3.98211 35.3167L17.9998 21.2983L32.0179 35.3167C32.4727 35.7715 33.0698 36 33.6669 36C34.2639 36 34.861 35.7715 35.3158 35.3167C36.2281 34.4048 36.2281 32.9305 35.3158 32.0187L21.2981 18.0003Z"
                                    fill="white"
                                />
                            </svg>
                            <div className="second-popup">
                                <h6 className='second-popup-heading'>Set approx time</h6>
                                <div className='second-popup-btns-container2'>
                                    <button className='second-popup-minus-plus-btn' onClick={decreaseTime}>-</button>
                                    <button className='second-popup-time-btn'>{time} min</button>
                                    <button className='second-popup-minus-plus-btn' onClick={increaseTime}>+</button>
                                </div>
                                <button className='second-popup-settime-btn'>Set time</button>
                        
                            </div>
                            <div className="new-orders-blur-background">
                            </div></div></>

                    )}

                </InfiniteScroll>

            )}
        </div>
        
    );
}

export default NewOrders;