import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import '../../../Styles/Cart/AddressList.css';
import AddAddressModel from './AddAddressModel';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

function AdressList({ handleClose, handleUpdateDeliveryAddress }) {
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
    const { t } = useTranslation();

    const [addAddress, setAddAddress] = useState(false);
    const handleOpenAddress = () => setAddAddress(true);
    const handleCloseAddress = () => setAddAddress(false);

    const [addressList, setAddressList] = useState([]);

    console.log(addressList);

    const getAddress = async () => {
    // Fetch addresses from API when component mounts
        axios
            .get(`${base_URL}/address/account/2`) // Replace with your API endpoint
            .then((response) => {
                setAddressList(response.data.addresses);
            })
            .catch((error) => {
                console.error('Error fetching addresses:', error);
            });
    };

    useEffect(() => {
        getAddress();
    }, []);

    const handleCheckboxChange = (index) => {
        setSelectedAddressIndex(index);
    };

    const getAddressType = () => {
        if (selectedAddressIndex !== 0) {
            return addressList[selectedAddressIndex]?.Delivery_name;
        }
        return 'Home';
    };

    const handleAddAddress = (newAddress) => {
        setAddressList([...addressList, newAddress]);
    };

    return (
        <div className="adress-list-main-container">
            <div className="hide-horizantal-line">
                <div className="horizantal-line-mobile"></div>
            </div>

            <div className="comment-and-crossIcon-container">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t('cart.address-title')}
                </Typography>

                <svg
                    width="25"
                    height="18"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cross-icon"
                    onClick={handleClose}
                >
                    <path
                        d="M14.7904 12.5002L24.5249 2.76519C25.1584 2.13197 25.1584 1.10814 24.5249 0.474916C23.8917 -0.158305 22.8679 -0.158305 22.2346 0.474916L12.4999 10.2099L2.76535 0.474916C2.13185 -0.158305 1.10834 -0.158305 0.475128 0.474916C-0.158376 1.10814 -0.158376 2.13197 0.475128 2.76519L10.2096 12.5002L0.475128 22.2352C-0.158376 22.8684 -0.158376 23.8922 0.475128 24.5255C0.790694 24.8413 1.20562 25 1.62024 25C2.03487 25 2.44949 24.8413 2.76535 24.5255L12.4999 14.7905L22.2346 24.5255C22.5505 24.8413 22.9651 25 23.3798 25C23.7944 25 24.209 24.8413 24.5249 24.5255C25.1584 23.8922 25.1584 22.8684 24.5249 22.2352L14.7904 12.5002Z"
                        fill="white"
                    />
                </svg>
            </div>

            <div className="address-container-in-model">
                <div className="delivery-location-home-desc-container">
                    <svg
                        width="27"
                        height="33"
                        viewBox="0 0 27 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.4638 20.4199L10.8783 30.2964C11.4416 31.0466 12.3389 31.5319 13.3497 31.5319C14.3604 31.5319 15.2577 31.0466 15.821 30.2964L23.2355 20.4199C24.7829 18.359 25.6999 15.7977 25.6999 13.023C25.6999 6.20809 20.1708 0.683594 13.3502 0.683594C6.52965 0.683594 1 6.20809 1 13.023C1 15.7977 1.91698 18.359 3.46438 20.4199H3.4638Z"
                            stroke="white"
                            strokeWidth="1.33"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M16.4363 13.0183C16.4363 14.7218 15.0539 16.103 13.349 16.103C11.6441 16.103 10.2617 14.7218 10.2617 13.0183C10.2617 11.3149 11.6441 9.93359 13.349 9.93359C15.0539 9.93359 16.4363 11.3149 16.4363 13.0183Z"
                            stroke="white"
                            strokeWidth="1.33"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>

                    <div className="delivert-to-home-desc-container">
                        <h3 className="delivery-to-home-text">
                            {t('cart.delivery-to')} {getAddressType()}
                        </h3>
                        <p className="delivery-to-home-address">
                            {t('cart.for-near-by-restaurants-and-quick-delivery')}
                        </p>
                    </div>
                </div>

                <svg
                    width="17"
                    height="10"
                    viewBox="0 0 17 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M17 1.50743L8.5 10L1.01273e-07 1.50743L1.50875 1.92534e-06L8.5 6.98514L15.4913 2.09208e-06L17 1.50743Z"
                        fill="white"
                    />
                </svg>
            </div>

            <div className="current-location-icon-text">
                <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="location-svg-adress-list "
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.3001 5.6C13.6867 5.6 14 5.2866 14 4.9V0.7C14 0.313404 13.6867 0 13.3001 0H9.09834C8.71179 0 8.39846 0.313397 8.39846 0.7C8.39846 1.0866 8.71179 1.4 9.09834 1.4H11.6106L5.587 7.42462C5.31369 7.69797 5.31369 8.14121 5.587 8.41456C5.86035 8.68798 6.30345 8.68798 6.57675 8.41456L12.6002 2.39006V4.9C12.6002 5.2866 12.9136 5.6 13.3001 5.6ZM2.09963 2.1C0.940041 2.1 0 3.0402 0 4.2V11.9C0 13.0598 0.940041 14 2.09963 14H9.79828C10.9579 14 11.8979 13.0598 11.8979 11.9V7.7C11.8979 7.31339 11.5846 7 11.198 7C10.8115 7 10.4982 7.31339 10.4982 7.7V11.9C10.4982 12.2866 10.1848 12.6 9.79828 12.6H2.09963C1.7131 12.6 1.39976 12.2866 1.39976 11.9V4.2C1.39976 3.8134 1.7131 3.5 2.09963 3.5H6.2989C6.68544 3.5 6.99878 3.1866 6.99878 2.8C6.99878 2.4134 6.68544 2.1 6.2989 2.1H2.09963Z"
                        fill="white"
                    />
                </svg>

                <p className="current-location-text">
                    {t('cart.use-current-location')}
                </p>
            </div>

            <div className="address-list-main-container">
                <div className="address-list-title-container">
                    <p className="saved-address">{t('cart.saved-address-title')}</p>
                    <button
                        className="add-new-address-btn"
                        onClick={() => handleOpenAddress()}
                    >
                        {t('cart.add-new-address-title')}
                    </button>

                    {addAddress && (
                        <AddAddressModel
                            open={open}
                            handleCloseAddress={handleCloseAddress}
                            handleAddAddress={handleAddAddress}
                            getAddress={getAddress}
                        />
                    )}
                </div>

                <div className="address-list-scrollable">
                    {addressList.map((address, index) => {
                        return (
                            <div key={index} className="address-type-container">
                                <input
                                    type="checkbox"
                                    className="address-checkbox"
                                    checked={selectedAddressIndex === index}
                                    onChange={() => handleCheckboxChange(index)}
                                />

                                <div>
                                    <h4 className="address-type">{address.Delivery_name}</h4>
                                    <p className="address-type-address">
                                        {`${address.Address_line_1} ${address.City} ${address.State} ${address.ZIP}`}{' '}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <button
                className="update-delivery-address"
                onClick={() => {
                    handleUpdateDeliveryAddress(getAddressType());
                    handleClose();
                }}
            >
                {t('cart.update-delivery-address')}
            </button>
        </div>
    );
}

export default AdressList;
