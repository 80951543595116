import React from 'react';

import image1 from '../../../assets/posts1.png';
import image2 from '../../../assets/posts2.png';
import image3 from '../../../assets/posts3.png';
import image4 from '../../../assets/posts4.png';
import image5 from '../../../assets/posts5.png';
import image6 from '../../../assets/posts6.png';
import image7 from '../../../assets/posts7.png';
import image8 from '../../../assets/posts8.png';
import image9 from '../../../assets/posts9.png';
import vid1 from '../Videos/video1.mp4';
import vid2 from '../Videos/video2.mp4';
import vid3 from '../Videos/video3.mp4';
import vid4 from '../Videos/video4.mp4';
import vid5 from '../Videos/video5.mp4';
import vid6 from '../Videos/video6.mp4';

import './Post.css';
const saved = [
    { id: '1', image: image1 },
    { id: '2', image: image2 },
    { id: '3', video: vid1 },
    { id: '4', video: vid2 },
    { id: '5', video: vid3 },
    { id: '6', image: image3 },
    { id: '7', video: vid5 },
    { id: '8', video: vid6 },
    { id: '9', video: vid4 },
    { id: '10', video: vid5 },
    { id: '11', image: image4 },
    { id: '12', image: image5 },
    { id: '13', image: image6 },
    { id: '14', image: image7 },
    { id: '15', image: image8 },
    { id: '16', image: image9 },
];

function ReelsAndPosts() {
    // const {t} = useTranslation();

    return (
        <div className="media-post-reels">
            {saved.map((item) => (
                <div className="media-item" key={item.id}>
                    {item.image ? (
                        <img src={item.image} alt={`Media ${item.id}`} />
                    ) : (
                        <video controls>
                            <source src={item.video} type="video/mp4" />
              Your browser does not support the video tag.
                        </video>
                    )}
                </div>
            ))}
        </div>
    );
}
export default ReelsAndPosts;
