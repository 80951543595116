import React, { useEffect, useRef, useState } from 'react';

// Styles
import './../../../Styles/QRCodeScanner/QRCodeScanner.css';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';

// Qr Scanner
import QrScanner from 'qr-scanner';
import QrFrame from '../../../assets/qr-frame.svg';
import { Link } from 'react-router-dom';

const QrReader = () => {
    // QR States
    const scanner = useRef();
    const videoEl = useRef(null);
    const qrBoxEl = useRef(null);
    const [qrOn, setQrOn] = useState(true);

    const uploadInputRef = useRef(null);

    const [scannedResult, setScannedResult] = useState('');

    const handleImageClick = () => {
        uploadInputRef.current.click();
    };

    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const detectQRCode = async (imageData) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => {
                console.log('Image loaded successfully');
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0);
                resolve(canvas.toDataURL());
            };
            image.onerror = (error) => {
                console.error('Error loading image:', error);
                reject(error);
            };

            image.src = imageData;

            console.log(imageData);
        });
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const imageData = await readFileAsDataURL(file);
            const qrCodeResult = await detectQRCode(imageData);
            if (qrCodeResult) {
                console.log('QR Code detected:', qrCodeResult);
            } else {
                console.log('No QR Code detected');
            }
        }
    // if (file) {
    //     // Handle the file upload here, for example, send it to a server using fetch or axios
    //     console.log('File selected:', file.name);
    // }
    };

    // Success
    const onScanSuccess = (result) => {
        console.log(result);
        setScannedResult(result?.data);
    };

    // // Fail
    // const onScanFail = (err) => {
    //     console.log(err);
    // };

    useEffect(() => {
        if (videoEl.current && !scanner.current) {
            scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
                onDecodeError: null,
                preferredCamera: 'environment',
                highlightScanRegion: true,
                highlightCodeOutline: true,
                overlay: qrBoxEl.current || undefined,
            });

            scanner.current
                .start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        return () => {
            if (!videoEl.current) {
                scanner.current.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (!qrOn) {
            alert(
                'Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.'
            );
        }
    }, [qrOn]);

    return (
        <div className="qr-code-main-container">
            <div className="qr-top-container">
                <div className="qr-back-svg-and-text">
                    <Link to="/">
                        <svg
                            width="16"
                            height="27"
                            viewBox="0 0 16 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.5881 27L-2.36041e-06 13.5L13.5881 2.37582e-06L16 2.39625L4.82378 13.5L16 24.6038L13.5881 27Z"
                                fill="white"
                            />
                        </svg>
                    </Link>

                    <p className="scan-qr-code-text">Scan QR code</p>
                </div>

                <svg
                    width="30"
                    height="30"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_1109_1661)">
                        <path
                            d="M15.5236 7.78517C15.4086 7.60642 15.2111 7.50017 14.9999 7.50017H10.6249V0.625264C10.6249 0.330268 10.4187 0.0752718 10.1299 0.0140226C9.83618 -0.0484765 9.54869 0.102771 9.42869 0.371518L4.42876 11.6214C4.34251 11.8139 4.36126 12.0389 4.47626 12.2151C4.59126 12.3926 4.78875 12.5001 5 12.5001H9.37494V19.375C9.37494 19.67 9.58119 19.925 9.86993 19.9862C9.91368 19.995 9.95743 20 9.99993 20C10.2424 20 10.4687 19.8587 10.5712 19.6288L15.5711 8.37891C15.6561 8.18516 15.6399 7.96266 15.5236 7.78517Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1109_1661">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </div>

            <div className="qr-reader">
                <video ref={videoEl}></video>
                <div ref={qrBoxEl} className="qr-box">
                    <img
                        src={QrFrame}
                        alt="Qr Frame"
                        width={256}
                        height={256}
                        className="qr-frame"
                    />
                </div>

                <div onClick={handleImageClick}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="34px"
                        height="34px"
                        className="gallery-img-svg"
                    >
                        <path d="M 4 4 C 2.9042029 4 2 4.9042029 2 6 L 2 18 C 2 19.095797 2.9042029 20 4 20 L 20 20 C 21.095797 20 22 19.095797 22 18 L 22 6 C 22 4.9042029 21.095797 4 20 4 L 4 4 z M 4 5.5 L 20 5.5 C 20.284203 5.5 20.5 5.7157971 20.5 6 L 20.5 17.060547 L 14.53125 11.089844 A 0.750075 0.750075 0 0 0 13.46875 11.091797 L 11.992188 12.576172 L 9.53125 10.115234 A 0.750075 0.750075 0 0 0 8.9882812 9.8945312 A 0.750075 0.750075 0 0 0 8.46875 10.115234 L 3.5 15.083984 L 3.5 6 C 3.5 5.7157971 3.7157971 5.5 4 5.5 z M 18 7 A 1 1 0 0 0 18 9 A 1 1 0 0 0 18 7 z M 9 11.707031 L 13.517578 16.222656 A 0.750075 0.750075 0 1 0 14.578125 15.162109 L 13.052734 13.636719 L 14 12.681641 L 19.818359 18.5 L 4 18.5 C 3.7157971 18.5 3.5 18.284203 3.5 18 L 3.5 17.205078 L 9 11.707031 z" />
                    </svg>
                </div>

                <input
                    type="file"
                    accept="image/jpeg, image/png"
                    ref={uploadInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />

                <Link to="/">
                    <button className="qr-cancel-btn">Cancel</button>
                </Link>

                {scannedResult && (
                    <p
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 99999,
                            color: 'white',
                        }}
                    >
            Scanned Result: {scannedResult}
                    </p>
                )}
            </div>

            <SideMenuBar></SideMenuBar>
        </div>
    );
};

export default QrReader;
