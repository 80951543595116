import React, { useState } from 'react';
import './../../../Styles/Cart/AddAddress.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';

function AddNewAddress({ handleClose, handleAddAddress, getAddress }) {
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const [deliveryName, setDeliveryName] = useState('');
    const [streetName, setStreetName] = useState('');
    const [areaCityState, setAreaCityState] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');
    const { t } = useTranslation();

    const handleSubmit = async (e) => {
        if (!deliveryName || !streetName || !areaCityState || !zipcode) {
            e.preventDefault();
            toast.error('Please fill all fields');
            return;
        }
        e.preventDefault();
        // Create a new address object
        const newAddress = {
            id: Math.random(), // Generate a random ID (should be unique)
            addressType: deliveryName,
            address: `${streetName} ${areaCityState} ${state},zip code:${zipcode}`,
        };

        try {
            const addAddressData = {
                Delivery_name: deliveryName,
                Address_line_1: streetName,
                Address_line_2: '',
                City: areaCityState,
                State: state,
                ZIP: zipcode,
                Account_id: 2,
                Customer_id: 2,
            };

            await axios.post(`${base_URL}/address/account/2`, addAddressData);

            await getAddress();
            toast.success('Address added successfully');
        } catch (error) {
            console.error('Error adding address:', error);
            toast.error('Failed to add address. Please try again.');
        }

        // Call handleAddAddress function with the new address object
        handleAddAddress(newAddress);

        // Reset form fields

        setDeliveryName('');
        setStreetName('');
        setAreaCityState('');
        setState('');
        setZipcode('');

        handleClose();
    };

    return (
        <div>
            <div className="title-cross-svg-container">
                <h1 className="add-new-address-title">
                    {t('cart.add-new-address-title')}
                </h1>
                <div className="cross-svg-add-address">
                    <svg
                        width="23"
                        height="18"
                        viewBox="0 0 25 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cross-icon"
                        onClick={handleClose}
                    >
                        <path
                            d="M14.7904 12.5002L24.5249 2.76519C25.1584 2.13197 25.1584 1.10814 24.5249 0.474916C23.8917 -0.158305 22.8679 -0.158305 22.2346 0.474916L12.4999 10.2099L2.76535 0.474916C2.13185 -0.158305 1.10834 -0.158305 0.475128 0.474916C-0.158376 1.10814 -0.158376 2.13197 0.475128 2.76519L10.2096 12.5002L0.475128 22.2352C-0.158376 22.8684 -0.158376 23.8922 0.475128 24.5255C0.790694 24.8413 1.20562 25 1.62024 25C2.03487 25 2.44949 24.8413 2.76535 24.5255L12.4999 14.7905L22.2346 24.5255C22.5505 24.8413 22.9651 25 23.3798 25C23.7944 25 24.209 24.8413 24.5249 24.5255C25.1584 23.8922 25.1584 22.8684 24.5249 22.2352L14.7904 12.5002Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </div>

            <div>
                <form onSubmit={handleSubmit}>
                    <div className="delivery-name-container">
                        <label className="name-label">
                            {t('cart.delivery-name-title')}
                        </label>
                        <input
                            className="add-address-input"
                            value={deliveryName}
                            onChange={(e) => setDeliveryName(e.target.value)}
                            placeholder={t('cart.delivery-name-placeholder')}
                        ></input>
                    </div>
                    <div className="delivery-name-container">
                        <label className="name-label">{t('cart.street-title')}</label>
                        <input
                            className="add-address-input"
                            value={streetName}
                            onChange={(e) => setStreetName(e.target.value)}
                        ></input>
                    </div>
                    <div className="delivery-name-container">
                        <label className="name-label">
                            {t('cart.area-and-city-and-state')}
                        </label>
                        <input
                            className="add-address-input"
                            value={areaCityState}
                            onChange={(e) => setAreaCityState(e.target.value)}
                        ></input>
                    </div>

                    <div className="delivery-name-container">
                        <label className="name-label">State</label>
                        <input
                            className="add-address-input"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        ></input>
                    </div>

                    <div className="delivery-name-container">
                        <label className="name-label">{t('cart.zip-code-title')}</label>
                        <input
                            className="add-address-input"
                            value={zipcode}
                            onChange={(e) => setZipcode(e.target.value)}
                        ></input>
                    </div>

                    <button type="submit" className="add-address-btn">
                        {t('cart.add-address-title')}
                    </button>
                </form>
            </div>
        </div>
    );
}

export default AddNewAddress;
