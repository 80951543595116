import React from 'react';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';

import Home from '../Homepage/Home';

function Dashboard() {
    return (
        <div className="dashboard">
            <div className="side-bar-cont">
                <SideMenuBar></SideMenuBar>
            </div>
            <div className="Home">
                <div className="Topbar-cont">
                    <TopMenuBar></TopMenuBar>
                </div>
                <div className="home-page-cont1">
                    <div className="home-page">
                        <Home></Home>
                    </div>
                    <div className="right-side-bar1">
                        <RightSideBar></RightSideBar>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
