import React, { useEffect, useState } from 'react';
import img1 from '../../../assets/nbrestaurants1.png';

import star from '../../../assets/Star.png';
import { useTranslation } from 'react-i18next';
import Filters from './filters';
import './Restaurantnearyou.css';

import axios from 'axios';
export default function Restaurantnearyou() {
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const { t } = useTranslation();

    const [restaurantData, setRestaurantData] = useState([]);

    // Dummy latitude and longitude values, replace these with actual location data
    const latitude = '17.4072147';
    const longitude = '78.492518';
    const pageNo = 1;
    const pageSize = 10;

    useEffect(() => {
        const getRestaurantNearYou = async () => {
            const response = await axios.get(
                `${base_URL}/search?searchText=&location=${latitude},${longitude}&pageNo=${pageNo}&pageSize=${pageSize}&type=restaurant`
            );
            setRestaurantData(response.data.data[0].Restaraunt_Information);
        };
        getRestaurantNearYou();
    }, []);

    return (
        <div className="restaurantnearyou-Main-container-11">
            <div className="restaurantnearyou-head-container-11">
                <span className="restaurantnearyou-head-text-11">
                    {t('homescreen.restaurant-near-you-title')}
                </span>
                <Filters></Filters>
            </div>
            <div className="restaurantnearyou-card-container-11">
                {restaurantData.map((restaurant, index) => (
                    <div key={index} className="restaurantnearyou-card-11">
                        <div className="restaurantnearyou-image-container-11">
                            <img
                                className="restaurantnearyou-image-11"
                                src={img1}
                                alt="restaurant img"
                            ></img>
                            <div className="restaurantnearyou-offer-bg-11">
                                <span className="restaurantnearyou-offer-percentage-11">
                                    {restaurant.Offer_percent}% OFF
                                </span>
                            </div>
                        </div>
                        <div className="restaurants-data">
                            <h6 className="restaurantnearyou-name-11">
                                <span className="restaurantnearyou-text-11">
                                    {restaurant.Restaurant_name}
                                </span>
                            </h6>
                            <div className="restaurantnearyou-image-footer-11">
                                <img
                                    className="restaurantnearyou-star-icon-image-11"
                                    src={star}
                                    alt="star icon"
                                ></img>
                                <span className="restaurantnearyou-reviews-11">
                                    {restaurant.Restaurant_ratings} reviews
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
