import React, { useState, useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';

import './../../../Styles/Cart/Cart.css';
import backIcon from './../../../assets/backIcon-white.png';

import starPic from './../../../assets/Star.png';
import redDot from './../../../assets/reddot.png';
import SubTotal from './SubTotal';
import { ActionTypes } from '../../../Redux/Contants/action-types';
import { Link, useNavigate } from 'react-router-dom';
import Address from './AddressListModel';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import emptyCartImg from './../../../assets/empty-cart.png';
import AddOns from './AddOns';
import { toast } from 'react-toastify';
import axios from 'axios';
import img1 from '../../../assets/Image1.png';
import { useCart } from '../../../CartProvider';
function cart() {
    const { fetchCartCount } = useCart();
    const base_URL = process.env.REACT_APP_API_BASE_URL;
    // const base_URL = 'http://localhost:5002/api';

    const navigate = useNavigate();

    const vendorID = useSelector((state) => {
        return state.ItemReducer.selectedvendorid;
    });
    console.log(vendorID);

    const [openAddressList, setOpenAddressList] = useState(false);
    const handleOpenAdressList = () => setOpenAddressList(true);
    const handleCloseAddressList = () => setOpenAddressList(false);
    const { t } = useTranslation();

    const [deliveryAddress, setDeliveryAddress] = useState('Home');

    const handleUpdateDeliveryAddress = (newAddressType) => {
        setDeliveryAddress(newAddressType);
        toast.success('Updated Delivery Address');
    };

    const dispatch = useDispatch();

    const [cartData, setCartData] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    console.log(totalPrice);

    console.log(cartData);

    const fetchDataFromCart = async () => {
    // Fetch item data from the API using the itemID
        const itemResponse = await axios.get(`${base_URL}/cartdetails/1`);
        console.log(
            itemResponse.data.cartDetails.cartDetails[0].result[0].Cart_Information
        );

        setCartData(
            itemResponse.data.cartDetails.cartDetails[0].result[0]?.Cart_Information
        );

        setTotalPrice(
            itemResponse.data.cartDetails.cartDetails[0].result[0]?.Cart_Total_Price
        );
    };

    useEffect(() => {
        fetchDataFromCart();
    }, []);

    const Increment = async (id) => {
        await axios.put(
            `${base_URL}/cartdetails/customer/1/vendor/${vendorID}/item/${id.Item_ID}`,
            { Quantity: id.Item_Quantity + 1 }
        );

        toast.success('Item Quantity Increased');
        await fetchDataFromCart();
    };

    const Decrement = async (id) => {
        const newQuantity = id.Item_Quantity - 1;

        if (newQuantity > 0) {
            // If the new quantity is greater than zero, update the quantity in the database
            await axios.put(
                `${base_URL}/cartdetails/customer/1/vendor/${vendorID}/item/${id.Item_ID}`,
                { Quantity: newQuantity }
            );

            toast.success('Item Quantity Decreased');
            await fetchDataFromCart();
        } else {
            // If the new quantity is zero, delete the item from the cart in the database
            await axios.delete(
                `${base_URL}/cartdetails/customer/1/vendor/${vendorID}/${id.Item_ID}`
            );
            toast.success('Item Removed from Cart');
            await fetchDataFromCart();
            await fetchCartCount();
        }

        console.log(id);
    };

    const handleMoreInfo = (data) => {
        dispatch({
            type: ActionTypes.SELECTED_ITEM_DATA,
            payload: data,
        });

        navigate('/FoodDescription');
    };

    const handleOrderItem = () => {
        navigate('/');
    };

    return (
        <div>
            <div className="topmenubar-cart">
                <SideMenuBar></SideMenuBar>

                <div className="topbar-cart">
                    <TopMenuBar></TopMenuBar>
                </div>
            </div>

            <div className="cart-mobile-header">
                <div className="cart-header-item-container">
                    <Link to="/">
                        {' '}
                        <img className="backIcon-cart" src={backIcon} alt="backIcon" />
                    </Link>
                    <h2 className="review-order-heading">{t('cart.review-order')}</h2>
                </div>

                <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.4155 24.999C8.40525 24.999 6.76953 23.3637 6.76953 21.353C6.76953 21.0654 7.00293 20.832 7.29052 20.832C7.57811 20.832 7.81151 21.0654 7.81151 21.353C7.81151 22.7885 8.98044 23.957 10.4155 23.957C11.8505 23.957 13.0195 22.7881 13.0195 21.353C13.0195 21.0654 13.2528 20.832 13.5404 20.832C13.828 20.832 14.0614 21.0654 14.0614 21.353C14.0614 23.3632 12.4262 24.999 10.4155 24.999Z"
                        fill="white"
                    />
                    <path
                        d="M19.2707 21.8742H1.56248C0.701163 21.8742 0 21.173 0 20.3117C0 19.8542 0.198728 19.4221 0.546868 19.1242C0.567864 19.1067 0.590813 19.0901 0.613762 19.0764C2.21042 17.6902 3.12496 15.6921 3.12496 13.5828V10.4163C3.12496 6.98718 5.5595 3.98625 8.91444 3.28215C9.19129 3.22502 9.47156 3.40276 9.53113 3.68547C9.5907 3.96671 9.41052 4.24259 9.12781 4.30216C6.25285 4.90518 4.16645 7.47692 4.16645 10.4168V13.5833C4.16645 16.0227 3.09762 18.3323 1.23338 19.9177C1.21776 19.9314 1.20213 19.9426 1.18553 19.9543C1.09276 20.051 1.04198 20.1794 1.04198 20.3127C1.04198 20.6003 1.27537 20.8337 1.56297 20.8337H19.2712C19.5588 20.8337 19.7922 20.6003 19.7922 20.3127C19.7922 20.1784 19.74 20.05 19.6487 19.9533C19.633 19.9431 19.6174 19.9314 19.6028 19.9191C18.6321 19.1013 17.8728 18.0774 17.3465 16.8752C17.2307 16.6116 17.3508 16.3044 17.614 16.1887C17.8797 16.075 18.1848 16.1941 18.3005 16.4563C18.7537 17.4885 19.3997 18.3698 20.2224 19.0769C20.2453 19.0905 20.2673 19.1062 20.2868 19.1237C20.635 19.4226 20.8337 19.8552 20.8337 20.3122C20.8337 21.1735 20.1326 21.8747 19.2712 21.8747L19.2707 21.8742Z"
                        fill="white"
                    />
                    <path
                        d="M18.2279 13.5413C14.4946 13.5413 11.457 10.5038 11.457 6.77042C11.457 3.03707 14.4946 0 18.2279 0C21.9613 0 24.9988 3.03756 24.9988 6.77091C24.9988 10.5043 21.9613 13.5418 18.2279 13.5418V13.5413Z"
                        fill="#E13535"
                    />
                    <path
                        d="M19.7905 9.89494H16.6655C16.3779 9.89494 16.1445 9.66154 16.1445 9.37395V8.59271C16.1445 7.30025 17.1958 6.24899 18.4883 6.24899C18.9194 6.24899 19.2695 5.8989 19.2695 5.46775C19.2695 5.0366 18.9194 4.68651 18.4883 4.68651H16.6655C16.3779 4.68651 16.1445 4.45311 16.1445 4.16552C16.1445 3.87793 16.3779 3.64453 16.6655 3.64453H18.4883C19.4936 3.64453 20.311 4.46239 20.311 5.46726C20.311 6.47213 19.4931 7.28999 18.4883 7.28999C17.7705 7.28999 17.186 7.87446 17.186 8.59222V8.85247H19.79C20.0776 8.85247 20.311 9.08587 20.311 9.37346C20.311 9.66106 20.0776 9.89445 19.79 9.89445L19.7905 9.89494Z"
                        fill="white"
                    />
                </svg>
            </div>

            <div className="cart-container">
                {!cartData || cartData.length === 0 ? (
                    <div className="empty-cart-container">
                        <img
                            className="empty-cart-img"
                            src={emptyCartImg}
                            alt="empty-cart"
                            loading="lazy"
                        />
                        <h1 className="empty-cart-text"> Your Cart Empty</h1>
                        <button className="continue-to-order" onClick={handleOrderItem}>
              Continue to order
                        </button>
                    </div>
                ) : (
                    <>
                        {cartData?.map((item) => {
                            const shortDescription = item.MenuItem_Description
                                ? item.MenuItem_Description.slice(0, 60)
                                : 'No description available'; // Truncate description
                            return (
                                <div className="item-container-cart" key={item.Item_id}>
                                    <div>
                                        <img className="item-img-order" src={img1} alt="item" />
                                    </div>
                                    <div className="item-details-order">
                                        <div className="item-row1">
                                            <div className="row1">
                                                <img
                                                    className="item-reddot-order"
                                                    src={redDot}
                                                    alt="reddot"
                                                />
                                                <h5 className="item-name-order">
                                                    {item.MenuItem_Name}
                                                </h5>
                                            </div>
                                            <p className="item-cost-order">$ {item.Total_Price}</p>
                                        </div>

                                        <div className="item-row2">
                                            <div className="star-rating-item">
                                                <img
                                                    className="item-star-order"
                                                    src={starPic}
                                                    alt="star"
                                                />
                                                <p className="item-rating-order">4.5</p>
                                            </div>

                                            <p className="item-review-order">
                        35 {t('cart.reviews')}
                                            </p>
                                        </div>

                                        <div className="desc-add-item">
                                            <p className="item-desc-order">
                                                {shortDescription}...
                                                <button
                                                    onClick={() => handleMoreInfo(item)}
                                                    className="more-btn"
                                                >
                          More
                                                </button>
                                            </p>
                                            <div className="increment-container">
                                                <button
                                                    className="btn"
                                                    onClick={() => {
                                                        Decrement(item);
                                                    }}
                                                >
                          -
                                                </button>

                                                <p className="count-value">{item.Item_Quantity}</p>
                                                <button className="btn" onClick={() => Increment(item)}>
                          +
                                                </button>
                                            </div>
                                        </div>

                                       
                                    </div>
                                </div>
                            );
                        })}
                        {cartData?.length > 0 && (
                            <span className="cart-horizantal-line"></span>
                        )}
                        {cartData?.length > 0 && <AddOns />}
                        {cartData?.length > 0 && (
                            <div
                                className="address-container"
                                onClick={() => handleOpenAdressList()}
                            >
                                <div className="delivery-location-home-desc-container">
                                    <svg
                                        width="27"
                                        height="33"
                                        viewBox="0 0 27 33"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.4638 20.4199L10.8783 30.2964C11.4416 31.0466 12.3389 31.5319 13.3497 31.5319C14.3604 31.5319 15.2577 31.0466 15.821 30.2964L23.2355 20.4199C24.7829 18.359 25.6999 15.7977 25.6999 13.023C25.6999 6.20809 20.1708 0.683594 13.3502 0.683594C6.52965 0.683594 1 6.20809 1 13.023C1 15.7977 1.91698 18.359 3.46438 20.4199H3.4638Z"
                                            stroke="white"
                                            strokeWidth="1.33"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M16.4363 13.0183C16.4363 14.7218 15.0539 16.103 13.349 16.103C11.6441 16.103 10.2617 14.7218 10.2617 13.0183C10.2617 11.3149 11.6441 9.93359 13.349 9.93359C15.0539 9.93359 16.4363 11.3149 16.4363 13.0183Z"
                                            stroke="white"
                                            strokeWidth="1.33"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>

                                    <div className="delivert-to-home-desc-container">
                                        <h3 className="delivery-to-home-text">
                                            {' '}
                                            {t('cart.delivery-to')} {deliveryAddress}
                                        </h3>
                                        <p className="delivery-to-home-address">
                                            {t('cart.for-near-by-restaurants-and-quick-delivery')}
                                        </p>
                                    </div>
                                </div>

                                <svg
                                    width="17"
                                    height="10"
                                    viewBox="0 0 17 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M17 1.50743L8.5 10L1.01273e-07 1.50743L1.50875 1.92534e-06L8.5 6.98514L15.4913 2.09208e-06L17 1.50743Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        )}
                        {openAddressList && (
                            <Address
                                open={openAddressList}
                                handleClose={handleCloseAddressList}
                                handleUpdateDeliveryAddress={handleUpdateDeliveryAddress}
                            />
                        )}
                        <div className="items-andprice-container">
                            {cartData?.map((item, index) => {
                                return (
                                    <div key={index} className="item-name-and-price-container">
                                        <p>{item.MenuItem_Name}</p>
                                        <p>${item.MenuItem_Price * item.Item_Quantity}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="total-items-container">
                            {cartData?.length > 0 && (
                                <div className="total">
                                    <SubTotal totalPrice={totalPrice}></SubTotal>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>

            <div className="right-side-bar">
                <RightSideBar></RightSideBar>
            </div>
        </div>
    );
}

export default cart;
