import React,{useState} from 'react';
// import Box from '@mui/material/Box';
// import { Modal, Button, TextField } from '@mui/material';

// import Modal from '@mui/material/Modal';

// import PostDetail from './PostDetail';

import { Modal, Box, Button, TextField } from '@mui/material';
import SliderComponent from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PostModal.css';


import { IoMdClose } from 'react-icons/io';


// import SliderComponent from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import { IoMdClose } from 'react-icons/io';

function EditPostModal({handleClose,currentPostData}) {

    const settings = {
        dots: true,
        infinite: currentPostData.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        // afterChange: (index) => setCurrentIndex(index),
        initialSlide: 0, // Ensure initial slide is set to 0
    };


    const [description, setDescription] = useState('');
    const [tags, setTags] = useState('');
    const [menu, setMenu] = useState('');
    const [location, setLocation] = useState('');

    
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        width: '60%',
                        height: '80%',
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                        p: 2,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >

                    <Button
                        onClick={handleClose}
                        sx={{ position: 'absolute', top: -50, right: -50 ,color: 'white'}}
                    >
                        <IoMdClose size={30} color='white'/>
                    </Button>
                    <Box sx={{ flex: 1, overflow: 'hidden' }}>
                        <SliderComponent {...settings} className="carousel-last">
                            {currentPostData.map((file, index) => (
                                <div key={index}>
                                   
                                    <img
                                        src={file.image}
                                        alt={`Selected ${index}`}
                                        style={{

                                            marginTop:'50px',
                                            width: '100%',
                                            height: '350px',
                     
                                            objectFit:'contain',
                       
                                            // filter: `brightness(${filters[index]?.brightness || 100}%) contrast(${filters[index]?.contrast || 100}%)`,
                                               
                                        }}
                                    />
                                 
                                </div>
                            ))}
                        </SliderComponent>
                        {/* <Button onClick={handleNext} color="primary" sx={{ position: 'absolute', top: 10, right: 10 }}>
              Post
                        </Button>
                        <Button onClick={handleBack} color="primary" sx={{ position: 'absolute', top: 10, left: 10 }}>
              Back
                        </Button> */}
                        <button   className='next-btn-create-post'>
              save
                        </button>
                        <button onClick={handleClose}  className='back-btn-create-post'>
              cancel
                        </button>
                    </Box>
                    <Box sx={{ flex: 1, p: 2 }}>
                        <h2>Post Details</h2>
                        <TextField
                            label="Description"
                            fullWidth
                            multiline
                            rows={3}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Tags"
                            fullWidth
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Menu"
                            fullWidth
                            value={menu}
                            onChange={(e) => setMenu(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            label="Location"
                            fullWidth
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </Box>   
                </Box>
            </Modal>
        </div>
    );
}

export default EditPostModal;