import '../../VendorStyles/DeclinedRightbar.css';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 

function BusinessHours() {
    const [selectedDays, setSelectedDays] = useState([]);
    const [fromTime, setFromTime] = useState('');
    const [toTime, setToTime] = useState('');
    const [timings, setTimings] = useState([]);

    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    const generateTimeOptions = () => {
        const times = [];
        const periods = ['AM', 'PM'];

        for (let i = 0; i < 24; i++) {
            const period = periods[Math.floor(i / 12)];
            const hour = i % 12 === 0 ? 12 : i % 12;
            times.push(`${hour}:00 ${period}`);
            times.push(`${hour}:30 ${period}`);
        }

        return times.map((time, index) => (
            <option key={index} value={time}>
                {time}
            </option>
        ));
    };

    const handleDayClick = (day) => {
        setSelectedDays((prevSelectedDays) => {
            if (prevSelectedDays.includes(day)) {
                return prevSelectedDays.filter(d => d !== day);
            } else {
                const updatedDays = [...prevSelectedDays, day].sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b));
                return updatedDays;
            }
        });
    };

    const handleSubmit = () => {
        if (selectedDays.length > 0 && fromTime && toTime) {
            const groupedDays = groupConsecutiveDays(selectedDays);
            const newTimings = groupedDays.map(group => ({
                days: group.length > 1 ? `${group[0]}-${group[group.length - 1]}` : group[0],
                from: fromTime,
                to: toTime
            }));
            setTimings([...timings, ...newTimings]);
            // Clear the inputs
            setSelectedDays([]);
            setFromTime('');
            setToTime('');
            toast.success('Business hours updated successfully');
        } else {
            toast.error('Please fill in all fields');
        }
    };

    const groupConsecutiveDays = (days) => {
        const dayIndices = days.map(day => daysOfWeek.indexOf(day));
        dayIndices.sort((a, b) => a - b);

        const groups = [];
        let group = [daysOfWeek[dayIndices[0]]];

        for (let i = 1; i < dayIndices.length; i++) {
            if (dayIndices[i] === dayIndices[i - 1] + 1) {
                group.push(daysOfWeek[dayIndices[i]]);
            } else {
                groups.push(group);
                group = [daysOfWeek[dayIndices[i]]];
            }
        }
        groups.push(group);

        return groups.map(group => {
            if (group.length > 2) {
                return [group[0], group[group.length - 1]];
            }
            return group;
        });
    };

    const handleDelete = (index) => {
        const newTimings = timings.filter((_, i) => i !== index);
        setTimings(newTimings);
        toast.success('Timing removed successfully');
    };

    return (
        <div>
            <div>
                <h3 className="business-hours-title">Business Hours</h3>
            </div>
            <div className="days-container">
                {daysOfWeek.map((day, index) => (
                    <div 
                        className={`day ${selectedDays.includes(day) ? 'selected' : ''}`} 
                        key={index}
                        onClick={() => handleDayClick(day)}
                    >
                        <p className="day-txt">{day}</p>
                    </div>
                ))}
            </div>
            <div className="time-range-cont">
                <div className="from-cont">
                    <p className="from-text">From</p>
                    <select 
                        name="from-time" 
                        id="from-time" 
                        className="custom-select" 
                        value={fromTime} 
                        onChange={(e) => setFromTime(e.target.value)}
                    >
                        {generateTimeOptions()}
                    </select>
                </div>
                <div className="to-cont">
                    <p className="to-text">To</p>
                    <select 
                        name="to-time" 
                        id="to-time" 
                        className="custom-select" 
                        value={toTime} 
                        onChange={(e) => setToTime(e.target.value)}
                    >
                        {generateTimeOptions()}
                    </select>
                </div>
            </div>
            <button className='vendor-business-hours-submit-btn' onClick={handleSubmit}>Submit</button>
            <div className='timings-main-container'>
                {timings.map((timing, index) => (
                    <div key={index}>
                        <div className='timings-container'>
                            <div className='timings-left'>
                                <p className='day-name'>{timing.days}</p>
                                <p className='time-text'>{timing.from}-{timing.to}</p>
                            </div>
                            <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => handleDelete(index)}
                            >
                                <path d="M11.0958 13.8689H3.95538C3.80507 13.8653 3.65693 13.8322 3.51943 13.7714C3.38193 13.7106 3.25775 13.6233 3.15401 13.5144C3.05026 13.4056 2.96898 13.2774 2.91479 13.1372C2.86061 12.9969 2.83459 12.8474 2.83822 12.6971V4.97461H3.61945V12.6971C3.61572 12.7448 3.62147 12.7928 3.63635 12.8383C3.65124 12.8838 3.67498 12.9259 3.70619 12.9622C3.73741 12.9985 3.7755 13.0283 3.81826 13.0498C3.86103 13.0713 3.90763 13.0842 3.95538 13.0877H11.0958C11.1436 13.0842 11.1902 13.0713 11.2329 13.0498C11.2757 13.0283 11.3138 12.9985 11.345 12.9622C11.3762 12.9259 11.4 12.8838 11.4148 12.8383C11.4297 12.7928 11.4355 12.7448 11.4317 12.6971V4.97461H12.213V12.6971C12.2166 12.8474 12.1906 12.9969 12.1364 13.1372C12.0822 13.2774 12.0009 13.4056 11.8972 13.5144C11.7934 13.6233 11.6693 13.7106 11.5318 13.7714C11.3943 13.8322 11.2461 13.8653 11.0958 13.8689Z" fill="#6D7383" />
                                <path d="M12.5173 4.10349H2.44725C2.34366 4.10349 2.2443 4.06234 2.17105 3.98909C2.09779 3.91583 2.05664 3.81648 2.05664 3.71288C2.05664 3.60928 2.09779 3.50993 2.17105 3.43667C2.2443 3.36342 2.34366 3.32227 2.44725 3.32227H12.5173C12.6209 3.32227 12.7202 3.36342 12.7935 3.43667C12.8667 3.50993 12.9079 3.60928 12.9079 3.71288C12.9079 3.81648 12.8667 3.91583 12.7935 3.98909C12.7202 4.06234 12.6209 4.10349 12.5173 4.10349Z" fill="#6D7383" />
                                <path d="M8.69727 5.66602H9.47849V11.5252H8.69727V5.66602Z" fill="#6D7383" />
                                <path d="M5.57227 5.66602H6.35349V11.5252H5.57227V5.66602Z" fill="#6D7383" />
                                <path d="M9.47841 2.87691H8.73624V2.15037H6.31443V2.87691H5.57227V2.15037C5.57202 1.94977 5.64894 1.75676 5.7871 1.61132C5.92527 1.46589 6.11408 1.37917 6.31443 1.36914H8.73624C8.93659 1.37917 9.12541 1.46589 9.26357 1.61132C9.40174 1.75676 9.47866 1.94977 9.47841 2.15037V2.87691Z" fill="#6D7383" />
                            </svg>
                        </div>
                        {index < timings.length - 1 && <div className='neworders-right-dotted-line'></div>}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BusinessHours;
