import React, { useEffect, useState } from 'react';
import Profile1 from '../../../assets/Bloggers1.png';
// import Profile2 from '../../../assets/Bloggers2.png';
// import Profile3 from '../../../assets/Bloggers3.png';
import axios from 'axios';
import './Blogger.css';

function Blogger({ searchTerm }) {
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const [bloggersData, setBloggersData] = useState([]);
    console.log(bloggersData);

    useEffect(() => {
        const getBloggersData = async () => {
            const response = await axios.get(
                `${base_URL}/search/profileinfo?searchText=${searchTerm}`
            );
            setBloggersData(response.data.list_of_customers);
            console.log(response);
        };
        getBloggersData();
    }, []);

    const [visibleBloggers, setVisibleBloggers] = useState(10);

    const handleShowMore = () => {
        setVisibleBloggers((prev) => prev + 10);
    };

    const filteredBloggers = bloggersData.filter((blogger) =>
        blogger.Display_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="bloggers-main-container">
            {filteredBloggers.length > 0 ? (
                filteredBloggers.slice(0, visibleBloggers).map((blogger, index) => (
                    <div key={index} className="blogger-item">
                        <img className="blogger-img" src={Profile1} alt={blogger.name} />
                        <p>{blogger.Display_name}</p>
                    </div>
                ))
            ) : (
                <h5 className="no-data-found">No bloggers found</h5>
            )}
            {visibleBloggers < filteredBloggers.length &&
        filteredBloggers.length > 0 && (
                <button className="show-more-btn" onClick={handleShowMore}>
            Show More
                </button>
            )}
        </div>
    );
}

export default Blogger;
