import React, {lazy} from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
const DeliveryRegistration = lazy(()=>import('./Views/DeliveryPartner/DeliveryRegistration'));
const  PageNotFound = lazy(()=>import('./Views/User/PageNotFound/PageNotFound'));


function Deliveryrouter() {

   
    return (
        <div>
            

            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<DeliveryRegistration/>}></Route> 
                    <Route path='*' element={<PageNotFound/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default Deliveryrouter;