export const ActionTypes = {
    ADD_TO_CART: 'ADD_TO_CART',
    INCREMENT: 'INCREMENT',
    DECREMENT: 'DECREMENT',
    SENDING_DATA_TO_RESTAURANT_PAGE: 'SENDING_DATA_TO_RESTAURANT_PAGE',
    SENDING_DATA_TO_RESTAURANT_ITEMS: 'SENDING_DATA_TO_RESTAURANT_ITEMS',
    SELECTED_ITEM_DATA: 'SELECTED_ITEM_DATA',
    SELECTED_VENDOR_ID: 'SELECTED_VENDOR_ID',
    SET_USER_ROLE: 'SET_USER_ROLE',
};
