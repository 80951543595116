import { toast } from 'react-toastify';
import { ActionTypes } from '../Contants/action-types';

const initialState = {
    cart: [],
    restaurantpage: '',
    restaurantitems: '',
    selecteditemdata: null,
    selectedvendorid: localStorage.getItem('selectedvendorid') || 0,

    userRole: localStorage.getItem('userRole') || 'user',
};

export const ItemReducer = (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.ADD_TO_CART: {
        const ItemIndex = state.cart.findIndex(
            (item) => item.Item_ID === action.payload.Item_ID
        );

        if (ItemIndex >= 0) {
            return state;
        } else {
            const updatedCart = [...state.cart, { ...action.payload }];

            return {
                ...state,

                cart: updatedCart,
            };
        }
    }

    case ActionTypes.INCREMENT: {
        // Map over the outer array (cart)
        let updatedCart = state.cart.map((cartObj) => {
        // Use Object.keys to iterate over the keys (0, 1, etc.) inside each object
            let updatedCartObj = Object.keys(cartObj).reduce((acc, key) => {
                let curElem = cartObj[key];

                // Check if the current item's Item_id matches the payload's Item_id
                if (curElem.Item_ID === action.payload.Item_ID) {
                    toast.success('Item quantity increased');

                    // Update the item quantity
                    return {
                        ...acc,
                        [key]: {
                            ...curElem,
                            Item_Quantity: curElem.Item_Quantity + 1,
                        },
                    };
                }

                // If no match, return the current item unchanged
                return {
                    ...acc,
                    [key]: curElem,
                };
            }, {});

            return updatedCartObj;
        });

        console.log(updatedCart);

        // Store updated cart in localStorage if needed
        // localStorage.setItem('cart', JSON.stringify(updatedCart));

        // Return the new state with the updated cart
        return {
            ...state,
            cart: updatedCart,
        };
    }

    case ActionTypes.DECREMENT: {
        let updatedCart = state.cart
            .map((cartObj) => {
                // Use Object.keys to iterate over the keys (0, 1, etc.) inside each object
                let updatedCartObj = Object.keys(cartObj).reduce((acc, key) => {
                    let curElem = cartObj[key];

                    // Check if the current item's Item_id matches the payload's Item_id
                    if (curElem.Item_ID === action.payload.Item_ID) {
                        // Decrease the quantity
                        const newQuantity = curElem.Item_Quantity - 1;

                        // If quantity becomes 0, don't include this item in the updated cart
                        if (newQuantity === 0) {
                            return acc; // Skip adding this item to the accumulator (it gets removed)
                        } else {
                            // Otherwise, update the item's quantity
                            return {
                                ...acc,
                                [key]: {
                                    ...curElem,
                                    Item_Quantity: newQuantity,
                                },
                            };
                        }
                    }

                    // If no match, return the current item unchanged
                    return {
                        ...acc,
                        [key]: curElem,
                    };
                }, {});

                return updatedCartObj;
            })
            .filter((cartObj) => Object.keys(cartObj).length !== null);

        // .filter((cartObj) => Object.keys(cartObj).length > 0); // Filter out any empty cart objects (items with 0 quantity)

        // Check if any items were removed from the cart
        if (updatedCart.length < state.cart.length) {
            toast.warning('Item removed from cart');
        } else {
            toast.success('Item quantity decreased');
        }

        // Store updated cart in localStorage if needed
        // localStorage.setItem('cart', JSON.stringify(updatedCart));

        // Return the new state with the updated cart
        return {
            ...state,
            cart: updatedCart,
        };
    }

    case ActionTypes.SENDING_DATA_TO_RESTAURANT_PAGE:
        return {
            ...state,
            restaurantpage: [action.payload],
        };

    case ActionTypes.SENDING_DATA_TO_RESTAURANT_ITEMS:
        return {
            ...state,
            restaurantitems: [action.payload],
        };

    case ActionTypes.SELECTED_ITEM_DATA:
        return {
            ...state,
            selecteditemdata: action.payload,
        };

    case ActionTypes.SELECTED_VENDOR_ID:
        // Save vendor ID to localStorage
        localStorage.setItem('selectedvendorid', action.payload);
        return {
            ...state,
            // previousselectedvendorid:state.selectedvendorid,
            selectedvendorid: action.payload,
        };

    case ActionTypes.SET_USER_ROLE:
        localStorage.setItem('userRole', action.payload);

        return { ...state, userRole: action.payload };

    default:
        return state;
    }
};
