import React from 'react';

import star from '../../../assets/Star.png'; // Assuming the star icon is stored in a file named 'star.png'
import restaurant1 from '../../../assets/nbrestaurants1.png';
import './Food.css';

function Restaurant({ searchTerm, results }) {
    console.log(results);
    const validResults = results || [];

    console.log('Restaurant clicked now');

    const filteredRestaurants = validResults.filter((restaurant) =>
        restaurant.Restaurant_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    console.log(filteredRestaurants);
    return (
        <div className="food-container-fake">
            <div className="Food-Nearby-restaurants-cards-container">
                {filteredRestaurants.length > 0 ? (
                    filteredRestaurants.map((restaurant, index) => (
                        <div key={index} className="Food-search-restaurants-card">
                            <img
                                className="Food-search-restaurants-image"
                                src={restaurant1}
                                alt="Food img"
                            />
                            <span className="Food-search-restaurants-text">
                                {restaurant.Restaurant_name}
                            </span>
                            <div className="Food-search-restaurant-footer">
                                <span className="Food-search-restaurant-dist">
                                    {restaurant.Distance_in_Miles} m
                                </span>

                                <div className="Food-dist-layout">
                                    <img
                                        className="Food-search-rest-star-icon-image"
                                        src={star}
                                        alt="search-rest-star icon"
                                    />
                                    <span className="Food-search-rest-reviews">
                                        {restaurant.AvgRating} reviews
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <h5 className="no-data-found">No Restaurant Found</h5>
                )}
            </div>
        </div>
    );
}

export default Restaurant;
