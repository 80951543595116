import '../../VendorStyles/DeclinedRightbar.css';
import VendorRightSidebar from './VendorRightSideBar';
import Customrange from './CustomRange';
function DeclinedRightbar(){
    return(
        <><div className="declined-vertical-line"></div><div className="declined-rightsidebar-main-cont">
            <VendorRightSidebar></VendorRightSidebar>
            <Customrange></Customrange>
        </div></>
    );
}
export default DeclinedRightbar;