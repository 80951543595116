import React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import SavedItemDetails from './SavedItemDetail';
import './PostModal.css';

function SavedModal({ handleClose, open, Saved }) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="post-modal">
                    <SavedItemDetails Saved={Saved} handleClose={handleClose} />
                </Box>
            </Modal>
        </div>
    );
}

export default SavedModal;
