import React, { useEffect, useState, useRef } from 'react';
import '../../../Styles/Reels/ReelsIndex.css';

// import videoUrls from './Data';
import VideoCard from '../../../Components/VideoCard';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';

import axios from 'axios';

function Reels() {
    // const [videos, setVideos] = useState([]);
    // console.log(videos);

    const base_URL = process.env.REACT_APP_API_BASE_URL;

    // const base_URL = 'http://localhost:5002/api';

    const [postData, setPostData] = useState([]);
    const [restaurant, setRestaurant] = useState([]);
    // console.log(postData);

    const [ismuted, setIsMuted] = useState(true); // State to manage mute/unmute for all reels
    const [hasMoreData, setHasMoreData] = useState(true); // Flag for more data availability

    const handleMute = () => {
        setIsMuted((prevMuted) => !prevMuted);
    };

    const [globalMuteIcon, setGlobalMuteIcon] = useState(true); // Global mute state

    const toggleMuteIcon = () => {
        setGlobalMuteIcon((prevMute) => !prevMute);
    };

    const [page, setPage] = useState(1);

    const videoRefs = useRef([]);

    const getPosts = async () => {
        const response = await axios.get(
            `${base_URL}/posts/17.4072147,78.492518/page/${page}?pageSize=10`
        );
        // setVideos(response.data.posts.result[0].Post_Information[0]);
        // console.log(response.data.posts.result[0].Post_Information);
        const postInformation = response.data.posts.result[0].Post_Information;

        // setPostData(postInformation);
        // Check if there is more data to load
        if (postInformation?.length > page) {
            setPostData((prevData) =>
                page === 1 ? [...postInformation] : [...prevData, ...postInformation]
            );
            setRestaurant((prevData) =>
                page === 1 ? [...postInformation] : [...prevData, ...postInformation]
            );
        } else {
            setHasMoreData(false); // No more data to load
        }
    // setPostData((prevData)=> page === 1 ? [...postInformation] : [...prevData, ...postInformation]);
    // Log all Post IDs to the console
    // const postIds = postInformation.map(post => post.Post_ID);
    // console.log('Post IDs:', postIds);
    };

    const handleInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
            ) {
                if (hasMoreData) {
                    setPage((prev) => prev + 1);
                }

                // setPage((prev) => prev + 1);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
    // setVideos(videoUrls);

        getPosts();
    }, [page]);

    useEffect(() => {
        window.addEventListener('scroll', handleInfiniteScroll);

        return () => window.removeEventListener('scroll', handleInfiniteScroll);
    }, [hasMoreData]);

    // const [ cartCount,setCartCount] = useState(0);

    // const fetchCartCount = async()=>{

    //     // Fetch item data from the API using the itemID
    //     const itemResponse = await axios.get(`${base_URL}/cartdetails/1/1`);
    //     console.log(itemResponse.data.cartDetails.result[0].Cart_Information.length);
    //     // const data = itemResponse.data.cartDetails.result[0].Cart_Information.length;

    //     setCartCount(itemResponse.data.cartDetails.result[0].Cart_Information.length);

    // };

    // useEffect(()=>{

    //     fetchCartCount();

    // },[]);

    // useEffect(() => {
    //     const handleIntersection = (entries) => {
    //         entries.forEach((entry) => {
    //             if (entry.isIntersecting) {
    //                 const videoElement = entry.target;
    //                 videoElement.play();
    //             } else {
    //                 const videoElement = entry.target;
    //                 videoElement.pause();
    //             }
    //         });
    //     };

    //     const observer = new IntersectionObserver(handleIntersection, {
    //         threshold: 0.7,
    //     });
    //     videoRefs.current.forEach((videoRef) => {
    //         observer.observe(videoRef);
    //     });

    //     return () => {
    //         observer.disconnect();
    //     };
    // }, []);

    // Intersection Observer API to observe videos
    useEffect(() => {
        if (!postData.length) return; // Only observe if postData exists

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const video = entry.target;
                    if (entry.isIntersecting) {
                        video.play();
                    } else {
                        video.pause();
                    }
                });
            },
            {
                threshold: 0.75, // Trigger the video when 75% of it is visible
            }
        );

        // Observe each video
        videoRefs.current.forEach((videoRef) => {
            if (videoRef) {
                observer.observe(videoRef);
            }
        });

        // Cleanup observer when component unmounts or postData changes
        return () => {
            observer.disconnect();
        };
    }, [postData]);

    const handleVideoRef = (index) => (ref) => {
        videoRefs.current[index] = ref;
    };

    return (
        <>
            <div className="reels-dashboard">
                <div className="topmenubar">
                    <SideMenuBar></SideMenuBar>

                    {/* <SideMenuBar></SideMenuBar> */}

                    <div className="top-bar-mobile">
                        <TopMenuBar />
                    </div>
                </div>

                <div className="main-container">
                    <div className="video-container">
                        {/* here we map over rhe videos array and imported videocard component */}
                        {postData?.map((post, index) => {
                            const attachmentKeys = post?.Attachment_keys || [];

                            // // Assuming post.Attachment_keys is an array of objects
                            // let  url = post.Attachment_keys?.Avatar_key || ''; // The URL you want to log

                            // // Console log to see the URL before passing it to VideoCard
                            // console.log('Attachment Key (URL):', url);

                            return attachmentKeys?.map((attachment) => (
                                <VideoCard
                                    // key={index}
                                    // id={video.Post_ID}
                                    // video={video}
                                    // url={video}
                                    // profilePic={video.profilePic}
                                    // userName={video.userName}
                                    // restaurantName={video.restaurantName}
                                    // starPic={video.starPic}
                                    // rating={video.rating}
                                    // description1={video.description1}
                                    // description2={video.description2}
                                    // distance={video.distance}
                                    // order={video.order}
                                    // likes={video.likes}
                                    // comments={video.comments}
                                    // shares={video.shares}
                                    // videos={postData}
                                    // setVideoRef={handleVideoRef(index)}
                                    // ismuted={ismuted}
                                    // handleMute={handleMute}
                                    // globalMuteIcon={globalMuteIcon}
                                    // toggleMuteIcon={toggleMuteIcon}

                                    key={post.Post_ID}
                                    id={post.Post_ID}
                                    vendorID={post.Post_Vendor_id}
                                    AccountID={post.Post_Account_id}
                                    url={attachment.Avatar_key}
                                    attachmentKeys={post.Attachment_keys || []}
                                    profilePic={post.Account_Avatar_key || ''}
                                    userName={post.Account_Display_name || ''}
                                    restaurantName={post?.Restaurant_Info[0]?.Vendor_Name || ''}
                                    starPic={post?.Restaurant_Info[0]?.Star_Image || ''}
                                    rating={post?.Restaurant_Info[0]?.Vendor_Rating || 'N/A'}
                                    distance={post.Distance_in_Miles}
                                    description={post.Post_Description}
                                    likes={post.Post_Likes_count}
                                    shares={post.Post_Shares_count}
                                    comments={post.Post_Comments_count}
                                    setLike={() => {}}
                                    handleMute={handleMute}
                                    ismuted={ismuted}
                                    globalMuteIcon={globalMuteIcon}
                                    toggleMuteIcon={toggleMuteIcon}
                                    setVideoRef={handleVideoRef(index)}
                                    autoplay={index === 0}
                                    restaurant={restaurant}
                                />
                            ));
                        })}
                    </div>
                </div>

                {/* <div className="sidemenubar">
            <div className="bottom-bar-mobile">
              <SideMenuBar></SideMenuBar>
            </div>
          </div> */}

                <div className="right-side-bar">
                    <RightSideBar></RightSideBar>
                </div>
            </div>
        </>
    );
}

export default Reels;
