import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from './Redux/Store';


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

 
// import {ReactKeycloakProvider} from '@react-keycloak/web';
// import kcConfig from './keyCloakAuth';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <>
        {/* <React.StrictMode> */}

        {/* <ReactKeycloakProvider
         authClient={kcConfig}
         initOptions={{onLoad:'login-required',checkLoginIframe:false}}
    
     > */}
  
        <Provider store={store}>
            
           
            <App />
            <ToastContainer />
           
 
        </Provider>
   
       
        {/* </ReactKeycloakProvider>  */}
        {/* </React.StrictMode>  */}

    </> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
