import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    useMediaQuery,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tab,
    Tabs,
    Grid,
    Radio,
    RadioGroup,
    Checkbox,
    FormControlLabel,
    useTheme,
} from '@mui/material';
import filtericon from '../../../assets/Filtericon.png';
import './Filters.css';
function Filters() {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [tabValue, setTabValue] = useState(0);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const handleApplyFilters = () => {
    // Logic to apply filters
        handleClose();
    };

    return (
        <div className="Filters-main-container">
            <Button
                onClick={handleOpen}
                startIcon={
                    <img src={filtericon} width="20px" height="18px" alt="filter icon" />
                }
                sx={{
                    borderRadius: '22px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    backgroundColor: '#FFFFFF',
                    color: '#50555C',
                    height: '35px',
                    border: '#000',
                    boxShadow: '0px 0px 4px 0px #0000001A',
                    width: '90px',
                }}
            >
                {t('homescreen.filter-button')}
            </Button>
            <Dialog open={open} onClose={handleClose} width="70%" height="300px">
                <DialogTitle className="filters-title">
                    {t('homescreen.filter-title')}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} className="width-of-dialog-box">
                        <Grid item xs={isMobile ? 4 : 5}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                orientation="vertical"
                                textColor="primary"
                            >
                                <Tab label={t('homescreen.sort-by-tab')} />
                                <Tab label={t('homescreen.delivery-time-tab')} />
                                <Tab label={t('homescreen.cuisine-tab')} />
                                <Tab label={t('homescreen.explore-tab')} />
                                <Tab label={t('homescreen.ratings-tab')} />
                                <Tab label={t('homescreen.veg-or-nonveg-tab')} />
                                <Tab label={t('homescreen.offers-tab')} />
                            </Tabs>
                        </Grid>
                        <Grid item xs={isMobile ? 6 : 7}>
                            <div className="css-mhc70k-MuiGrid-root MuiGrid-item">
                                {tabValue === 0 && (
                                    <RadioGroup>
                                        <FormControlLabel
                                            value="Relevance"
                                            control={<Radio />}
                                            label={t('homescreen.sort-by-tab-val1')}
                                        />
                                        <FormControlLabel
                                            value="Delivery Time"
                                            control={<Radio />}
                                            label={t('homescreen.sort-by-tab-val2')}
                                        />
                                        <FormControlLabel
                                            value="Rating"
                                            control={<Radio />}
                                            label={t('homescreen.sort-by-tab-val3')}
                                        />
                                        <FormControlLabel
                                            value="priceLowToHigh"
                                            control={<Radio />}
                                            label={t('homescreen.sort-by-tab-val4')}
                                        />
                                        <FormControlLabel
                                            value="priceHighToLow"
                                            control={<Radio />}
                                            label={t('homescreen.sort-by-tab-val5')}
                                        />
                                    </RadioGroup>
                                )}
                                {tabValue === 1 && (
                                    <div className="checkbox--1">
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('homescreen.delivery-time-tab-val1')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('homescreen.delivery-time-tab-val2')}
                                        />
                                    </div>
                                )}
                                {tabValue === 2 && (
                                    <div className="checkbox--1">
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('homescreen.cuisine-tab-val1')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('homescreen.cuisine-tab-val2')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('homescreen.cuisine-tab-val3')}
                                        />
                                    </div>
                                )}
                                {tabValue === 3 && (
                                    <div className="checkbox--1">
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('homescreen.explore-tab-val1')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('homescreen.explore-tab-val2')}
                                        />
                                    </div>
                                )}
                                {tabValue === 4 && (
                                    <div className="checkbox--1">
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('homescreen.ratings-tab-val1')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('homescreen.ratings-tab-val2')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('homescreen.ratings-tab-val3')}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            label={t('homescreen.ratings-tab-val4')}
                                        />
                                    </div>
                                )}
                                {tabValue === 5 && (
                                    <RadioGroup>
                                        <FormControlLabel
                                            value="Pure Veg"
                                            control={<Radio />}
                                            label={t('homescreen.veg-or-nonveg-tab-val1')}
                                        />
                                        <FormControlLabel
                                            value="Pure Non-Veg"
                                            control={<Radio />}
                                            label={t('homescreen.veg-or-nonveg-tab-val2')}
                                        />
                                        <FormControlLabel
                                            value="Veg and Non-Veg"
                                            control={<Radio />}
                                            label={t('homescreen.veg-or-nonveg-tab-val3')}
                                        />
                                    </RadioGroup>
                                )}
                                {tabValue === 6 && (
                                    <RadioGroup>
                                        <FormControlLabel
                                            value=">90%"
                                            control={<Radio />}
                                            label={t('homescreen.offers-tab-val1')}
                                        />
                                        <FormControlLabel
                                            value=">70%"
                                            control={<Radio />}
                                            label={t('homescreen.offers-tab-val2')}
                                        />
                                        <FormControlLabel
                                            value=">50%"
                                            control={<Radio />}
                                            label={t('homescreen.offers-tab-val3')}
                                        />
                                    </RadioGroup>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('homescreen.close-button')}</Button>
                    <Button
                        onClick={handleApplyFilters}
                        variant="contained"
                        className="filters-apply-btn"
                    >
                        {t('homescreen.apply-button')}
                    </Button>{' '}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Filters;
