import FollowToWatchReels from './FollowToWatchReels';
import PrevOrders from './PreviousOrders';
import OffersForYou from './OffersForYou';
import Restaurantnearyou from './Restaurantnearyou';
import './Home.css';
function Home() {
    return (
        <div className="home-page">
            <FollowToWatchReels></FollowToWatchReels>
            <PrevOrders></PrevOrders>
            <OffersForYou></OffersForYou>
            <Restaurantnearyou></Restaurantnearyou>
        </div>
    );
}
export default Home;
