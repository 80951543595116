

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import { Link } from 'react-router-dom';
import './Profilesettings.css';
import prof from '../../../assets/profilephoto.png';
import bell from '../../../assets/notification.png';
import gal from '../../../assets/gallery.png';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
export default function Editprofile() {
    const base_URL = process.env.REACT_APP_API_BASE_URL;

    // const base_URL ='http://localhost:5002/api';

    // const [profileImage, setProfileImage] = useState(prof); // Initialize with default image
    const [profileImage, setProfileImage] = useState(null); // Stores the file for uploading
    const [profileImagePreview, setProfileImagePreview] = useState(prof); // Preview image


    console.log(profileImage);
    const { t } = useTranslation();

    const [firstName, setFirstName] = useState('');
    
    const [lastName, setLastName] = useState('');
    const [dob, setDob] = useState('');

    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [dobError, setDobError] = useState('');

    useEffect(() => {
        // Fetch the user data from the API
        axios.get(`${base_URL}/customers/account/4`)
            .then(response => {
                const data = response.data.profileDetails[0];
                setFirstName(data.First_name || '');
                setLastName(data.Last_name || '');
                const formattedDate = data.Date_of_birth ? data.Date_of_birth.split('T')[0] : '';
                setDob(formattedDate);
            })
            .catch(error => console.error('Error fetching user data:', error));
    }, []);

    


    const handleGalleryClick = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
            // Set the file object for uploading later
            setProfileImage(file);
    
            // For preview purposes, convert the image to base64
            const reader = new FileReader();
            reader.onload = () => {
                // Update the UI with the image preview
                setProfileImagePreview(reader.result); // New state for image preview (optional)
            };
            reader.readAsDataURL(file);
        }
    };
    

    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        if (!value) {
            setFirstNameError('First name cannot be blank');
        } else if (value.length > 50) {
            setFirstNameError('First name cannot be longer than 50 characters');
        } else {
            setFirstNameError('');
        }
        setFirstName(value);
    };

    const handleLastNameChange = (e) => {
        const value = e.target.value;
        if (!value) {
            setLastNameError('Last name cannot be blank');
        } else if (value.length > 25) {
            setLastNameError('Last name cannot be longer than 25 characters');
        } else {
            setLastNameError('');
        }
        setLastName(value);
    };

    const handleDobChange = (e) => {
        const value = e.target.value;
        if (!value) {
            setDobError('Please enter date of birth');
        } else {
            setDobError('');
        }
        setDob(value);
    };

 


    const handleSaveChanges = () => {
        let isValid = true;
    
        if (!firstName) {
            setFirstNameError('First name cannot be blank');
            isValid = false;
        } else if (firstName.length > 50) {
            setFirstNameError('First name cannot be longer than 50 characters');
            isValid = false;
        }
    
        if (!lastName) {
            setLastNameError('Last name cannot be blank');
            isValid = false;
        } else if (lastName.length > 25) {
            setLastNameError('Last name cannot be longer than 25 characters');
            isValid = false;
        }
    
        if (!dob) {
            setDobError('Please enter date of birth');
            isValid = false;
        }
    
        if (isValid) {
            // Create FormData object to send the profile image and other data
            const formData = new FormData();
            formData.append('First_name', firstName);
            formData.append('Last_name', lastName);
            formData.append('Date_of_birth', dob);
            formData.append('Updated_by', 1);
    
           
            

            // Append the profile image file to FormData if it's selected
            if (profileImage) {
                formData.append('file', profileImage);  // Send file to backend
            }
    
            // Send updated data to the server
            axios.put(`${base_URL}/customers/account/4`, formData)
                .then(response => {
                    console.log('Response data:', response.data);
                    toast.success('Your profile updated successfully');
                })
                .catch(error => console.error('Error saving changes:', error));
        }

        
    };
    

    return (
        <>
            <div className='editprofile-top-main'>
                <div className='sidebar'>
                    <SideMenuBar />
                </div>
                <div className='top-bar'>
                    <TopMenuBar />
                </div>
                <div className='edit-profile-main-c'>
                    <div className='edit-profile-top1'>
                        <Link to='/profilesettings'>
                            <div className='ico1'>
                                <svg width="22" height="25" viewBox="0 0 42 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M38.9909 3L3 39L39 75" stroke="#464B59" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </div>
                        </Link>
                        <div className='edit-profile-text-layout'>
                            <h5 className='edit-profile-text'>{t('editprofile.editprofile-title')}</h5>
                        </div>
                        <img src={bell} alt='bell' className='msg-icon' />
                    </div>
                    <div className='edit-profile-bottom'>
                        <div className='profile-gallary-container'>
                            <img src={profileImagePreview} alt='profilephoto' className='profile-photo' />
                            <div className='gallary-container'>
                                <input type="file" id="fileInput" accept="image/*" style={{ display: 'none' }} onChange={handleGalleryClick} />
                                <label htmlFor="fileInput">
                                    <img src={gal} alt='gallery-icon' className='gallary-img' />
                                </label>
                            </div>
                        </div>
                        <form className='person-data-container'>
                            <div className='name-container'>
                                <div className='firstname-container'>
                                    <p className='profile-head-text'>{t('editprofile.first-name-title')}</p>
                                    <TextField
                                        className='txtfld-1'
                                        placeholder={t('editprofile.first-name-placeholder')}
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        error={!!firstNameError}
                                        helperText={firstNameError}
                                    />
                                </div>
                                <div className='lastname-container'>
                                    <p className='profile-head-text'>{t('editprofile.last-name-title')}</p>
                                    <TextField
                                        placeholder={t('editprofile.last-name-placeholder')}
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                        error={!!lastNameError}
                                        helperText={lastNameError}
                                    />
                                </div>
                            </div>
                            <div className='dob-container'>
                                <p className='profile-head-text'>{t('editprofile.dob-title')}</p>
                                <TextField
                                    type="date"
                                    placeholder={t('editprofile.dob-placeholder')}
                                    value={dob}
                                    onChange={handleDobChange}
                                    error={!!dobError}
                                    helperText={dobError}
                                />
                            </div>
                            <div className='save-changes-layout'>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: 'var(--color4)', color: '#FFFFFF' }}
                                    onClick={handleSaveChanges}
                                    className='save-changes-button'
                                >
                                    {t('editprofile.save-changes-button')}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
