import React, { createContext, useContext, useState,useEffect } from 'react';
// import { useSelector } from 'react-redux';
import axios from 'axios';
// Create a context for the cart
const CartContext = createContext();

// Create a provider component
export const CartProvider = ({ children }) => {


    const base_URL = process.env.REACT_APP_API_BASE_URL;

    // const  base_URL = 'http://localhost:5002/api';

    // const vendorID= useSelector((state)=>{
    //     return state.ItemReducer.selectedvendorid;
    // });
    // console.log(vendorID);
    
    const [ cartCount,setCartCount] = useState(0);



    const fetchCartCount = async()=>{

        // Fetch item data from the API using the itemID
        const itemResponse = await axios.get(`${base_URL}/cartdetails/1`);
        console.log(itemResponse.data.cartDetails.cartDetails[0].result[0].Cart_Information?.length);
        // const data = itemResponse.data.cartDetails.result[0].Cart_Information.length;
       
        setCartCount(itemResponse.data.cartDetails.cartDetails[0].result[0].Cart_Information?.length);
      
  
       

        

    };

    useEffect(()=>{

        fetchCartCount();

    },[]);
    
    return (
        <CartContext.Provider value={{ cartCount, fetchCartCount }}>
            {children}
        </CartContext.Provider>
    );
};

// Custom hook to use the cart context
export const useCart = () => {
    return useContext(CartContext);
};
