import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
// import img1 from '../../../assets/vectorblack.png';
import img2 from '../../../assets/profilephoto.png';
import img3 from '../../../assets/onlinestatus.png';

import vid5 from '../Videos/video5.mp4';
import vid6 from '../Videos/video6.mp4';
import './Profilescreen.css';
import PostModal from './PostModal';
import ReelsModal from './ReelsModal';
import SavedModal from './SavedModal';
import { useTranslation } from 'react-i18next';
import { FaComment } from 'react-icons/fa';
import { FcLike } from 'react-icons/fc';
import './Profilescreen.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Profilescreen = () => {
    // http://34.225.61.9:3021/api/search/profileinfo/
    // const user_Base_URL = process.env.REACT_APP_USER_API_BASE_URL;
    const user_Base_URL = 'http://localhost:5004/api';

    const { t } = useTranslation();
    const [contentType, setContentType] = useState('posts');
    const [Posts, setPosts] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    console.log(selectedImageIndex);
    const navigate = useNavigate();

    const [Reels, setReels] = useState(null);
    console.log(Reels);

    const [Saved, setSaved] = useState(null);
    console.log(Saved);
    const containerRef = useRef(null);

    const [showDetails, setShowDetails] = useState(null);

    const handleMouseEnter = (postId) => {
        setShowDetails(postId);
    };

    const handleMouseLeave = () => {
        setShowDetails(null);
    };

    const [openPostModal, setOpenPostModal] = useState(false);
    const handleOpenPostModal = () => setOpenPostModal(true);
    const handleClosePostModal = () => setOpenPostModal(false);

    const [openReelModal, setOpenReelModal] = useState(false);
    const handleOpenReelModal = () => setOpenReelModal(true);
    const handleCloseReelModal = () => setOpenReelModal(false);

    const [openSavedModal, setOpenSavedModal] = useState(false);
    const handleOpenSavedModal = () => setOpenSavedModal(true);
    const handleCloseSavedModal = () => setOpenSavedModal(false);

    const [images, setImages] = useState([]);
    console.log(images);
    const [videos, setVideos] = useState([]);
    console.log(videos);

    const handleContentTypeChange = (type) => {
        setContentType(type);
    };

    const [userData, setUserData] = useState([]);

    console.log(userData);

    const fetchUserData = async () => {
        const response = await axios.get(`${user_Base_URL}/account/3`);
        setUserData(response.data.user.result[0]);
        console.log(response);
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    // Function to classify attachments as images or videos
    const classifyAttachments = (attachments) => {
        const imageAttachments = [];
        const videoAttachments = [];

        attachments.forEach((attachment) => {
            const url = attachment.Avatar_key;

            // Check if the URL ends with common image or video extensions
            if (url.match(/\.(jpeg|jpg|gif|png)$/)) {
                imageAttachments.push(attachment);
            } else if (url.match(/\.(mp4|mov|avi)$/)) {
                videoAttachments.push(attachment);
            }
        });

        // Update the state with classified attachments
        setImages((prevImages) => [...prevImages, ...imageAttachments]);
        setVideos((prevVideos) => [...prevVideos, ...videoAttachments]);
    };

    // Fetch API data and classify attachments
    const fetchPosts = async () => {
        try {
            const response = await axios.get(`${user_Base_URL}/account/3`); // Replace with your API endpoint
            const fetchedPosts = response.data.user.result[0].Posts;
            console.log(fetchedPosts);

            // Loop through each post's Attachments and classify them
            fetchedPosts.forEach((post) => {
                if (post.Attachments && post.Attachments.length > 0) {
                    classifyAttachments(post.Attachments);
                }
            });
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };

    useEffect(() => {
        fetchPosts();
    }, []);

    const saved = [
        {
            id: 5,
            video: vid5,
            likes: 100,
            comment: 50,
            comments: ['price ?', ' u r from ?', 'i will visit soon'],
        },
        {
            id: 6,
            video: vid6,
            likes: 100,
            comment: 50,
            comments: [
                'hiii',
                'good video',
                'i like this food',
                'hiii',
                'good video',
                'i like this food',
            ],
        },
    ];

    const handleSelectedImageIndex = (id) => {
        setSelectedImageIndex(id);
    };

    const handleSavedClick = (saved) => {
        setSaved(saved);
        setReels(saved); // remove it ( just written error purpose )
    };

    const handleNavigate = () => {
        navigate('/profilesettings');
    };
    // const [refreshCount, setRefreshCount] = useState(0);

    // Function to delete post by ID
    const handleDeletePost = (postId) => {
        console.log(postId);
        const filteredPosts = images.filter((post) => post.Post_id !== postId);
        console.log(filteredPosts);
        setPosts(filteredPosts);
        setOpenPostModal(false); // Close modal after deletion
    };

    return (
        <div className="profile-dashboard">
            <div className="profile-side-bar">
                <SideMenuBar></SideMenuBar>
            </div>
            <div className="profile-home">
                <div className="profile-Topbar-cont">
                    <TopMenuBar></TopMenuBar>
                </div>
                <div className="profile-page-cont">
                    <div className="profile-page" ref={containerRef}>
                        <div className="profilepage-main-cont">
                            <div className="Topofpage-Main-container">
                                <div className="Top1-layout">
                                    <Link to="/">
                                        <svg
                                            width="30"
                                            height="30"
                                            viewBox="0 0 16 27"
                                            fill="none"
                                            className="profile-vector-icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M13.5881 27L-2.36041e-06 13.5L13.5881 2.37582e-06L16 2.39625L4.82378 13.5L16 24.6038L13.5881 27Z"
                                                fill="#2C3142"
                                            />
                                        </svg>
                                    </Link>
                                    <div className="Edit-button-layout">
                                        {/* <Link  to='/profilesettings'> */}
                                        <svg
                                            width="48"
                                            height="41"
                                            viewBox="0 0 48 41"
                                            fill="none"
                                            className="menuicon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            onClick={() => handleNavigate()}
                                        >
                                            <path
                                                d="M38.6667 29.041H9.33333C8.59695 29.041 8 29.5509 8 30.1799C8 30.8089 8.59695 31.3188 9.33333 31.3188H38.6667C39.403 31.3188 40 30.8089 40 30.1799C40 29.5509 39.403 29.041 38.6667 29.041Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M38.6667 19.3613H9.33333C8.59695 19.3613 8 19.8712 8 20.5002C8 21.1292 8.59695 21.6391 9.33333 21.6391H38.6667C39.403 21.6391 40 21.1292 40 20.5002C40 19.8712 39.403 19.3613 38.6667 19.3613Z"
                                                fill="white"
                                            />
                                            <path
                                                d="M38.6667 9.68164H9.33333C8.59695 9.68164 8 10.1915 8 10.8205C8 11.4495 8.59695 11.9594 9.33333 11.9594H38.6667C39.403 11.9594 40 11.4495 40 10.8205C40 10.1915 39.403 9.68164 38.6667 9.68164Z"
                                                fill="white"
                                            />
                                        </svg>
                                        {/* </Link> */}
                                    </div>
                                </div>
                                <div className="profile-container-2">
                                    <div className="profile-status-layout">
                                        <img
                                            src={img2}
                                            alt="profile-img"
                                            className="profile-photo"
                                        />
                                        <img
                                            src={img3}
                                            alt="status-symbol"
                                            className="profile-status-icon"
                                        />
                                    </div>
                                    <div className="Top-right-layout">
                                        <div className="profile-name-layout">
                                            <p className="profile-name-text">
                                                {userData.Account_First_Name}{' '}
                                                {userData.Account_Last_name}
                                            </p>
                                        </div>
                                        <div className="profile-description-layout">
                                            <p className="profile-desc-line1">
                                                {userData.Account_Description}
                                            </p>
                                        </div>
                                        <div className="profile-info-layout">
                                            <span className="followers-count-layout">
                                                <p className="data-count-text">
                                                    {userData.Following_count}
                                                </p>
                                            </span>
                                            <span className="following-count-layout">
                                                <p className="data-count-text">
                                                    {userData.Following_count}
                                                </p>
                                            </span>
                                            <span className="posts-count-layout">
                                                <p className="data-count-text">{userData.Post_count}</p>
                                            </span>
                                            <span className="likes-count-layout">
                                                <p className="data-count-text">
                                                    {userData.Post_likes_count}
                                                </p>
                                            </span>
                                            <span className="followers-head-layout">
                                                <p className="data-head-text">
                                                    {t('profilescreen.followers-heading')}
                                                </p>
                                            </span>
                                            <span className="following-head-layout">
                                                <p className="data-head-text">
                                                    {t('profilescreen.following-heading')}
                                                </p>
                                            </span>
                                            <span className="posts-head-layout">
                                                <p className="data-head-text">
                                                    {t('profilescreen.posts-heading')}
                                                </p>
                                            </span>
                                            <span className="likes-head-layout">
                                                <p className="data-head-text">
                                                    {t('profilescreen.likes-heading')}
                                                </p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="Buttons-layout">
                                    <Button
                                        className={`Posts-button ${
                                            contentType === 'posts' ? 'activeBtn' : 'inactive'
                                        }`}
                                        variant="contained"
                                        onClick={() => handleContentTypeChange('posts')}
                                    >
                                        {t('profilescreen.posts-button')}
                                    </Button>
                                    <Button
                                        className={`Reels-button ${
                                            contentType === 'reels' ? 'activeBtn' : 'inactive'
                                        }`}
                                        variant="contained"
                                        onClick={() => handleContentTypeChange('reels')}
                                    >
                                        {t('profilescreen.reels-button')}
                                    </Button>
                                    <Button
                                        className={`saved-button ${
                                            contentType === 'saved' ? 'activeBtn' : 'inactive'
                                        }`}
                                        variant="contained"
                                        onClick={() => handleContentTypeChange('saved')}
                                    >
                                        {t('profilescreen.saved-button')}
                                    </Button>
                                </div>
                            </div>
                            <div className="Bottompage-container">
                                <div className="posts-container">
                                    {contentType === 'posts' &&
                    (images.length > 0 ? (
                        images.map((post) => (
                            <div
                                className={`posts ${
                                    images === post.Post_id ? 'selected' : ''
                                }`}
                                key={post.Post_id}
                                onClick={() => {
                                    // handlePostClick(images);
                                    handleOpenPostModal();
                                    handleSelectedImageIndex(post.Post_id);
                                }}
                                onMouseEnter={() => handleMouseEnter(post.Post_id)}
                                onMouseLeave={() => handleMouseLeave(post.Post_id)}
                            >
                                <img
                                    src={post.Avatar_key}
                                    className="posts-imgs"
                                    alt={`post-${post.Post_id}`}
                                />
                                {showDetails === post.Post_id && (
                                    <div className="likes-and-images-in-post">
                                        <span className="span-like-text-post">
                                            <FcLike className="like-svg-post" />
                                12
                                        </span>
                                        <span className="span-comment-text-post">
                                            <FaComment className="comment-svg-post" />
                                120
                                        </span>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="no-reels-and-images-message">
                        No Images Available.
                        </div>
                    ))}

                                    {openPostModal && (
                                        <PostModal
                                            open={openPostModal}
                                            handleClose={handleClosePostModal}
                                            posts={Posts}
                                            handleDeletePost={handleDeletePost}
                                            selectedImageIndex={selectedImageIndex}
                                        />
                                    )}
                                </div>
                                <div className="reels-container">
                                    {contentType === 'reels' &&
                    (videos.length > 0 ? (
                        videos.map((reel) => (
                            <div
                                className={`reels ${
                                    videos === reel.Post_id ? 'selected' : ''
                                }`}
                                key={reel.Post_id}
                                onClick={() => {
                                    // handleReelClick(videos);
                                    handleOpenReelModal();
                                }}
                                onMouseEnter={() => handleMouseEnter(reel.Post_id)}
                                onMouseLeave={() => handleMouseLeave(reel.Post_id)}
                            >
                                <video className="reels-videos">
                                    <source src={reel.Avatar_key} type="video/mp4" />
                                    {t('profilescreen.reels-videos-not-supporting')}
                                </video>
                                {showDetails === reel.Post_id && (
                                    <div className="likes-and-images-in-post">
                                        <span className="span-like-text-post">
                                            <FcLike className="like-svg-post" />
                                20
                                        </span>
                                        <span className="span-comment-text-post">
                                            <FaComment className="comment-svg-post" />
                                300
                                        </span>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="no-reels-and-images-message">
                        No reels available.
                        </div>
                    ))}

                                    {openReelModal && (
                                        <ReelsModal
                                            open={openReelModal}
                                            handleClose={handleCloseReelModal}
                                            Reels={Reels}
                                        />
                                    )}
                                </div>
                                <div className="saved-container">
                                    {contentType === 'saved' &&
                    saved.map((item) => (
                        <div
                            className={`saved ${
                                Saved === item.id ? 'selected' : ''
                            }`}
                            key={item.id}
                            onClick={() => {
                                handleSavedClick(saved);
                                handleOpenSavedModal();
                            }}
                        >
                            {item.image && (
                                <img
                                    src={item.image}
                                    className="saved-content"
                                    alt={`saved-${item.id}`}
                                />
                            )}
                            {item.video && (
                                <video className="saved-content">
                                    <source src={item.video} type="video/mp4" />
                                </video>
                            )}
                        </div>
                    ))}

                                    {openSavedModal && (
                                        <SavedModal
                                            open={openSavedModal}
                                            handleClose={handleCloseSavedModal}
                                            Saved={Saved}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="profile-right-side-bar">
                        <RightSideBar></RightSideBar>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profilescreen;
