// NotFound.js
import React,{useEffect} from 'react';

const PageNotFound = () => {

    useEffect(() => {
        // Change the page title
        document.title = 'Page Not Found';

    }, []);
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
        </div>
    );
};

export default PageNotFound;


