import React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import ReelDetail from './ReelDetail';
import './PostModal.css';

function PostModal({ handleClose, open, Reels }) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="post-modal">
                    <ReelDetail Reels={Reels} handleClose={handleClose} />
                </Box>
            </Modal>
        </div>
    );
}

export default PostModal;
