// import { useState } from 'react';
// import { Button } from '@mui/material';
// import '../../VendorStyles/AddMenuType.css';
// import { toast } from 'react-toastify';

// function AddMenuType({ onClose, onAddMenuType }) {
//     const [name, setName] = useState('');
//     const [description, setDescription] = useState('');

//     const handleAdd = () => {
//         if (!name) {
//             toast.error('Please fill the name');
//             return;
//         }

//         onAddMenuType({ name, description });
//         onClose();
//         toast.success('Menu added successfully');
//     };

//     return (
//         <div className='menu-add-menu-popup-container'>
//             <svg
//                 width="26"
//                 height="26"
//                 viewBox="0 0 36 36"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="menu-add-menu-popup-cross-icon"
//                 onClick={onClose}
//             >
//                 <path
//                     d="M21.2981 18.0003L35.3158 3.98187C36.2281 3.07003 36.2281 1.59572 35.3158 0.683879C34.404 -0.22796 32.9297 -0.22796 32.0179 0.683879L17.9998 14.7023L3.98211 0.683879C3.06986 -0.22796 1.596 -0.22796 0.684185 0.683879C-0.228062 1.59572 -0.228062 3.07003 0.684185 3.98187L14.7019 18.0003L0.684185 32.0187C-0.228062 32.9305 -0.228062 34.4048 0.684185 35.3167C1.1386 35.7715 1.73609 36 2.33315 36C2.93021 36 3.52727 35.7715 3.98211 35.3167L17.9998 21.2983L32.0179 35.3167C32.4727 35.7715 33.0698 36 33.6669 36C34.2639 36 34.861 35.7715 35.3158 35.3167C36.2281 34.4048 36.2281 32.9305 35.3158 32.0187L21.2981 18.0003Z"
//                     fill="white"
//                 />
//             </svg>
//             <div className="add-menu-popup">
//                 <p className='add-menu-field-txt'>Name</p>
//                 <input 
//                     value={name} 
//                     onChange={(e) => setName(e.target.value)} 
//                 />
//                 <p className='add-menu-field-txt'>Description</p>
//                 <textarea 
//                     value={description} 
//                     onChange={(e) => setDescription(e.target.value)} 
//                 />
//                 <Button 
//                     className="add-menu-popup-next-btn" 
//                     onClick={handleAdd}
//                 >
//                     Add
//                 </Button>
//             </div>
//             <div className="blur-background"></div>
//         </div>
//     );
// }

// export default AddMenuType;


import { useState } from 'react';
import { Button } from '@mui/material';
import '../../VendorStyles/AddMenuType.css';
import { toast } from 'react-toastify';

function AddMenuType({ onClose, onAddMenuType }) {
    // const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [newMenuType, setNewMenuType] = useState('');

    const handleAdd = () => {
        if (!newMenuType) {
            toast.error('Please fill in the name');
            return;
        }

        onAddMenuType({ newMenuType });
        onClose();
        toast.success('Menu added successfully');
        // {console.log(newMenuType);}

    };

    return (
        <div className='menu-add-menu-popup-container'>
            <svg
                width="26"
                height="26"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="menu-add-menu-popup-cross-icon"
                onClick={onClose}
            >
                <path
                    d="M21.2981 18.0003L35.3158 3.98187C36.2281 3.07003 36.2281 1.59572 35.3158 0.683879C34.404 -0.22796 32.9297 -0.22796 32.0179 0.683879L17.9998 14.7023L3.98211 0.683879C3.06986 -0.22796 1.596 -0.22796 0.684185 0.683879C-0.228062 1.59572 -0.228062 3.07003 0.684185 3.98187L14.7019 18.0003L0.684185 32.0187C-0.228062 32.9305 -0.228062 34.4048 0.684185 35.3167C1.1386 35.7715 1.73609 36 2.33315 36C2.93021 36 3.52727 35.7715 3.98211 35.3167L17.9998 21.2983L32.0179 35.3167C32.4727 35.7715 33.0698 36 33.6669 36C34.2639 36 34.861 35.7715 35.3158 35.3167C36.2281 34.4048 36.2281 32.9305 35.3158 32.0187L21.2981 18.0003Z"
                    fill="white"
                />
            </svg>
            <div className="add-menu-popup">
                <p className='add-menu-field-txt'>Name</p>
                <input 
                    value={newMenuType} 
                    onChange={(e) => setNewMenuType(e.target.value)} 
                />
                <p className='add-menu-field-txt'>Description</p>
                <textarea 
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)} 
                />
                <Button 
                    className="add-menu-popup-next-btn" 
                    onClick={handleAdd}
                >
                    Add
                </Button>
            </div>
            <div className="blur-background"></div>
        </div>
    );
}

export default AddMenuType;
