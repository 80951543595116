import React, { Suspense} from 'react';
import './App.css';
import MainNavigation from './MainNavigation';
// import { ActionTypes } from './Redux/Contants/action-types';
// import { useDispatch } from 'react-redux';
// import axios from 'axios';
// import getLocation from './GetLocation';

function App() {




    // const  base_URL = 'http://localhost:5002/api';

    // const dispatch = useDispatch();

    // const [data,setData] = useState([]);
    // console.log(data);


    // useEffect(()=>{

        

    //     fetchDataFromCart();

    // },[]);


    // const fetchDataFromCart = async()=>{

    //     // Fetch item data from the API using the itemID
    //     const itemResponse = await axios.get(`${base_URL}/cartdetails/1/1`);
    //     console.log(itemResponse.data.cartDetails.result[0].Cart_Information);
    //     const data = itemResponse.data.cartDetails.result[0].Cart_Information;
    //     // setData(data);
  
    //     dispatch({
    //         type: ActionTypes.ADD_TO_CART,
    //         payload: data,
    //     });

    // };



   

    
    // const [country, setCountry] = useState('');
    // const [loading, setLoading] = useState(true);
    // const [testingCountry, setTestingCountry] = useState('');

    // useEffect(() => {
    //     const fetchLocation = async () => {
    //         const data = await getLocation();
    //         if (data) {
    //             setCountry(data.country);
    //             console.log(data.country ,'country');
    //         }
    //         setLoading(false);
    //     };

    //     fetchLocation();
    // }, []);

    // const handleCountryChange = (event) => {
    //     setTestingCountry(event.target.value);
    // };

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    // const displayCountry = testingCountry || country;
    // const displayCountry =  country;

    // console.log('Display country:', displayCountry);
    return (
    // <div>

    //     {country === 'IN' ? (

    //     ) : (
    //         <div style={{textAlign:'center',marginTop:'50px',fontSize:'20px',fontWeight:'800'}}>Coming soon...</div>
    //     )}
    // </div>

        <div className="App">
            <Suspense
                fallback={
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                }
            >
                <MainNavigation></MainNavigation>
            </Suspense>
        </div>
    );
}

export default App;
