import React, { useRef, useState, useEffect } from 'react';
import './FollowToWatchReels.css';
import { useTranslation } from 'react-i18next';
import img1 from '../../../assets/FollowImage1.png';

import axios from 'axios';

function FollowToWatchReels() {
    const base_URL = process.env.REACT_APP_API_BASE_URL;
    // const base_URL = 'http://localhost:5002/api';

    const { t } = useTranslation();
    const containerRef = useRef(null);

    const [Followers, setFollowers] = useState([]);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true); // Initially show right arrow

    const [isFollowing, setIsFollowing] = useState(false);

    useEffect(() => {
        const fetchFollowToWatch = async () => {
            const response = await axios.get(
                `${base_URL}/follow/watch-reels?pageNo=1&pageSize=10`
            );
            setFollowers(response.data.followInfo.result[0].Follow_Information);
        };

        fetchFollowToWatch();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            // Check if there's content to scroll to the left
            setShowLeftArrow(containerRef.current.scrollLeft > 0);
            // Check if there's content to scroll to the right
            setShowRightArrow(
                containerRef.current.scrollWidth >
          containerRef.current.clientWidth + containerRef.current.scrollLeft
            );
        };

        // Attach scroll event listener
        containerRef.current.addEventListener('scroll', handleScroll);

        // Call handleScroll once to set initial arrow visibility
        handleScroll();

    // Clean up the event listener
    // return () => containerRef.current.removeEventListener('scroll', handleScroll);
    }, []);

    const handleFollow = () => {
        setIsFollowing(!isFollowing);
    };
    // const handleFollow=()=>{

    //     setIsFollowing(!isFollowing);

    // };

    // const handleFollow= async(id)=>{

    //     console.log(id);

    //     const Data = {
    //         Follow_by_id: id,
    //         Created_by: Date.now()
    //     };

    //     // let followResponse;
    //     if (isFollowing) {

    //         // Follow logic
    //         await axios.post(`${base_URL}/follow/account/${id}`, Data);
    //         // console.log('Followed:', followResponse);
    //         setIsFollowing(true);

    //     } else {
    //         // Unfollow logic
    //         await axios.delete(`${base_URL}/follow/account/${id}/${id}`);
    //         // console.log('Unfollowed:', followResponse);
    //         setIsFollowing(false);

    //     }

    // };

    const handleScrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= 90;
            setShowRightArrow(true); // Show right arrow when scrolling left
        }
    };

    const handleScrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += 90;
            setShowLeftArrow(true); // Show left arrow when scrolling right
        }
    };
    return (
        <div className="Follow_To_Watch_Reels_Container">
            <div className="title-and-arrows">
                <h5 className="follow_To_Watch_Reels">
                    {t('homescreen.Follow-to-watch-reels-title')}
                </h5>
                <div className="arrows">
                    {showLeftArrow && (
                        <svg
                            width="100"
                            height="100"
                            viewBox="0 0 100 100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            alt="left arrow"
                            className="left-arrow-homepage"
                            onClick={handleScrollLeft}
                        >
                            <g clipPath="url(#clip0_5807_2713)">
                                <rect width="100" height="100" fill="white" />
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="50"
                                    fill="#E4EDF9"
                                    fillOpacity="0.5"
                                />
                                <path
                                    d="M60.9942 26L38 49.5L61 73"
                                    stroke="#464B59"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_5807_2713">
                                    <rect width="100" height="100" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}

                    {showRightArrow && (
                        <svg
                            width="100"
                            height="100"
                            viewBox="0 0 100 100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            alt="right arrow"
                            className="right-arrow-homepage"
                            onClick={handleScrollRight}
                        >
                            <g clipPath="url(#clip0_5807_2715)">
                                <rect width="100" height="100" fill="white" />
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="50"
                                    fill="#E4EDF9"
                                    fillOpacity="0.5"
                                />
                                <path
                                    d="M39.0058 73L62 49.5L39 26"
                                    stroke="#464B59"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_5807_2715">
                                    <rect width="100" height="100" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                </div>
            </div>

            <div className="person-cards-container" ref={containerRef}>
                {Followers.map((profile, index) => (
                    <div key={index} className="person-card">
                        <img className="person_image" src={img1} alt="person img" />
                        <h6 className="channel-name">{profile.Display_name}</h6>
                        <p className="followers-count">{profile.Follower_count}</p>
                        <div className="botton-text-container">
                            <button className="Follow_Button" onClick={handleFollow}>
                                {isFollowing ? 'Following' : 'Follow'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FollowToWatchReels;
