import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import hideIcon from '../../../assets/eyeicon.png';
import showIcon from '../../../assets/showicon.png'; // Add show icon image
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Profilesettings.css';

function ChangePassword() {
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const { t } = useTranslation();

    const [previousPassword, setPreviousPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [previousPasswordError, setPreviousPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const togglePasswordVisibility2 = () => {
        setShowPassword2(!showPassword2);
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = true;

        if (!previousPassword) {
            setPreviousPasswordError(t('please enter previous password.'));
            isValid = false;
        } else {
            setPreviousPasswordError('');
        }

        if (!newPassword) {
            setNewPasswordError(t('please enter a new password.'));
            isValid = false;
        } else if (previousPassword === newPassword) {
            setNewPasswordError('Please choose a different password.');
            isValid = false;
        } else if (!validatePassword(newPassword)) {
            setNewPasswordError('Password must be at least 8 characters long and contain 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character (!@#$%^&*).');
            isValid = false;
        } else {
            setNewPasswordError('');
        }

        if (isValid) {
            // Proceed with password update logic here
            // console.log('Previous Password:', previousPassword);
            // console.log('New Password:', newPassword);
            // Example: axios.put('/api/update-password', { previousPassword, newPassword })
        }
    };

    return (
        <div className='editprofile-top-main'>
            <div className='sidebar'>
                <SideMenuBar />
            </div>
            <div className='top-bar'>
                <TopMenuBar />
            </div>

            <div className='update-password-cont'>
                <div className='update-pass-top'>
                    <Link to='/profilesettings'>
                        <div className='ico1'>
                            <svg width="22" height="25" viewBox="0 0 42 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38.9909 3L3 39L39 75" stroke="#464B59" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </Link>
                    <div className='Update-Password-txt-layout'>
                        <h5 className='Update-Password-txt'>{t('changepassword.update-password-title')}</h5>
                    </div>
                </div>
                <div className="ChangePassword-main">
                    <form onSubmit={handleSubmit}>
                        <div className='password-container'>
                            <p className='Previous-password-text'>{t('changepassword.previous-password-title')}</p>
                            <TextField
                                type={showPassword1 ? 'text' : 'password'}
                                placeholder={t('changepassword.previous-password-placeholder')}
                                value={previousPassword}
                                onChange={(e) => setPreviousPassword(e.target.value)}
                                error={!!previousPasswordError}
                                helperText={previousPasswordError}
                                required
                                className='custom-input'
                                autoComplete="current-password" // Added autocomplete attribute
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility1}
                                                edge="end"
                                            >
                                                <img
                                                    src={showPassword1 ? showIcon : hideIcon}
                                                    alt="Toggle Password Visibility"
                                                    height='19px'
                                                    width='24px'
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <u className='forgot-password-text'>{t('changepassword.forgot-password-title')}</u>
                        <div className='password-container'>
                            <p className='profile-head-text'>{t('changepassword.new-password-title')}</p>
                            <TextField
                                type={showPassword2 ? 'text' : 'password'}
                                placeholder={t('changepassword.new-password-placeholder')}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                error={!!newPasswordError}
                                helperText={newPasswordError}
                                required
                                className='custom-input'
                                autoComplete="new-password" // Added autocomplete attribute
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={togglePasswordVisibility2}
                                                edge="end"
                                            >
                                                <img
                                                    src={showPassword2 ? showIcon : hideIcon}
                                                    alt="Toggle Password Visibility"
                                                    height='19px'
                                                    width='24px'
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className='save-changes-layout'>
                            <Button
                                variant="contained"
                                type='submit'
                                style={{ backgroundColor: 'var(--color4)', color: '#FFFFFF', fontFamily: 'Poppins', marginTop: '30px' }}
                                className='save-changes-button'
                            >
                                {t('changepassword.verify-and-update-button')}
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
export default ChangePassword;