import React, { useState , useRef,useEffect} from 'react';
import axios from 'axios';
import './../../../Styles/Reels/Comment.css';
import profileIcon from './../../../assets/profile image.png';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { toast } from 'react-toastify';

function Comment({
    handleClose,
    id,
    commentsList,
    setCommentsList,
    getComments,
}) {
    const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;
    // const vendor_Base_URL = 'http://localhost:5003/api';

    const [comment, setComment] = useState('');
    // const [commentsList, setCommentsList] = useState([]);
    // console.log(commentsList.ID);
    const [editIndex, setEditIndex] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const { t } = useTranslation();

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSelectedCommentId(id);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const commentsEndRef = useRef(null); // Reference for scrolling

    const handleEdit = () => {
        const commentToEdit = commentsList.find(
            (comment) => comment.ID === selectedCommentId
        );
        if (commentToEdit) {
            setComment(commentToEdit.Comment);
            setEditIndex(selectedCommentId);
        }
        handleMenuClose();
    };

    const handleDelete = async () => {
        try {
            await axios.delete(
                `${vendor_Base_URL}/comments/account/1/post/${id}/comment/${selectedCommentId}`
            );
            setCommentsList(
                commentsList.filter((comment) => comment.ID !== selectedCommentId)
            );
            toast.success('Comment deleted successfully');

            getComments();
        } catch (error) {
            console.error('There was an error deleting the comment!', error);
            toast.error('Error deleting comment');
        }
        handleMenuClose();
    };

    const handleSubmit = async () => {
        if (comment === '') {
            alert('Please enter a comment');
            return;
        }

        try {
            if (editIndex !== null) {
                // Update existing comment

                const updatedComment = {
                    Comment: comment,
                    Updated_at: new Date().toISOString(),
                    Updated_by: 789,
                };
                await axios.put(
                    `${vendor_Base_URL}/comments/account/1/post/1/${editIndex}`,
                    updatedComment
                );
                setCommentsList(
                    commentsList.map((c) =>
                        c.ID === editIndex ? { ...c, Comment: comment } : c
                    )
                );
                toast.success('Comment updated successfully');
                setEditIndex(null);
            } else {
                // Add new comment
                const newComment = {
                    Account_id: '1',
                    Comment: comment,
                    Created_at: new Date().toISOString(),
                    Created_by: 456,
                    Updated_at: new Date().toISOString(),
                    Updated_by: 789,
                };
                const response = await axios.post(
                    `${vendor_Base_URL}/comments/account/1/post/${id}`,
                    newComment
                );
                // console.log(response.data);
                setCommentsList([...commentsList, response.data]);

                // setCommentsCount((prev)=> prev + 1);
                getComments();

                toast.success('Comment added successfully');
            }
            setComment('');
        } catch (error) {
            console.error('There was an error saving the comment!', error);
        }
    };


    // Scroll to the latest comment
    useEffect(() => {
        if (commentsEndRef.current) {
            commentsEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [commentsList]);

    return (
        <div className="comments-main-container">
            <div className="hide-horizantal-line">
                <div className="horizantal-line-mobile"></div>
            </div>

            <div className="comment-and-crossIcon-container">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t('reels.comments-title')}
                </Typography>

                <svg
                    width="25"
                    height="18"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cross-icon"
                    onClick={handleClose}
                >
                    <path
                        d="M14.7904 12.5002L24.5249 2.76519C25.1584 2.13197 25.1584 1.10814 24.5249 0.474916C23.8917 -0.158305 22.8679 -0.158305 22.2346 0.474916L12.4999 10.2099L2.76535 0.474916C2.13185 -0.158305 1.10834 -0.158305 0.475128 0.474916C-0.158376 1.10814 -0.158376 2.13197 0.475128 2.76519L10.2096 12.5002L0.475128 22.2352C-0.158376 22.8684 -0.158376 23.8922 0.475128 24.5255C0.790694 24.8413 1.20562 25 1.62024 25C2.03487 25 2.44949 24.8413 2.76535 24.5255L12.4999 14.7905L22.2346 24.5255C22.5505 24.8413 22.9651 25 23.3798 25C23.7944 25 24.209 24.8413 24.5249 24.5255C25.1584 23.8922 25.1584 22.8684 24.5249 22.2352L14.7904 12.5002Z"
                        fill="white"
                    />
                </svg>
            </div>

            <div className="comment-input-container">
                <img className="comment-profile-icon" src={profileIcon} alt="profile" />
                <input
                    className="comment-input"
                    type="text"
                    placeholder="Add a Comment..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                <Button
                    variant="contained"
                    className="comment-post-button"
                    onClick={handleSubmit}
                >
                    {editIndex !== null ? 'Update' : 'Post'}
                </Button>
            </div>
            <div className="user-comments-list">
                {commentsList.map((commentItem) => (
                    <div key={commentItem.ID} className="user-comments-container">
                        <div className="user-profile-and-name">
                            <Link to="/profilescreen">
                                <img
                                    className="user-comment-profile-pic"
                                    src={profileIcon}
                                    alt="profile pic"
                                />
                            </Link>
                            <div className="user-name-verified">
                                <h5>{t('reels.username')}</h5>
                                {/* <MdVerified size={15} style={{ color: 'blue' }} /> */}
                            </div>
                            {/* <p className='user-comment-time'>{t('reels.comment-time')}</p> */}
                            <p className="user-comment-time">Friday, 2:20pm</p>
                            <MoreVertIcon
                                onClick={(e) => handleMenuClick(e, commentItem.ID)}
                                fontSize="1.2rem"
                            />
                            <Menu
                                id="basic-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                sx={{
                                    '.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
                                        boxShadow: 'none',
                                    },
                                    '& .MuiPaper-root': {
                                        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                    },
                                }}
                            >
                                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                                <MenuItem onClick={handleDelete}>Delete</MenuItem>
                            </Menu>
                        </div>
                        <div className="user-comment">
                            <p className="user-comment-text">{commentItem.Comment}</p>
                            <div className="comment-reply-like">
                                <button className="user-comment-reply">
                                    {t('reels.reply-btn')}
                                </button>
                                <svg
                                    width="19"
                                    height="15"
                                    viewBox="0 0 19 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="comment-like-svg"
                                >
                                    <path
                                        d="M9.57097 15.9746L9.57382 15.9721L9.57383 15.9721C10.3108 15.3242 11.0252 14.7123 11.6539 14.1736L11.7465 14.0944C13.6824 12.4326 15.2622 11.0759 16.3447 9.76323L9.57097 15.9746ZM9.57097 15.9746C9.55414 15.9896 9.53032 16 9.5 16C9.46969 16 9.44586 15.9896 9.42903 15.9746L2.65526 9.76323C1.68609 8.79803 1.02097 7.63456 0.658583 6.46398L9.57097 15.9746Z"
                                        fill="black"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                ))}

                <div ref={commentsEndRef} /> {/* Invisible div to scroll to */}
            </div>
        </div>
    );
}

export default Comment;
