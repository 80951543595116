import React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import Comment from './Comment';

function Comments({
    handleClose,
    open,
    id,
    commentsList,
    setCommentsList,
    getComments,
}) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="style">
                    <Comment
                        handleClose={handleClose}
                        id={id}
                        commentsList={commentsList}
                        setCommentsList={setCommentsList}
                        getComments={getComments}
                    />
                </Box>
            </Modal>
        </div>
    );
}

export default Comments;
