import React, { useRef, useState, useEffect } from 'react';
import './PreviousOrders.css';
import { useTranslation } from 'react-i18next';
import img1 from '../../../assets/pancurry.png';
import axios from 'axios';

import './FollowToWatchReels.css';

function PrevOrders() {
    const base_URL = process.env.REACT_APP_API_BASE_URL;
    // const base_URL = 'http://localhost:5002/api';

    const { t } = useTranslation();
    const containerRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);

    const [previousOrders, setPreviousOrders] = useState([]);

    const fetchFollowToWatch = async () => {
        const response = await axios.get(
            `${base_URL}/orders/previous-orders-with-vendor?pageNo=1&pageSize=10&customerId=1`
        );
        setPreviousOrders(response.data.orders);
    };

    useEffect(() => {
        fetchFollowToWatch();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            // Check if there's content to scroll to the left
            setShowLeftArrow(containerRef.current.scrollLeft > 0);
            // Check if there's content to scroll to the right
            setShowRightArrow(
                containerRef.current.scrollWidth >
          containerRef.current.clientWidth + containerRef.current.scrollLeft
            );
        };

        // Attach scroll event listener
        containerRef.current.addEventListener('scroll', handleScroll);

        // Call handleScroll once to set initial arrow visibility
        handleScroll();

    // Clean up the event listener
    // return () => containerRef.current.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft -= 90;
        }
    };

    const handleScrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollLeft += 90;
        }
    };

    return (
        <div className="Prev-orders-Main-container">
            <div className="title-and-arrows">
                <h5 className="previous-orders-title-homepage">
                    {t('homescreen.previous-orders-title')}
                </h5>
                <div className="arrows">
                    {showLeftArrow && (
                        <svg
                            width="100"
                            height="100"
                            viewBox="0 0 100 100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            alt="left arrow"
                            className="left-arrow-homepage"
                            onClick={handleScrollLeft}
                        >
                            <g clipPath="url(#clip0_5807_2713)">
                                <rect width="100" height="100" fill="white" />
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="50"
                                    fill="#E4EDF9"
                                    fillOpacity="0.5"
                                />
                                <path
                                    d="M60.9942 26L38 49.5L61 73"
                                    stroke="#464B59"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_5807_2713">
                                    <rect width="100" height="100" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}

                    {showRightArrow && (
                        <svg
                            width="100"
                            height="100"
                            viewBox="0 0 100 100"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            alt="right arrow"
                            className="right-arrow-homepage"
                            onClick={handleScrollRight}
                        >
                            <g clipPath="url(#clip0_5807_2715)">
                                <rect width="100" height="100" fill="white" />
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="50"
                                    fill="#E4EDF9"
                                    fillOpacity="0.5"
                                />
                                <path
                                    d="M39.0058 73L62 49.5L39 26"
                                    stroke="#464B59"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_5807_2715">
                                    <rect width="100" height="100" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                </div>
            </div>

            <div className="order-cards-container" ref={containerRef}>
                {previousOrders.map((item, index) => (
                    <div key={index} className="order-card">
                        <img className="item-image" src={img1} alt="item img"></img>
                        <h6 className="items-name">{item.VendorName}</h6>
                        <div className="Botton-text-container">
                            <button className="Order-Button">
                                <p className="Button-text">
                                    {t('homescreen.order-again-button')}
                                </p>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default PrevOrders;
