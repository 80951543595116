import React, { useState } from 'react';
import '../../VendorStyles/DashboardRightbar.css';
import BusinessHours from './BusinessHours';
import Customrange from './CustomRange';
function DashboardRightbar() {
    const [activeButton, setActiveButton] = useState(null);

    const buttons = [
        'Monthly',
        'Weekly',
        'Custom Range',
        'Yearly',
        'Today',
        'Business hours'
    ];

    const handleButtonClick = (button) => {
        setActiveButton(button);
    };

     

    const businessHoursContent = (
        <div className="dashboard-container2">
            <BusinessHours></BusinessHours>
        </div>
    );
    const CustomRange = (
        <Customrange></Customrange>
    );
    return (
        <><div className="dashboard-vertical-line"></div><div className='dashboard-rightbar-container'>
            {buttons.map((button, index) => (
                <button
                    key={index}
                    className={`dashboard-rightbar-btn ${activeButton === button ? 'activebutton' : ''}`}
                    onClick={() => handleButtonClick(button)}
                >
                    {button}
                </button>
            ))}
            {activeButton && (
                <div className='dashboard-rightbar-content'>
                    {activeButton === 'Business hours' ? businessHoursContent : activeButton === 'Custom Range' ? CustomRange : `Content for ${activeButton}`}

                </div>
            )}
        </div></>
    );
}

export default DashboardRightbar;
