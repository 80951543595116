import React from 'react';

import { useSelector } from 'react-redux';
import Vendorrouter from './Vendorrouter';
import Userrouter from './Userrouter';
import Deliveryrouter from './Deliveryrouter';



function MainNavigation() {
    
     
    

    const selectedRole = useSelector((state) =>{

        return state.ItemReducer.userRole; 
    });
    // console.log(selectedRole);

    const role = selectedRole;

    return (
        <div>

            

         


            {/* {
                role === 'restaurantOwner' ? (
                     
                    <Vendorrouter></Vendorrouter>
                        
                ):(
                    <Userrouter></Userrouter>
                )
            } */}


            {
                role === 'restaurantOwner' ? (
                    <Vendorrouter />
                ) : role === 'deliverypartner' ? (
                    <Deliveryrouter/>
                ) : (
                    <Userrouter/>
                )
            }  

           
          
        </div>
    );
}

export default MainNavigation;
