import React from 'react';
import './../../../Styles/RestaurantPage/ImageCarousel.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function ImageCarousel() {
    const images = [
        {
            image:
        'https://img.freepik.com/premium-photo/hyderabadi-chicken-biryani-food-photos_410516-42775.jpg',
        },
        {
            image: 'https://miro.medium.com/v2/resize:fit:560/0*EAxPMcdiSupoNmEA.jpg',
        },
        {
            image: 'https://img.lovepik.com/photo/48013/0739.jpg_wh860.jpg',
        },
    ];

    return (
        <div className="react-multi-carousel-container">
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                removeArrowOnDeviceType={['mobile']}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024,
                        },
                        items: 1,
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0,
                        },
                        items: 1,
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464,
                        },
                        items: 1,
                    },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {images.map((image, index) => {
                    return (
                        <div key={index}>
                            <img
                                style={{
                                    display: 'block',
                                    height: '250px',
                                    margin: 'auto',
                                    width: '500px',
                                    objectFit: 'cover',
                                    borderRadius: '8px',
                                }}
                                src={image.image}
                                alt={image[index]}
                            />
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
}

export default ImageCarousel;
