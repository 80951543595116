import React, { useState } from 'react';
import '../../../Styles/Cart/DeliveryInstruction.css';
import { useTranslation } from 'react-i18next';
function DeliveryInstruction({ handleClose }) {
    const [selectedInstructions, setSelectedInstructions] = useState([]);
    const { t } = useTranslation();

    const handleInstructionClick = (instruction) => {
        if (selectedInstructions.includes(instruction)) {
            setSelectedInstructions(
                selectedInstructions.filter((item) => item !== instruction)
            );
        } else {
            setSelectedInstructions([...selectedInstructions, instruction]);
        }
    };
    return (
        <>
            <div className="cross-svg-cook-inst">
                <svg
                    width="23"
                    height="18"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cross-icon"
                    onClick={handleClose}
                >
                    <path
                        d="M14.7904 12.5002L24.5249 2.76519C25.1584 2.13197 25.1584 1.10814 24.5249 0.474916C23.8917 -0.158305 22.8679 -0.158305 22.2346 0.474916L12.4999 10.2099L2.76535 0.474916C2.13185 -0.158305 1.10834 -0.158305 0.475128 0.474916C-0.158376 1.10814 -0.158376 2.13197 0.475128 2.76519L10.2096 12.5002L0.475128 22.2352C-0.158376 22.8684 -0.158376 23.8922 0.475128 24.5255C0.790694 24.8413 1.20562 25 1.62024 25C2.03487 25 2.44949 24.8413 2.76535 24.5255L12.4999 14.7905L22.2346 24.5255C22.5505 24.8413 22.9651 25 23.3798 25C23.7944 25 24.209 24.8413 24.5249 24.5255C25.1584 23.8922 25.1584 22.8684 24.5249 22.2352L14.7904 12.5002Z"
                        fill="white"
                    />
                </svg>
            </div>
            <div className="cook-inst-main-container">
                <h1 className="title-cooking-inst">
                    {t('cart.add-delivery-nstructions')}
                </h1>

                <div
                    className="instruction-container"
                    onClick={() => handleInstructionClick('Avoid Ringing Bell')}
                    style={{
                        border: selectedInstructions.includes('Avoid Ringing Bell')
                            ? '2px solid #02A750'
                            : '2px solid #E2E8F0',
                    }}
                >
                    <p className="instructions-text">{t('cart.avoid-ringing-bell')}</p>
                    {selectedInstructions.includes('Avoid Ringing Bell') && (
                        <svg
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.32453 4.50007L8.82895 0.995468C9.05701 0.767508 9.05701 0.398929 8.82895 0.17097C8.601 -0.0569899 8.23243 -0.0569899 8.00447 0.17097L4.49995 3.67557L0.995527 0.17097C0.767466 -0.0569899 0.399001 -0.0569899 0.171046 0.17097C-0.0570154 0.398929 -0.0570154 0.767508 0.171046 0.995468L3.67547 4.50007L0.171046 8.00467C-0.0570154 8.23263 -0.0570154 8.6012 0.171046 8.82916C0.28465 8.94288 0.434022 9 0.583287 9C0.732552 9 0.881817 8.94288 0.995527 8.82916L4.49995 5.32456L8.00447 8.82916C8.11818 8.94288 8.26745 9 8.41671 9C8.56598 9 8.71524 8.94288 8.82895 8.82916C9.05701 8.6012 9.05701 8.23263 8.82895 8.00467L5.32453 4.50007Z"
                                fill="#02A750"
                            />
                        </svg>
                    )}
                </div>

                <div
                    className="instruction-container"
                    onClick={() => handleInstructionClick('Leave at the Gate')}
                    style={{
                        border: selectedInstructions.includes('Leave at the Gate')
                            ? '2px solid #02A750'
                            : '2px solid #E2E8F0',
                    }}
                >
                    <p className="instructions-text">{t('cart.leave-at-the-gate')}</p>
                    {selectedInstructions.includes('Leave at the Gate') && (
                        <svg
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.32453 4.50007L8.82895 0.995468C9.05701 0.767508 9.05701 0.398929 8.82895 0.17097C8.601 -0.0569899 8.23243 -0.0569899 8.00447 0.17097L4.49995 3.67557L0.995527 0.17097C0.767466 -0.0569899 0.399001 -0.0569899 0.171046 0.17097C-0.0570154 0.398929 -0.0570154 0.767508 0.171046 0.995468L3.67547 4.50007L0.171046 8.00467C-0.0570154 8.23263 -0.0570154 8.6012 0.171046 8.82916C0.28465 8.94288 0.434022 9 0.583287 9C0.732552 9 0.881817 8.94288 0.995527 8.82916L4.49995 5.32456L8.00447 8.82916C8.11818 8.94288 8.26745 9 8.41671 9C8.56598 9 8.71524 8.94288 8.82895 8.82916C9.05701 8.6012 9.05701 8.23263 8.82895 8.00467L5.32453 4.50007Z"
                                fill="#02A750"
                            />
                        </svg>
                    )}
                </div>

                <div
                    className="instruction-container"
                    onClick={() => handleInstructionClick('Leave at security')}
                    style={{
                        border: selectedInstructions.includes('Leave at security')
                            ? '2px solid #02A750'
                            : '2px solid #E2E8F0',
                    }}
                >
                    <p className="instructions-text">{t('cart.leave-at-security')}</p>
                    {selectedInstructions.includes('Leave at security') && (
                        <svg
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.32453 4.50007L8.82895 0.995468C9.05701 0.767508 9.05701 0.398929 8.82895 0.17097C8.601 -0.0569899 8.23243 -0.0569899 8.00447 0.17097L4.49995 3.67557L0.995527 0.17097C0.767466 -0.0569899 0.399001 -0.0569899 0.171046 0.17097C-0.0570154 0.398929 -0.0570154 0.767508 0.171046 0.995468L3.67547 4.50007L0.171046 8.00467C-0.0570154 8.23263 -0.0570154 8.6012 0.171046 8.82916C0.28465 8.94288 0.434022 9 0.583287 9C0.732552 9 0.881817 8.94288 0.995527 8.82916L4.49995 5.32456L8.00447 8.82916C8.11818 8.94288 8.26745 9 8.41671 9C8.56598 9 8.71524 8.94288 8.82895 8.82916C9.05701 8.6012 9.05701 8.23263 8.82895 8.00467L5.32453 4.50007Z"
                                fill="#02A750"
                            />
                        </svg>
                    )}
                </div>

                <div
                    className="instruction-container"
                    onClick={() => handleInstructionClick('Do not call')}
                    style={{
                        border: selectedInstructions.includes('Do not call')
                            ? '2px solid #02A750'
                            : '2px solid #E2E8F0',
                    }}
                >
                    <p className="instructions-text">{t('cart.do-not-call')}</p>
                    {selectedInstructions.includes('Do not call') && (
                        <svg
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.32453 4.50007L8.82895 0.995468C9.05701 0.767508 9.05701 0.398929 8.82895 0.17097C8.601 -0.0569899 8.23243 -0.0569899 8.00447 0.17097L4.49995 3.67557L0.995527 0.17097C0.767466 -0.0569899 0.399001 -0.0569899 0.171046 0.17097C-0.0570154 0.398929 -0.0570154 0.767508 0.171046 0.995468L3.67547 4.50007L0.171046 8.00467C-0.0570154 8.23263 -0.0570154 8.6012 0.171046 8.82916C0.28465 8.94288 0.434022 9 0.583287 9C0.732552 9 0.881817 8.94288 0.995527 8.82916L4.49995 5.32456L8.00447 8.82916C8.11818 8.94288 8.26745 9 8.41671 9C8.56598 9 8.71524 8.94288 8.82895 8.82916C9.05701 8.6012 9.05701 8.23263 8.82895 8.00467L5.32453 4.50007Z"
                                fill="#02A750"
                            />
                        </svg>
                    )}
                </div>

                <button className="submit-btn-cooking-inst">
                    {t('cart.submit-button')}
                </button>
            </div>
        </>
    );
}

export default DeliveryInstruction;
