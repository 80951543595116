import React from 'react';
import './../../../Styles/Cart/CookingInstModal.css';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';

import CookingInstruction from './CookingInstruction';

function CookingInstModal({ handleClose, open }) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="cooking-inst-modal">
                    <CookingInstruction handleClose={handleClose} />
                </Box>
            </Modal>
        </div>
    );
}

export default CookingInstModal;
