import i18n from 'i18next';
import languagedetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import backend from 'i18next-http-backend';




i18n.use(languagedetector).use(initReactI18next).use(backend).init({
    debug:true,
    lng:'en',
    fallbackLng:'en',
    returnObjects:true,
    // backend: {
    //     // Here you specify the endpoint from where to load the translations
    //     loadPath: '/api/translations/{{lng}}/{{ns}}', // Adjust this to your API endpoint
    // },
});


export default i18n;
