import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import ic1 from '../../../assets/editprofile.png';
import ic2 from '../../../assets/paymentsettings.png';
import ic3 from '../../../assets/changelanguage.png';
import ic4 from '../../../assets/chatwithus.png';
import ic5 from '../../../assets/privacypolicy.png';
import ic6 from '../../../assets/Termsandconditions.png';
import ic7 from '../../../assets/addanaccount.png';
import ic8 from '../../../assets/reportaproblem.png';
import ic9 from '../../../assets/Logout.png';
import ic10 from '../../../assets/update adrress.png';
import Editprofile from './Editprofile.jsx';
import Switchaccount from './Switchaccount.jsx';
import Changelanguage from './changelanguage.jsx';
import ChangePassword from './Change Password.jsx';
import UpdateAddresses from './Update Addresses.jsx';
import './Profilesettings.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingTop: 6 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function ProfilesettingsMain() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { t } = useTranslation();
    const navigate = useNavigate();
    const mobtabs = [
        'edit-profile',
        'change-password',
        'payment-settings',
        'change-language',
        'update-addresses',
        'chat-with-us',
        'privacy-policy',
        'terms-and-conditions',
        'switch-account',
        'report-a-problem',
        'logout',
    ];

    const [value, setValue] = useState(isDesktop ? 0 : undefined);

    useEffect(() => {
    // Update value based on current path when the component mounts
        const path = window.location.pathname.split('/').pop();
        const index = mobtabs.indexOf(path);
        if (index !== -1) {
            setValue(index);
        }
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (!isDesktop) {
            navigate(`/${mobtabs[newValue]}`);
        }
    };

    return (
        <div className="profile-settings-home">
            <div className="Tabs-container">
                <Box className="left-panel">
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        className="Tabs-css"
                    >
                        <Tab
                            icon={<img src={ic1} alt="profile" className="tab-icon-img" />}
                            label={t('profilesettings.edit-profile-tab')}
                            className="left-tabs1"
                            {...a11yProps(0)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic2}
                                    alt="Password"
                                    width="22px"
                                    height="17.71px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.change-password-tab')}
                            className="left-tabs2"
                            {...a11yProps(1)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic2}
                                    alt="Payment"
                                    width="22px"
                                    height="17.71px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.payment-settings-tab')}
                            className="left-tabs3"
                            {...a11yProps(2)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic3}
                                    alt="language"
                                    width="22px"
                                    height="22px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.change-language-tab')}
                            className="left-tabs4"
                            {...a11yProps(3)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic10}
                                    alt="Addresses"
                                    width="20px"
                                    height="22.23px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.update-addresses-tab')}
                            className="left-tabs5"
                            {...a11yProps(4)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic4}
                                    alt="Chat"
                                    width="20px"
                                    height="20px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.chat-with-us-tab')}
                            className="left-tabs6"
                            {...a11yProps(5)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic5}
                                    alt="policy"
                                    width="18.65px"
                                    height="25px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.privacy-policy-tab')}
                            className="left-tabs7"
                            {...a11yProps(6)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic6}
                                    alt="T&C"
                                    width="24px"
                                    height="24px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.terms-and-conditions-tab')}
                            className="left-tabs8"
                            {...a11yProps(7)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic7}
                                    alt="Switch-acc"
                                    width="28.03px"
                                    height="28.79px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.switch-account-tab')}
                            className="left-tabs9"
                            {...a11yProps(8)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic8}
                                    alt="Report"
                                    width="28px"
                                    height="25.17px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.report-a-problem-tab')}
                            className="left-tabs10"
                            {...a11yProps(9)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic9}
                                    alt="Logout"
                                    width="19.56px"
                                    height="21.98px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.logout-tab')}
                            className="left-tabs11"
                            {...a11yProps(10)}
                        />
                    </Tabs>
                </Box>
                {isDesktop && (
                    <Box
                        className="editprofile-main"
                        sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <TabPanel value={value} index={0} sx={{ gap: '20px' }}>
                            <Editprofile />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ChangePassword />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
              Payment settings
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Changelanguage />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <UpdateAddresses />
                        </TabPanel>
                        <TabPanel value={value} index={5}>
              Chat with us
                        </TabPanel>
                        <TabPanel value={value} index={6}>
              Privacy policy
                        </TabPanel>
                        <TabPanel value={value} index={7}>
              Terms and conditions
                        </TabPanel>
                        <TabPanel value={value} index={8}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '100px',
                                }}
                            >
                                <Switchaccount />
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={9}>
              Report a problem
                        </TabPanel>
                        <TabPanel value={value} index={10}>
              Logout
                        </TabPanel>
                    </Box>
                )}
            </div>
        </div>
    );
}
