import React from 'react';

import './../../../Styles/Cart/AddOns.css';
import Item1 from './../../../assets/add-ons1.png';
import Item2 from './../../../assets/add-ons2.png';
import Item3 from './../../../assets/add-ons3.png';

import addImg from './../../../assets/plus.png';
import starImg from './../../../assets/Star 3.png';

function AddOns() {
    const Items = [
        {
            id: 1,
            img: Item1,
            title: 'Chicken Noodles',
            price: 10,
            rating: 3.8,
        },
        {
            id: 2,
            img: Item2,
            title: 'Chicken Noodles',
            price: 10,
            rating: 3.8,
        },
        {
            id: 3,
            img: Item3,
            title: 'Chicken Noodles',
            price: 10,
            rating: 3.8,
        },
        {
            id: 4,
            img: Item2,
            title: 'Chicken Noodles',
            price: 10,
            rating: 3.8,
        },
        {
            id: 5,
            img: Item1,
            title: 'Chicken Noodles',
            price: 10,
            rating: 3.8,
        },
    ];
    return (
        <>
            <h1 className="add-ons-title">Add-ons</h1>

            <div className="add-ons-container">
                {Items.map((item) => {
                    return (
                        <div className="items-container" key={item.id}>
                            <div className="image-and-title-container">
                                <img className="item-img" src={item.img} alt={item.name} />
                                <img className="plus-img" src={addImg} alt="plus-img" />
                            </div>
                            <div className="title-price-rating-container">
                                <h3 className="item-title">{item.title}</h3>
                                <div className="price-and-rating-container">
                                    <p className="item-price-addons">${item.price}</p>
                                    <div className="rating-container">
                                        <img
                                            className="star-img-addons"
                                            src={starImg}
                                            alt="star-img"
                                        />
                                        <p className="rating-addons">{item.rating}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default AddOns;
