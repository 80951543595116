import React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import AdressList from './AdressList';
import './../../../Styles/Cart/AddressListModel.css';

function AddressListModel({ handleClose, open, handleUpdateDeliveryAddress }) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="styleAddressModel">
                    <AdressList
                        handleClose={handleClose}
                        handleUpdateDeliveryAddress={handleUpdateDeliveryAddress}
                    />
                </Box>
            </Modal>
        </div>
    );
}

export default AddressListModel;
