import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import othericon from '../../../assets/addrmapicon.png';
import addicon from '../../../assets/addraddicon.png';
import { Modal, Box, TextField } from '@mui/material';
import curlocicon from '../../../assets/currlocation.png';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import { Link } from 'react-router-dom';
// import { IoIosArrowRoundBack } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';

function UpdateAddresses() {
    const { t } = useTranslation();

    const base_URL = process.env.REACT_APP_API_BASE_URL;

    const [openModal, setOpenModal] = useState(false);
    const [openModal1, setOpenModal1] = useState(false);
    const [deliveryName, setDeliveryName] = useState('');
    const [streetName, setStreetName] = useState('');
    const [areaCity, setAreaCity] = useState('');
    const [areaState, setAreaState] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [addressList, setAddressList] = useState([]);
    const [selectedAddressId, setSelectedAddressId] = useState(null); // Define selectedAddressId

    const fetchAddresses = async () => {
        try {
            const response = await axios.get(`${base_URL}/address/account/2`);
            const data = response.data;

            if (Array.isArray(data.addresses)) {
                const filteredData = data.addresses.filter(
                    (item) =>
                        item.Account_id === 2 && // Filter addresses by Account_id
            item.Delivery_name !== null &&
            item.Address_line_1 !== null &&
            item.City !== null &&
            item.State !== null &&
            item.ZIP !== null
                );

                const formattedAddresses = filteredData.map((address) => ({
                    id: address.ID,
                    addressType: 'Home',

                    address: `${address.Delivery_name}, ${address.Address_line_1}, ${address.City}, ${address.State}, ${address.ZIP}`,
                }));

                setAddressList(formattedAddresses);
            } else {
                console.error(
                    'Error fetching addresses: Data.addresses is not an array'
                );
            }
        } catch (error) {
            console.error('Error fetching addresses:', error);
        }
    };

    useEffect(() => {
        fetchAddresses();
    }, []);

    const handleEditClick = (id) => {
        const addr = addressList.find((address) => address.id === id);
        if (addr) {
            setOpenModal(true);
            setSelectedAddressId(id);
            const [name, line1, city, state, zip] = addr.address.split(',');
            setDeliveryName(name.trim());
            setStreetName(line1.trim());
            setAreaCity(city.trim());
            setAreaState(state.trim());
            setZipcode(zip.trim());
        } else {
            console.error('Address not found with ID:', id);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        resetFormFields();
    };

    const handleAddClick = () => {
        setOpenModal1(true);
    };

    const handleCloseModal1 = () => {
        setOpenModal1(false);
        resetFormFields();
    };

    const resetFormFields = () => {
        setSelectedAddressId(null);
        setDeliveryName('');
        setStreetName('');
        setAreaCity('');
        setAreaState('');
        setZipcode('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!deliveryName || !streetName || !areaCity || !areaState || !zipcode) {
            console.error('All fields are required.');
            return;
        }

        try {
            let response;
            const addressData = {
                Delivery_name: deliveryName,
                Address_line_1: streetName,
                Address_line_2: '',
                City: areaCity,
                State: areaState,
                ZIP: zipcode,
                Account_id: 2,
                Customer_id: 2,
            };

            if (selectedAddressId) {
                console.log(selectedAddressId);
                response = await axios.put(
                    `${base_URL}/address/account/2/${selectedAddressId}`,
                    addressData
                );
                await fetchAddresses();
                toast.success('Address Updated Successfully');
            } else {
                response = await axios.post(
                    `${base_URL}/address/account/2`,
                    addressData
                );
                await fetchAddresses();
                toast.success('Address Added Successfully');
            }

            if (response.status === 200 || response.status === 201) {
                fetchAddresses();
                handleCloseModal();
                handleCloseModal1();
            } else {
                console.error('Error:', response.status);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const handleDeleteAddress = async (id) => {
        try {
            const deleteResponse = await axios.delete(
                `${base_URL}/address/account/2/${id}`
            );
            toast.success('Address Deleted Successfully');
            if (deleteResponse.status === 200) {
                fetchAddresses();
            } else {
                console.error('Error deleting address:', deleteResponse.status);
                alert('Error deleting address. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An unexpected error occurred');
        }
    };

    return (
        <div className="editprofile-top-main">
            <div className="sidebar">
                <SideMenuBar />
            </div>
            <div className="top-bar">
                <TopMenuBar />
            </div>
            <div className="UpdateAddress-main-cont">
                <div className="edit-address-top-layout-1">
                    <Link to="/profilesettings">
                        <div className="ico1">
                            <svg
                                width="22"
                                height="25"
                                viewBox="0 0 42 78"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M38.9909 3L3 39L39 75"
                                    stroke="#464B59"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </Link>
                    <div className="edit-address-text-layout">
                        <h5 className="edit-address-text">
                            {t('updateaddresses.edit-addresses-title')}
                        </h5>
                    </div>
                </div>
                <div className="Saved-Address-text">
                    <p>{t('updateaddresses.saved-address-title')}</p>
                </div>
                <div className="address-cards-container">
                    {addressList.map((addr) => (
                        <React.Fragment key={addr.id}>
                            <hr className="dotted-line" />
                            <div className="address-card">
                                <div className="Address-container">
                                    <img className="address-icon" src={othericon} alt="icon" />
                                    <div className="address-text-container">
                                        <p className="address-name-text">{addr.addressType}</p>
                                        <p className="address-text">{addr.address}</p>
                                        <div className="addr-buttons-layout">
                                            <div className="addr-button-layout">
                                                <button
                                                    className="addr-button"
                                                    onClick={() => handleDeleteAddress(addr.id)}
                                                >
                                                    {t('updateaddresses.delete-button')}
                                                </button>
                                            </div>
                                            <div className="addr-button-layout">
                                                <button
                                                    className="addr-button"
                                                    onClick={() => handleEditClick(addr.id)}
                                                >
                                                    {t('updateaddresses.edit-button')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                <div className="add-address-button-container" onClick={handleAddClick}>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: 'var(--color4)',
                            color: 'var(--color2)',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '19.5px',
                            marginTop: '-35px',
                            textAlign: 'left',
                            height: '50px',
                            width: '100%',
                            gap: '50%',
                        }}
                        endIcon={
                            <img
                                src={addicon}
                                alt="Add Icon"
                                width="18.21px"
                                height="21.86px"
                                style={{ marginLeft: 'auto' }}
                            />
                        }
                    >
                        {t('updateaddresses.add-new-address-button')}
                    </Button>
                </div>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="popup-layout-editaddr">
                        <div>
                            <div className="editaddr-layout">
                                <div className="topofmodal">
                                    <span className="editaddr-text">
                                        {t('updateaddresses.edit-address-title')}
                                    </span>
                                    <svg
                                        width="25"
                                        height="18"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="cross-icon-img"
                                        onClick={handleCloseModal}
                                    >
                                        <path
                                            d="M14.7904 12.5002L24.5249 2.76519C25.1584 2.13197 25.1584 1.10814 24.5249 0.474916C23.8917 -0.158305 22.8679 -0.158305 22.2346 0.474916L12.4999 10.2099L2.76535 0.474916C2.13185 -0.158305 1.10834 -0.158305 0.475128 0.474916C-0.158376 1.10814 -0.158376 2.13197 0.475128 2.76519L10.2096 12.5002L0.475128 22.2352C-0.158376 22.8684 -0.158376 23.8922 0.475128 24.5255C0.790694 24.8413 1.20562 25 1.62024 25C2.03487 25 2.44949 24.8413 2.76535 24.5255L12.4999 14.7905L22.2346 24.5255C22.5505 24.8413 22.9651 25 23.3798 25C23.7944 25 24.209 24.8413 24.5249 24.5255C25.1584 23.8922 25.1584 22.8684 24.5249 22.2352L14.7904 12.5002Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="currlocation-main">
                                <img
                                    className="currlocation-icon"
                                    src={curlocicon}
                                    alt="Current Location"
                                />
                                <span className="use-currloc-text">
                                    {t('updateaddresses.use-current-location-title')}
                                </span>
                            </div>
                            <div className="addr-details">
                                <form onSubmit={handleSubmit}>
                                    <div className="DeliveryName-container">
                                        <p className="DeliveryName-text">
                                            {t('updateaddresses.delivery-name-title')}
                                        </p>
                                        <TextField
                                            placeholder={t(
                                                'updateaddresses.delivery-name-placeholder'
                                            )}
                                            className="textfield-mui"
                                            value={deliveryName}
                                            onChange={(e) => setDeliveryName(e.target.value)}
                                        />
                                    </div>
                                    <div className="DeliveryName-container">
                                        <p className="DeliveryName-text">
                                            {t('updateaddresses.street-title')}
                                        </p>
                                        <TextField
                                            className="textfield-mui"
                                            value={streetName}
                                            onChange={(e) => setStreetName(e.target.value)}
                                        />
                                    </div>
                                    <div className="DeliveryName-container">
                                        <p className="DeliveryName-text">
                                            {t('updateaddresses.area-and-city-title')}
                                        </p>
                                        <TextField
                                            className="textfield-mui"
                                            value={areaCity}
                                            onChange={(e) => setAreaCity(e.target.value)}
                                        />
                                    </div>
                                    <div className="DeliveryName-container">
                                        <p className="DeliveryName-text">
                                            {t('updateaddresses.state-title')}
                                        </p>
                                        <TextField
                                            className="textfield-mui"
                                            value={areaState}
                                            onChange={(e) => setAreaState(e.target.value)}
                                        />
                                    </div>
                                    <div className="DeliveryName-container">
                                        <p className="DeliveryName-text">
                                            {t('updateaddresses.zip-code-title')}
                                        </p>
                                        <TextField
                                            value={zipcode}
                                            onChange={(e) => setZipcode(e.target.value)}
                                            className="textfield-mui"
                                        />
                                    </div>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: '#E13535',
                                            color: '#FFFFFF',
                                            width: '90%',
                                            height: '45px',
                                            marginTop: '15px',
                                            fontStyle: 'capitalize',
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        {t('updateaddresses.submit-button')}
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </Box>
                </Modal>
                <Modal
                    open={openModal1}
                    onClose={handleCloseModal1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: '#FFFFFF',
                            borderRadius: '15px',
                            width: '347px',
                            height: '505px',
                        }}
                    >
                        <div>
                            <div className="editaddr-layout">
                                <p className="editaddr-text">
                                    {t('updateaddresses.add-new-address-title')}
                                </p>
                                <div className="cross-svg">
                                    <svg
                                        width="25"
                                        height="18"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="cross-icon-img"
                                        onClick={handleCloseModal1}
                                    >
                                        <path
                                            d="M14.7904 12.5002L24.5249 2.76519C25.1584 2.13197 25.1584 1.10814 24.5249 0.474916C23.8917 -0.158305 22.8679 -0.158305 22.2346 0.474916L12.4999 10.2099L2.76535 0.474916C2.13185 -0.158305 1.10834 -0.158305 0.475128 0.474916C-0.158376 1.10814 -0.158376 2.13197 0.475128 2.76519L10.2096 12.5002L0.475128 22.2352C-0.158376 22.8684 -0.158376 23.8922 0.475128 24.5255C0.790694 24.8413 1.20562 25 1.62024 25C2.03487 25 2.44949 24.8413 2.76535 24.5255L12.4999 14.7905L22.2346 24.5255C22.5505 24.8413 22.9651 25 23.3798 25C23.7944 25 24.209 24.8413 24.5249 24.5255C25.1584 23.8922 25.1584 22.8684 24.5249 22.2352L14.7904 12.5002Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div className="addr-details">
                                <form onSubmit={handleSubmit}>
                                    <div className="DeliveryName-container">
                                        <p className="DeliveryName-text">
                                            {t('updateaddresses.delivery-name-title')}
                                        </p>
                                        <TextField
                                            onChange={(e) => setDeliveryName(e.target.value)}
                                            placeholder={t(
                                                'updateaddresses.delivery-name-placeholder'
                                            )}
                                            className="textfield-mui"
                                        />
                                    </div>
                                    <div className="DeliveryName-container">
                                        <p className="DeliveryName-text">
                                            {t('updateaddresses.street-title')}
                                        </p>
                                        <TextField
                                            onChange={(e) => setStreetName(e.target.value)}
                                            className="textfield-mui"
                                        />
                                    </div>
                                    <div className="DeliveryName-container">
                                        <p className="DeliveryName-text">
                                            {t('updateaddresses.area-and-city-title')}
                                        </p>
                                        <TextField
                                            onChange={(e) => setAreaCity(e.target.value)}
                                            className="textfield-mui"
                                        />
                                    </div>
                                    <div className="DeliveryName-container">
                                        <p className="DeliveryName-text">
                                            {t('updateaddresses.state-title')}
                                        </p>
                                        <TextField
                                            onChange={(e) => setAreaState(e.target.value)}
                                            className="textfield-mui"
                                        />
                                    </div>
                                    <div className="DeliveryName-container">
                                        <p className="DeliveryName-text">
                                            {t('updateaddresses.zip-code-title')}
                                        </p>
                                        <TextField
                                            onChange={(e) => setZipcode(e.target.value)}
                                            className="textfield-mui"
                                        />
                                    </div>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: '#E13535',
                                            color: '#FFFFFF',
                                            width: '90%',
                                            height: '45px',
                                            marginTop: '15px',
                                            fontStyle: 'capitalize',
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        {t('updateaddresses.add-address-button')}
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

export default UpdateAddresses;
