import React from 'react';
import './Food.css';

import star from '../../../assets/Star.png'; // Assuming the star icon is stored in a file named 'star.png'

import img1 from '../../../assets/offersforyou1.png';

function Offer({ searchTerm, results }) {
    const validResults = results || [];

    console.log('food clicked now');

    const filteredOffers = validResults.filter((offers) =>
        offers.Restaurant_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <div className="food-container-fake">
            <div className="Food-Nearby-restaurants-cards-container">
                {filteredOffers.length > 0 ? (
                    filteredOffers.map((offer, index) => (
                        <div key={index} className="Food-search-restaurants-card">
                            <div className="img-and-offer-percentage">
                                <img
                                    className="Food-search-restaurants-image"
                                    src={img1}
                                    alt="Food img"
                                />
                                <h5 className="offer-percentage">{offer.Offer_percent}% OFF</h5>
                            </div>
                            <span className="Food-search-restaurants-text">
                                {offer.Restaurant_name}
                            </span>
                            <div className="Food-search-restaurant-footer">
                                <span className="Food-search-restaurant-dist">
                                    {offer.Distance_in_Miles} m
                                </span>

                                <div className="Food-dist-layout">
                                    <img
                                        className="Food-search-rest-star-icon-image"
                                        src={star}
                                        alt="search-rest-star icon"
                                    />
                                    <span className="Food-search-rest-reviews">
                                        {offer.Restaurant_ratings} reviews
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <h5 className="no-data-found">No Offer Found</h5>
                )}
            </div>
        </div>
    );
}

export default Offer;
