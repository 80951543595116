import React, { useState } from 'react';
import '../../../Styles/Notification/Notification.css';
import {useTranslation} from 'react-i18next';

function Notification({closeOverlay}) {
    const {t} = useTranslation();

    const  [notifications,setNotifications] =useState([

        {
    
            id: 1,
            title: '10% Off On First Order',
            time:'10:30 AM',
            date: '12 Mar',
            description: 'Filler text is text that shares some characteristics of a real written text Filler text is text that shares some characteristi...',
        },
        {
    
            id: 2,
            title: '2$ Off ON Burgers',
            time:'10:30 AM',
            date: '12 Mar',
            description: 'Filler text is text that shares some characteristics of a real written text Filler text is text that shares some characteristi...',
        },
        {
    
            id: 3,
            title: '10% Off On First Order',
            time:'10:30 AM',
            date: '12 Mar',
            description: 'Filler text is text that shares some characteristics of a real written text Filler text is text that shares some characteristi...',
        },
        {
    
            id: 4,
            title: 'Use Coupon Code  SAVE5$',
            time:'10:30 AM',
            date: '12 Mar',
            description: 'Filler text is text that shares some characteristics of a real written text Filler text is text that shares some characteristi...',
        },
        {
    
            id: 5,
            title: '10% Off On First Order',
            time:'10:30 AM',
            date: '12 Mar',
            description: 'Filler text is text that shares some characteristics of a real written text Filler text is text that shares some characteristi...',
        },
        {
    
            id: 6,
            title: 'Use Coupon Code  SAVE5$',
            time:'10:30 AM',
            date: '12 Mar',
            description: 'Filler text is text that shares some characteristics of a real written text Filler text is text that shares some characteristi...',
        }

    ]);

    const [dotColor, setDotColor] = useState('red');


    const markAllAsRead = () => {
        setDotColor('gray');
    };


    const clearNotifications =()=>{
        setNotifications([]);
    };
    return (
        <div  className='notification-container-overall'>
            <div className='main-title-container'>
                <h1 className='main-title-notification'>{t('notification.notifications-title')}</h1>
                <p className='mark-all-as-read' onClick={markAllAsRead}>{t('notification.mark-all-as-read-title')}</p>
                <p className='mark-all-as-read' onClick={clearNotifications}>{t('notification.clear-all-title')}</p>
                <svg width="18" height="18" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" className='notification-close-icon' onClick={closeOverlay}>
                    <path d="M22.4814 19.0003L37.2778 4.20309C38.2407 3.24059 38.2407 1.68437 37.2778 0.721872C36.3153 -0.240624 34.7591 -0.240624 33.7967 0.721872L18.9998 15.5191L4.20334 0.721872C3.24041 -0.240624 1.68467 -0.240624 0.722195 0.721872C-0.240732 1.68437 -0.240732 3.24059 0.722195 4.20309L15.5186 19.0003L0.722195 33.7975C-0.240732 34.76 -0.240732 36.3162 0.722195 37.2787C1.20186 37.7588 1.83254 38 2.46277 38C3.093 38 3.72323 37.7588 4.20334 37.2787L18.9998 22.4815L33.7967 37.2787C34.2768 37.7588 34.907 38 35.5372 38C36.1675 38 36.7977 37.7588 37.2778 37.2787C38.2407 36.3162 38.2407 34.76 37.2778 33.7975L22.4814 19.0003Z" fill="black"/>
                </svg>

            </div>
            <div className='notification-messages-main-container'>
                {
                    notifications.map((notification,index) => {

                        return (
                            <div key={index}>
                                <div className='title-time-and-date-container'>
                                    <div className='svg-title-notification'>
                                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" className='red-dot-in-notification'>
                                            <rect width="11" height="11" rx="5.5"  fill={dotColor === 'red' ? '#E13535' : '#CBD5E1'}/>
                                        </svg>

                                        <h1 className='notification-title'>{notification.title}</h1>
                                    </div>

                                    <div className='time-date-notification'>
                                        <p className='time-in-notification'>{notification.time}</p>
                                        <p className='date-in-notification'>{notification.date}</p>
                                    </div>


                                </div>

                                <p className='notification-description'>{notification.description}</p>

                                <div className='horizantal-line-notification'></div>

                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default Notification;