// import '../../VendorStyles/Preparing.css';
// // import React, { useState,useEffect } from 'react';

// function Preparing(){
//     const orders = [
//         {  
//             id:6354,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6364,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6374,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6384,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6394,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6454,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6554,
//             time:'10:30 AM',
//             date:'22 Mar'

//         }
//     ];
//     // const [orders, setOrders] = useState([]);
//     // useEffect(() => {
//     //     // Fetch data from the API
//     //     fetch('http://localhost:5002/api/order/vendor/1')
//     //         .then(response => response.json())
//     //         .then(data => {
//     //             const formattedOrders = data.address.map(order => {
//     //                 const createdAt = new Date(order.Created_at);
//     //                 const time = createdAt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
//     //                 const date = `${createdAt.getDate()} ${createdAt.toLocaleString('en-US', { month: 'short' })}`;
//     //                 return {
//     //                     id: order.id,
//     //                     time,
//     //                     date
//     //                 };
//     //             });
//     //             setOrders(formattedOrders);
//     //         })
//     //         .catch(error => console.error('Error fetching data:', error));
//     // }, []);

//     return(
//         <div className="preparing-main">
//             {orders.map(order => (
//                 <div key={order.id} className="preparing-single-order">
//                     <div className='preparing-orderid'><p className='preparing-order-details1'>Order ID:<span className='preparing-order-id'>{order.id}</span></p></div>
//                     <div className='preparing-date-and-time'><p className='preparing-order-details2'>{order.time}</p>
//                         <p className='preparing-order-details3'>{order.date}</p></div>
//                     <button className='push-to-pickup-btn'><p className='push-to-pickup-text'>Push to Pickup</p></button>
//                 </div>
//             ))}
    
//         </div>
//     );

// }
// export default Preparing;


import '../../VendorStyles/Preparing.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';


function Preparing() {

    // const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;
    const vendor_Base_URL = 'http://localhost:5003/api';

    const [orders, setOrders] = useState([]);
    console.log(orders);

    // const [page, setPage] = useState(1); // Track the current page
    // const [loading, setLoading] = useState(false); // Loading state


    // const [hasMoreOrders, setHasMoreOrders] = useState(true); // Track if more orders are available

    // const pageSize = 10; // Items per page


    useEffect(() => {
        // Fetch data from the API
        axios.get(`${vendor_Base_URL}/order/vendor/1?pageSize=10&pageNumber=1`)
        // .then(response => {
        //     const orderDetails = response.data.allOrdersDetails;
        //     console.log(orderDetails);
        // if (orderDetails) {
        // const formattedOrders = orderDetails
        //     .filter(order => order.Status_id === 2)
        //     .map(order => {
        //         const createdAt = new Date(order.Created_at);
        //         const time = createdAt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        //         const date = `${createdAt.getDate()} ${createdAt.toLocaleString('en-US', { month: 'short' })}`;
        // return {
        //     id: order.ID,  // Ensure this matches the field name from your API
        //     time,
        //     date,
        //     customerId: order.Customer_id // Ensure this matches the field name from your API
        // };
                            
        //     //         });
        //     //     // setOrders(formattedOrders);
        //     // }
        // })
        // .catch(error => console.error('Error fetching data:', error));

        //     setOrders(orderDetails);
        // });

            .then(response => {
                console.log(response);
                const formattedOrders = response.data.allOrdersDetails
                    .filter(order => order.Status_id === 2)
                    .map(order => {
                        const createdAt = new Date(order.Created_at);
                        const time = createdAt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                        const date = `${createdAt.getDate()} ${createdAt.toLocaleString('en-US', { month: 'short' })}`;
                        return {
                            id: order.ID,
                            time,
                            date,
                            customerId: order.Customer_id
                        };
                    });
                setOrders(formattedOrders);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);



    // Function to fetch orders based on page
    // const fetchNewOrders = async () => {
    //     try {
    //         // setLoading(true);
    //         const response = await axios.get(`${vendor_Base_URL}/order/vendor/1?pageSize=${pageSize}&pageNumber=${page}`);
    //         const incomingData = response.data.allOrdersDetails.filter(order => order.Status_id === 2);

    //         if (incomingData.length < pageSize) {
    //             // If the number of items fetched is less than the page size, it means there are no more pages to fetch
    //             setHasMoreOrders(false);
    //         }

    //         // If it's the first page, replace the data, otherwise append
    //         setOrders((prev) => page === 1 ? [...incomingData] : [...prev, ...incomingData]);

    //         // setOrders((prev) => [...prev, ...incomingData]); // Append new orders to the list
    //         // setLoading(false);
    //     } catch (error) {
    //         console.error(error.message);
    //         setLoading(false);
    //     }
    // };


    // useEffect(()=>{
    //     fetchNewOrders();  
    // },[page]);

    // const fetchMoreOrders = () => {
    //     setPage((prevPage) => prevPage + 1); // Increment page number to fetch more data
    // };

    const handlePushToPickup = (order) => {
        // const url = `${vendor_Base_URL}/Order/Vendor/1/Customer/${order.customerId}/${order.id}`;
        // console.log(`Updating order status for URL: ${url}`); // Debug statement to verify the URL
        
        axios.put(`${vendor_Base_URL}/Order/Vendor/1/order/${order.id}/status`, { status_id: 5 })
            .then(() => {
                // Update the local state to reflect the change
                setOrders(orders.filter(o => o.id !== order.id));
            })
            .catch(error => console.error('Error updating order status:', error));
    };

    return (
        <div className="preparing-main">

            {orders.map( order => (
                <div key={order.id} className="preparing-single-order">
                    <div className='preparing-orderid'>
                        <p className='preparing-order-details1'>Order ID:<span className='preparing-order-id'>{order.id}</span></p>
                    </div>
                    <div className='preparing-date-and-time'>
                        <p className='preparing-order-details2'>{order.time}</p>
                        <p className='preparing-order-details3'>{order.date}</p>
                    </div>
                    <button className='push-to-pickup-btn' onClick={() => handlePushToPickup(order)}>
                        <p className='push-to-pickup-text'>Push to Pickup</p>
                    </button>
                </div>
            ))}
               
        </div>
    );
}

export default Preparing;
