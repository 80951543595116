import React,{useState,useEffect} from 'react';
import '../../../Styles/Payment/Payment.css';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import {useTranslation} from 'react-i18next';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// import { PayPalScriptProvider } from '@paypal/react-paypal-js';

// import {useSelector} from 'react-redux';
// import { getTotal } from '../../../Redux/Reducers/ItemReducer';
import PaymentForm from './PaymentForm';

import CouponModal from './CouponModal';
import axios from 'axios';

const stripePromise = loadStripe('pk_test_51Q5f2hGU8f05p0J99Rt422pCwQfd4RRboUWadP767jIp0Mv6fGGP4Nchfz7DZAYhuYxj1V1i7OnPp7PyBm70OuGD00QI2P0gdW');





function Payment() {


    const base_URL = process.env.REACT_APP_API_BASE_URL;

    // const  base_URL = 'http://localhost:5002/api';

    const [selectedOption, setSelectedOption] = useState('delivery');
    const {t} = useTranslation();



    const [open, setOpen] = useState(false);
    const handleOpen = () =>
    {
        setOpen(!false);
    };
    const handleClose = () => {
        setOpen(false);
    };

    console.log( open);

    // const getData = useSelector((state)=>{
    //     return state.ItemReducer.cart;
    // });

    const [totalPrice,setTotalPrice] = useState(0);

    const getTotalPrice= async()=>{

        const itemResponse = await axios.get(`${base_URL}/cartdetails/1`);
        // console.log(itemResponse.data.cartDetails.totalPrice);
        const data = itemResponse.data.cartDetails.cartDetails[0].result[0].Cart_Total_Price;
        setTotalPrice(data);
    };

    useEffect(()=>{
        getTotalPrice();
    },[]);


    const savings = 2;
    const deliveryCharges = 8;
    const price = totalPrice;
    const total = price  + deliveryCharges  - savings;




    
    return (
        <div >
                    

            <div className="topmenubar">


                <SideMenuBar></SideMenuBar>


                <div className="top-bar-mobile">
                    <TopMenuBar></TopMenuBar>
                </div>
            </div>

            <div className='payment-main-container'>
                <div>
                    
                    
                    <p className='have-a-coupon-text' >{t('payment.have-a-coupon')}</p>
                    
                    <div className='coupon-bg' onClick={handleOpen}>

                        <div className='text-and-svg'>
                            <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31.6875 18.6875C31.6875 20.93 33.5075 22.75 35.75 22.75V24.375C35.75 30.875 34.125 32.5 27.625 32.5H11.375C4.875 32.5 3.25 30.875 3.25 24.375V23.5625C5.4925 23.5625 7.3125 21.7425 7.3125 19.5C7.3125 17.2575 5.4925 15.4375 3.25 15.4375V14.625C3.25 8.125 4.875 6.5 11.375 6.5H27.625C34.125 6.5 35.75 8.125 35.75 14.625C33.5075 14.625 31.6875 16.445 31.6875 18.6875Z" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.625 15.0313L24.375 24.7812" stroke="white" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M24.3661 15.0312H24.3807" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.6161 23.9687H14.6307" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            <p className='apply-coupon-text'>{t('payment.apply-coupon')}</p>
                        </div>

                        <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.80892 -1.01642e-06L12 10.5L1.80892 21L1.74441e-06 19.1363L8.38216 10.5L2.34404e-07 1.86375L1.80892 -1.01642e-06Z" fill="white"/>
                        </svg>

                    </div>

                    {
                        open && (

                            <CouponModal
                                open={open}
                                handleClose={handleClose}
                            
                            />
                            
                        )
                    }

                   
                            
                   
                            
                        
                    

                    <div className='delivery-pickup-container'>

                        <div className={`delivery-container ${selectedOption === 'delivery' ? 'selected' : ''}`}  onChange={() => setSelectedOption('delivery')}>
   
                            <input 
                                className='input-radio'
                                name='food'
                                id='delivery'
                                type="radio"
                                checked={selectedOption === 'delivery'}
                                // onChange={() => setSelectedOption('delivery')}
                            />
                            <label htmlFor='delivery' className='text'>{t('payment.delivery')}</label>
                            
   
                        </div>

                        <div className={`pickup-container ${selectedOption === 'pickup' ? 'selected' : ''}`}  onChange={() => setSelectedOption('pickup')}>
 
                            <input
                                className='input-radio'
                                name='food'
                                id='pickup'
                                type="radio"
                                checked={selectedOption === 'pickup'}
                                // onChange={() => setSelectedOption('pickup')}
                                
                            />
                            <label htmlFor='pickup' className='text' >{t('payment.pickup')}</label>
                          
                        </div>

                    </div>

                    <div className='bill-container'>

                        <p className='bill-summary-text'>{t('payment.bill-summary')}</p>
                        <div className='bill-bg'>



                            <div className='price-saving-total-container'>


                                <div className='price-container'>

                                    <h4 className='price-text'>{t('payment.price')}</h4>
                                    <p className='amount-text'>{price === 0 ? 0 : price} $</p>   
                                </div>
                                <div className='price-container'>

                                    <h4 className='price-text'>{t('payment.savings')}</h4>
                                    <p className='amount-text'>{price === 0 ? 0:-savings} $</p>   
                                </div>

                                <div className='price-container'>

                                    <h4 className='price-text'>{t('payment.delivery-charges')}</h4>
                                    <p className='amount-text'>{price === 0 ? 0 : deliveryCharges} $</p>   
                                </div>

                                <div className='horizantal-line'></div>

                                <div className='total-container'>

                                    <h4 className='price-text'>{t('payment.total')}</h4>
                                    <p className='amount-text'>{price === 0 ? 0 : total} $</p>   
                                </div>


                                
                            </div>
                            

                        </div>

                    </div>

                    


                   

                    

                
                    <div className='payment-form-component-container'>
                       
                        <Elements stripe={stripePromise}>
                            <PaymentForm total={total}/>
                        </Elements>
                        
                    </div>
                </div>

            </div>
            
            <div className="right-side-bar">
                <RightSideBar></RightSideBar>
            </div>
        </div>
    );
}

export default Payment;
