import React from 'react';
import './../../../Styles/Payment/PaymentFail.css';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import { useTranslation } from 'react-i18next';

function PaymentFail() {
    const { t } = useTranslation();

    return (
        <div>
            <div className="topmenubar">
                <SideMenuBar></SideMenuBar>

                <div className="top-bar-mobile">
                    <TopMenuBar></TopMenuBar>
                </div>
            </div>
            <div className="payment-fail-container">
                <div className="zoom-container">
                    <svg
                        width="210"
                        height="210"
                        viewBox="0 0 210 210"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="105"
                            cy="105"
                            r="105"
                            fill="#E13535"
                            fillOpacity="0.09"
                        />
                        <path
                            d="M105 17.5C56.7875 17.5 17.5 56.7875 17.5 105C17.5 153.213 56.7875 192.5 105 192.5C153.213 192.5 192.5 153.213 192.5 105C192.5 56.7875 153.213 17.5 105 17.5Z"
                            fill="#E13535"
                        />
                        <path
                            d="M111.413 105.001L138.67 77.7425C140.443 75.9695 140.443 73.1028 138.67 71.3298C136.897 69.5567 134.03 69.5567 132.257 71.3298L105 98.5878L77.743 71.3298C75.9692 69.5567 73.1033 69.5567 71.3304 71.3298C69.5565 73.1028 69.5565 75.9695 71.3304 77.7425L98.587 105.001L71.3304 132.259C69.5565 134.032 69.5565 136.898 71.3304 138.671C72.2139 139.556 73.3757 140 74.5367 140C75.6976 140 76.8586 139.556 77.743 138.671L105 111.413L132.257 138.671C133.141 139.556 134.302 140 135.463 140C136.624 140 137.785 139.556 138.67 138.671C140.443 136.898 140.443 134.032 138.67 132.259L111.413 105.001Z"
                            fill="white"
                        />
                    </svg>
                </div>
                <p className="failed-order-message">
                    {t('payment.your-order-has-been')}{' '}
                    <span className="failed-span">{t('payment.failed')}</span>
                </p>

                <p className="try-again-message">{t('payment.not-to-worry')}</p>

                <button className="retry-btn">{t('payment.retry-payment')}</button>
            </div>
            <div className="right-side-bar">
                <RightSideBar></RightSideBar>
            </div>
        </div>
    );
}

export default PaymentFail;
