import React, { useState, useEffect } from 'react';
// import ordersData from './InboxDetails';
import './../../../Styles/Inbox/Inbox.css';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import RateOrder from './RateOrderModel';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';

import image1 from '../../../assets/Image1.png';

function Inbox() {
    const base_URL = process.env.REACT_APP_API_BASE_URL;
    // const base_URL = 'http://localhost:5002/api';

    const { t } = useTranslation();

    // const [orderData,setOrderData] = useState([]);

    const [previousOrders, setPreviousOrders] = useState([]);
    console.log(previousOrders);

    const [pageNo, setPageNo] = useState(1); // Track page number for pagination
    const [hasMore, setHasMore] = useState(true); // To check if there are more items to load

    const fetchFollowToWatch = async () => {
        const response = await axios.get(
            `${base_URL}/orders/previous-orders-with-vendor?pageNo={pageNo}&pageSize=10&customerId=1`
        );

        const prevData = response.data.orders;
        // setPreviousOrders(prevData);

        if (prevData.length < pageNo) {
            // If the number of items fetched is less than the page size, it means there are no more pages to fetch
            setHasMore(false);
        }

        // If it's the first page, replace the data, otherwise append
        setPreviousOrders((prev) =>
            pageNo === 1 ? [...prevData] : [...prev, ...prevData]
        );
    };

    useEffect(() => {
        fetchFollowToWatch();
    }, [pageNo]);

    const fetchMoreOrders = () => {
        setPageNo((prevPage) => prevPage + 1); // Increment page number to fetch more data
    };

    const [rateOrder, setRateOrder] = useState([]);

    const [openRateOrder, setOpenRateOrder] = useState(false);
    const handleOpenRateOrder = (id) => {
        const filteredData = previousOrders.filter((item) => item.OrderID === id);
        setRateOrder(filteredData);

        setOpenRateOrder(true);
    };
    const handleCloseRateOrder = () => setOpenRateOrder(false);

    return (
        <div>
            <div className="topmenubar">
                <SideMenuBar></SideMenuBar>

                <div className="top-bar-mobile">
                    <TopMenuBar></TopMenuBar>
                </div>
            </div>

            <div className="order-card-main-container">
                <h1 className="previous-orders-heading">Previous Orders</h1>
                <InfiniteScroll
                    dataLength={previousOrders.length} // Length of current orders list
                    next={fetchMoreOrders} // Function to fetch more orders
                    hasMore={hasMore} // Whether more data to load
                    loader={<h4 className="loader-infinite-scroll">Loading...</h4>}
                    endMessage={
                        <p className="no-orders-to-load">No more orders to show</p>
                    }
                >
                    {previousOrders.map((item, index) => {
                        return (
                            <div key={index} className="order-card-container">
                                <img className="restaurant-img" src={image1} alt="image" />

                                <div className="restaurant-information-container">
                                    <div className="restaurant-name-delivered-container">
                                        <div className="restaurant-name-area-container">
                                            <h5 className="restaurant-title">{item.VendorName}</h5>
                                            <p className="restaurant-area">{item.restaurantArea}</p>
                                        </div>
                                        <div className="delivered-container">
                                            <svg
                                                width="16"
                                                height="17"
                                                viewBox="0 0 16 17"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect width="16" height="17" fill="#F5F5F5" />
                                                <g clipPath="url(#clip0_0_1)">
                                                    <rect
                                                        width="393"
                                                        height="853"
                                                        transform="translate(-301 -161)"
                                                        fill="white"
                                                    />
                                                    <g filter="url(#filter0_d_0_1)">
                                                        <rect
                                                            x="-281"
                                                            y="-16"
                                                            width="351"
                                                            height="145"
                                                            rx="5"
                                                            fill="white"
                                                        />
                                                    </g>
                                                    <ellipse
                                                        cx="7.99145"
                                                        cy="8.5"
                                                        rx="7.99145"
                                                        ry="8.5"
                                                        fill="#02A750"
                                                    />
                                                    <path
                                                        d="M4.70117 8.5L7.20829 11L12.2225 6"
                                                        stroke="white"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                    />
                                                </g>
                                                <defs>
                                                    <filter
                                                        id="filter0_d_0_1"
                                                        x="-308"
                                                        y="-43"
                                                        width="405"
                                                        height="199"
                                                        filterUnits="userSpaceOnUse"
                                                        colorInterpolationFilters="sRGB"
                                                    >
                                                        <feFlood
                                                            floodOpacity="0"
                                                            result="BackgroundImageFix"
                                                        />
                                                        <feColorMatrix
                                                            in="SourceAlpha"
                                                            type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha"
                                                        />
                                                        <feOffset />
                                                        <feGaussianBlur stdDeviation="13.5" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix
                                                            type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                                                        />
                                                        <feBlend
                                                            mode="normal"
                                                            in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_0_1"
                                                        />
                                                        <feBlend
                                                            mode="normal"
                                                            in="SourceGraphic"
                                                            in2="effect1_dropShadow_0_1"
                                                            result="shape"
                                                        />
                                                    </filter>
                                                    <clipPath id="clip0_0_1">
                                                        <rect
                                                            width="393"
                                                            height="853"
                                                            fill="white"
                                                            transform="translate(-301 -161)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <p>Delivered</p>
                                        </div>
                                    </div>

                                    <div className="restuarant-item-container">
                                        <p>Chicken Soft noodles (1)</p>
                                        <p>Chicken Noodels (2)</p>
                                    </div>

                                    <div className="price-and-btns-container">
                                        <>
                                            <h4>${item.Total_price}</h4>
                                        </>

                                        <div className="two-btns">
                                            <button
                                                className="btn-rate-order"
                                                onClick={() => handleOpenRateOrder(item.OrderID)}
                                            >
                                                {t('inbox.rateorder-btn')}
                                            </button>
                                            {openRateOrder && (
                                                <RateOrder
                                                    rateOrder={rateOrder}
                                                    open={openRateOrder}
                                                    handleClose={handleCloseRateOrder}
                                                />
                                            )}
                                            <button className="btn-reorder">
                                                {t('inbox.Reorder-btn')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </InfiniteScroll>
            </div>

            <div className="right-side-bar">
                <RightSideBar></RightSideBar>
            </div>
        </div>
    );
}

export default Inbox;
