import '../../VendorStyles/PreparingRightbar.css';
import VendorRightSidebar from './VendorRightSideBar';
function OrderRightbar(){
    return(
        <><div className="preparing-vertical-line"></div><div className="preparing-rightsidebar-main-cont">
            <VendorRightSidebar></VendorRightSidebar>
        </div></>
    );
}
 
export default OrderRightbar;