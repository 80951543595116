import BusinessHours from './BusinessHours';
import VendorRightSidebar from './VendorRightSideBar';
import '../../VendorStyles/NewOrdersRightbar.css';
function NewOrdersRightbar() {
    

    return (
        <>
            <div className="vertical-line"></div>
            <div className="rightsidebar-main-cont">
                <VendorRightSidebar></VendorRightSidebar>
                <div className="container2">
                    <BusinessHours></BusinessHours>
                </div>
            </div>
        </>
    );
}

export default NewOrdersRightbar;
