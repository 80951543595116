import React, { useEffect, useState } from 'react';
import './PostDetail.css';

import {
    MdOutlineArrowBackIos,
    MdOutlineArrowForwardIos,
} from 'react-icons/md';
import { BsEmojiSmile } from 'react-icons/bs';
import Picker from 'emoji-picker-react';

import profileIcon from '../../../assets/profile image.png';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditPostModal from './EditPostModal';

const PostDetail = ({
    posts,
    handleClose,
    selectedImageIndex,
    handleDeletePost,
}) => {
    const [inputStr, setInputStr] = useState('');
    const [showPicker, setShowPicker] = useState(false);

    const [openEditPostModal, setOpenEditPostModal] = useState(false);
    const handleOpenEditPostModal = () => setOpenEditPostModal(true);
    const handleCloseEditPostModal = () => setOpenEditPostModal(false);

    const [currentPostData, setCurrentPostData] = useState([]);

    useEffect(() => {
        const filteredData = posts.filter((post) => post.id === selectedImageIndex);
        setCurrentPostData(filteredData);
    }, []);

    console.log(posts);
    console.log(selectedImageIndex);

    const currentPost = posts[selectedImageIndex];
    console.log(currentPost);

    // const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [currentSlidePost, setCurrentSlidePost] = useState(0);
    const sliderLength = posts.length;

    const prevSlidePost = () => {
        setCurrentSlidePost(
            currentSlidePost === sliderLength - 1 ? 0 : currentSlidePost + 1
        );
    };
    const nextSlidePost = () => {
        setCurrentSlidePost(
            currentSlidePost === 0 ? sliderLength - 1 : currentSlidePost - 1
        );
    };

    console.log(posts);

    const onEmojiClick = (emojiObject) => {
        setInputStr((prevEmoji) => prevEmoji + emojiObject.emoji);
        setShowPicker(false);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <div className="cross-svg-post-details">
                <svg
                    width="23"
                    height="18"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cross-icon-post-details"
                    onClick={handleClose}
                >
                    <path
                        d="M14.7904 12.5002L24.5249 2.76519C25.1584 2.13197 25.1584 1.10814 24.5249 0.474916C23.8917 -0.158305 22.8679 -0.158305 22.2346 0.474916L12.4999 10.2099L2.76535 0.474916C2.13185 -0.158305 1.10834 -0.158305 0.475128 0.474916C-0.158376 1.10814 -0.158376 2.13197 0.475128 2.76519L10.2096 12.5002L0.475128 22.2352C-0.158376 22.8684 -0.158376 23.8922 0.475128 24.5255C0.790694 24.8413 1.20562 25 1.62024 25C2.03487 25 2.44949 24.8413 2.76535 24.5255L12.4999 14.7905L22.2346 24.5255C22.5505 24.8413 22.9651 25 23.3798 25C23.7944 25 24.209 24.8413 24.5249 24.5255C25.1584 23.8922 25.1584 22.8684 24.5249 22.2352L14.7904 12.5002Z"
                        fill="white"
                    />
                </svg>
            </div>

            <div className="post-details-img-container">
                <div className="user-name-desc-img-cont">
                    {currentPostData.map((post, index) => {
                        return (
                            <div key={index} className="post-img-container-carousel">
                                {index === currentSlidePost ? (
                                    <>
                                        <div className="left-and-right-arrow-container-profile-screen">
                                            <MdOutlineArrowBackIos
                                                size={25}
                                                className="left-arrow-profile-screen"
                                                onClick={() => prevSlidePost()}
                                            />

                                            <MdOutlineArrowForwardIos
                                                size={25}
                                                className="right-arrow-profile-screen"
                                                onClick={() => nextSlidePost()}
                                            />
                                        </div>
                                        <img
                                            className="post-details-img"
                                            src={post.image}
                                            alt="post"
                                        ></img>
                                    </>
                                ) : null}
                            </div>
                        );
                    })}
                </div>

                <div className="comments-and-underline">
                    <div className="profile-and-name-posts">
                        <img
                            className="comment-profile-pic"
                            src={profileIcon}
                            alt="profile pic"
                        ></img>

                        <div className="user-name-post-details-container">
                            <div className="user-name-post-details">
                                <h5>Ramesh</h5>
                                <p className="location-post-details">Virginia,USA</p>
                            </div>
                            <div className="three-dots">
                                <MoreVertIcon
                                    onClick={(e) => handleMenuClick(e)}
                                    fontSize="1.2rem"
                                />
                            </div>
                            <Menu
                                id="basic-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenuClose}
                                sx={{
                                    '.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
                                        boxShadow: 'none',
                                    },
                                    '& .MuiPaper-root': {
                                        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                    },
                                }}
                            >
                                <MenuItem onClick={() => handleOpenEditPostModal()}>
                  edit
                                </MenuItem>
                                <MenuItem onClick={() => handleDeletePost(selectedImageIndex)}>
                  delete
                                </MenuItem>
                                {openEditPostModal && (
                                    <EditPostModal
                                        currentPostData={currentPostData}
                                        handleClose={handleCloseEditPostModal}
                                    />
                                )}
                            </Menu>
                        </div>
                    </div>

                    <div className="description-container-post-details">
                        <p className="user-description-post-details">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit
              possimus excepturi sunt delectus illum eligendi, atque distinctio
              placeat neque mollitia.
                        </p>
                    </div>
                    <h5 className="Comments-title-post-details">Comments</h5>
                    <div className="horizantal-line-post-details"></div>

                    <div className="comments">
                        <div className="comments-main-container-profile"></div>

                        {posts[currentSlidePost].comments.map((comment, index) => (
                            <div key={index} className="profile-comments-container">
                                <div className="profile-and-name">
                                    <img
                                        className="comment-profile-pic"
                                        src={profileIcon}
                                        alt="profile pic"
                                    ></img>

                                    <div className="user-name-post-details">
                                        <h5>Ramesh</h5>
                                    </div>
                                    <p className="user-comment-time1">5h</p>
                                </div>
                                <div className="user-comment1">
                                    <p className="user-comment-text">{comment}</p>
                                    <div className="comment-reply-like1">
                                        <button className="user-comment-reply1">Reply</button>
                                        <svg
                                            width="19"
                                            height="15"
                                            viewBox="0 0 19 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="comment-like-svg"
                                        >
                                            <path
                                                d="M9.57097 15.9746L9.57382 15.9721L9.57383 15.9721C10.3108 15.3242 11.0252 14.7123 11.6539 14.1736L11.7465 14.0944C13.6824 12.4326 15.2622 11.0759 16.3447 9.76323L9.57097 15.9746ZM9.57097 15.9746C9.55414 15.9896 9.53032 16 9.5 16C9.46969 16 9.44586 15.9896 9.42903 15.9746L9.42617 15.9721M9.57097 15.9746L9.42617 15.9721M9.42617 15.9721C8.68925 15.3242 7.97484 14.7123 7.34606 14.1736M9.42617 15.9721L7.34606 14.1736M7.67735 1.82781L7.67736 1.82779C6.96508 1.27195 6.17597 1 5.30783 1C4.11869 1 3.0388 1.47584 2.24362 2.34216L7.67735 1.82781ZM7.67735 1.82781L7.68359 1.83261M7.67735 1.82781L7.68359 1.83261M7.68359 1.83261C8.04398 2.10931 8.37916 2.46269 8.689 2.89219L9.5 4.01641M7.68359 1.83261L9.5 4.01641M9.5 4.01641L10.311 2.89219M9.5 4.01641L10.311 2.89219M10.311 2.89219C10.6184 2.46605 10.9591 2.10991 11.3206 1.82941L11.3226 1.82779M10.311 2.89219L11.3226 1.82779M11.3226 1.82779C12.0349 1.27195 12.824 1 13.6922 1M11.3226 1.82779L13.6922 1M7.34606 14.1736L7.25352 14.0944M7.34606 14.1736L7.25352 14.0944M7.25352 14.0944C7.25329 14.0942 7.25307 14.094 7.25284 14.0938M7.25352 14.0944L7.25284 14.0938M7.25284 14.0938C7.25282 14.0938 7.2528 14.0937 7.25278 14.0937M7.25284 14.0938L7.25278 14.0937M7.25278 14.0937C5.31722 12.4323 3.73765 11.0757 2.65534 9.76323L7.25278 14.0937ZM13.6922 1C14.8813 1 15.9612 1.47586 16.7564 2.3422M13.6922 1L16.7564 2.3422M16.7564 2.3422C17.5418 3.19908 18 4.3979 18 5.746M16.7564 2.3422L18 5.746M18 5.746C18 7.07305 17.5198 8.33693 16.3449 9.76295L18 5.746ZM2.65511 9.76295C1.48017 8.33693 1 7.07305 1 5.746C1 4.3979 1.4582 3.19908 2.24358 2.3422L2.65511 9.76295Z"
                                                stroke="#2C3142"
                                                strokeWidth="2"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="horizantal-line-under-comments-post-details"></div>

                    <div className="svg-icons-and-order-btn-container">
                        <div className="like-comment-share-svg-post-details">
                            <svg
                                width="31"
                                height="27"
                                viewBox="0 0 31 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="svg-post-details"
                            >
                                <path
                                    d="M16.0447 25.803L16.0475 25.8005C17.2467 24.7743 18.4095 23.8046 19.4359 22.9488L19.5868 22.8229L19.5876 22.8223L19.6107 22.8029C22.7446 20.1843 25.361 17.9981 27.1637 15.8699C29.1483 13.5253 30 11.3933 30 9.126C30 6.85994 29.2084 4.81297 27.8139 3.33203C26.399 1.83155 24.4655 1 22.3399 1C20.7724 1 19.3452 1.48218 18.0749 2.44716L18.0728 2.44875C17.4311 2.93357 16.8352 3.54213 16.3034 4.25969L15.5 5.34382L14.6966 4.25969C14.1624 3.53883 13.5751 2.93297 12.9313 2.45186L12.9251 2.44718L12.9251 2.44716C11.6548 1.48218 10.2276 1 8.66014 1C6.53469 1 4.60132 1.83139 3.18653 3.33162L16.0447 25.803ZM16.0447 25.803C15.9007 25.9272 15.7087 26 15.5 26C15.2913 26 15.0993 25.9272 14.9553 25.803L14.9525 25.8005M16.0447 25.803L14.9525 25.8005M14.9525 25.8005C13.7533 24.7743 12.5905 23.8046 11.5642 22.9488M14.9525 25.8005L11.5642 22.9488M11.5642 22.9488L11.4132 22.8229M11.5642 22.9488L11.4132 22.8229M11.4132 22.8229L11.4124 22.8223M11.4132 22.8229L11.4124 22.8223M11.4124 22.8223L11.3892 22.8029C8.25552 20.1844 5.63918 17.9982 3.83648 15.8702L11.4124 22.8223ZM1 9.126C1 11.3933 1.85167 13.5253 3.83624 15.8699L1 9.126ZM1 9.126C1 6.85976 1.79168 4.81265 3.18647 3.33168L1 9.126Z"
                                    stroke="white"
                                    strokeWidth="2"
                                />
                            </svg>

                            <svg
                                width="34"
                                height="34"
                                viewBox="0 0 34 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="svg-post-details"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.8552 3.15695C9.19386 3.15695 2.98314 9.36767 2.98314 17.029C2.98314 20.3078 3.37991 22.409 5.04577 24.6268C5.29664 24.9608 5.36585 25.3973 5.23058 25.7926C4.84196 26.9279 4.26324 28.622 3.78158 30.0326L3.67873 30.3339L4.76505 30.0435C6.51947 29.5744 8.40911 29.0687 8.95994 28.9201C9.28399 28.8327 9.62953 28.8779 9.92007 29.046C11.9592 30.2256 14.3264 30.901 16.8552 30.901C24.5166 30.901 30.7272 24.6902 30.7272 17.029C30.7272 9.36767 24.5166 3.15695 16.8552 3.15695ZM1.72205 32.1621C0.52853 31.7549 0.528546 31.7549 0.528546 31.7547L1.39472 29.2175C1.79578 28.043 2.26435 26.6713 2.63447 25.589C0.840793 22.9418 0.460952 20.3341 0.460952 17.029C0.460952 7.9747 7.80089 0.634766 16.8552 0.634766C25.9095 0.634766 33.2494 7.9747 33.2494 17.029C33.2494 26.0833 25.9095 33.4232 16.8552 33.4232C14.0609 33.4232 11.4269 32.7232 9.12222 31.4882C8.29468 31.7103 6.81093 32.1073 5.41653 32.4801L2.04872 33.3802L1.72205 32.1621ZM1.72205 32.1621L2.04872 33.3802C1.59896 33.5004 1.11842 33.3632 0.80059 33.023C0.482777 32.683 0.378207 32.1954 0.528546 31.7547L1.72205 32.1621Z"
                                    fill="white"
                                />
                            </svg>

                            <svg
                                width="38"
                                height="32"
                                viewBox="0 0 38 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="svg-post-details"
                            >
                                <path
                                    d="M36.102 10.2674L27.7221 1.97284C27.0307 1.28844 26.3294 0.941406 25.6377 0.941406C24.6881 0.941406 23.5791 1.65637 23.5791 3.67135V6.49526C17.4909 6.75792 11.8053 9.22936 7.46886 13.5215C2.87588 18.0674 0.346209 24.1117 0.345703 30.5408C0.345631 31.0027 0.644171 31.4129 1.08681 31.5591C1.19975 31.5964 1.31551 31.6145 1.43011 31.6145C1.76465 31.6145 2.0885 31.4608 2.29738 31.1857C7.41767 24.4394 15.1267 20.4525 23.5791 20.1377V22.918C23.5791 24.9329 24.688 25.648 25.6376 25.648H25.6377C26.3294 25.648 27.0308 25.301 27.7221 24.6166L36.1019 16.3219C36.9164 15.5159 37.3649 14.4408 37.3649 13.2948C37.3649 12.1486 36.9164 11.0735 36.102 10.2674ZM34.5682 14.8038L26.1884 23.0985C26.0276 23.2576 25.8945 23.3581 25.7963 23.4191C25.7705 23.3073 25.7482 23.143 25.7482 22.9181V19.0441C25.7482 18.4512 25.2626 17.9705 24.6636 17.9705C19.8449 17.9705 15.2402 19.0634 10.9779 21.2186C7.91801 22.7658 5.12089 24.8485 2.75217 27.3289C4.32794 16.7586 13.554 8.61896 24.6636 8.61896C25.2626 8.61896 25.7482 8.13831 25.7482 7.54544V3.67135C25.7482 3.44641 25.7705 3.28217 25.7963 3.17038C25.8945 3.23142 26.0276 3.33191 26.1884 3.491L34.5682 11.7857C34.973 12.1862 35.1958 12.7221 35.1958 13.2948C35.1958 13.8673 34.973 14.4032 34.5682 14.8038Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <button className="order-btn-post-details">Order</button>
                    </div>

                    <div>
                        <div className="input-container-post-details">
                            <BsEmojiSmile
                                size={20}
                                onClick={() => setShowPicker((val) => !val)}
                            />
                            <input
                                className="comment-input-post-details"
                                placeholder="comment"
                                value={inputStr}
                                onChange={(e) => setInputStr(e.target.value)}
                            ></input>
                            <button
                                className={`post-btn-post-details ${
                                    inputStr ? '' : 'disabled'
                                }`}
                            >
                Post
                            </button>
                        </div>
                        {showPicker && (
                            <div>
                                <Picker
                                    width={300}
                                    height={350}
                                    className="emoji-container"
                                    pickerStyle={{ width: '80%' }}
                                    onEmojiClick={onEmojiClick}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostDetail;
