import React from 'react';
import { Modal, Box } from '@mui/material';
import './../Styles/Cart/ConfirmationModal.css';

const ConfirmationModal = ({ open, onClose, onConfirm, message }) => {
    return (
        <Modal
            open={open}
            onClose={() => onClose(false)}
            aria-labelledby="modal-title"
        >
            <Box className="pop-up-container">
                <h1 className="already-items-in-cart-heading">Items already in cart</h1>
                <p className="pop-up-message">{message}</p>

                <div className="btn-container-popup">
                    <button
                        className="pop-up-btn-yes"
                        onClick={() => onConfirm(true)} // User confirms
                    >
            YES
                    </button>
                    <button className="pop-up-btn-no" onClick={() => onClose(false)}>
            NO
                    </button>
                </div>
            </Box>
        </Modal>
    );
};

export default ConfirmationModal;
