import '../../VendorStyles/PreparingRightbar.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
function VendorRightSidebar(){
    const [preparingCount, setPreparingCount] = useState(6);
    const [deliveredCount, setDeliveredCount] = useState(80);
    useEffect(() => {
        // Fetch data from the API
        axios.get('http://localhost:5002/api/order/vendor/1')
            .then(response => {
                const ordersWithStatus2 = response.data.allOrdersDetails.filter(order => order.Status_id === 2);
                setPreparingCount(ordersWithStatus2.length);
                const ordersWithStatus4 = response.data.allOrdersDetails.filter(order => order.Status_id === 4);
                setDeliveredCount(ordersWithStatus4.length);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);
    return(
        <><div className="preparing-container1">
            <h5 className="preparing-container1-text-1">{preparingCount}</h5> 
            <h5 className="preparing-container1-text-2">Preparing Orders</h5>
        </div><div className="preparing-container1">
            <h5 className="preparing-container1-text-1">{deliveredCount}</h5>
            <h5 className="preparing-container1-text-2">Orders Delivered</h5>
        </div></> 
    );
}
export default VendorRightSidebar;