// import '../../VendorStyles/Delivered.css';
// // import React, { useState,useEffect } from 'react';

// function Delivered(){
//     const orders = [
//         {  
//             id:6354,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6354,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6354,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6354,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6354,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6354,
//             time:'10:30 AM',
//             date:'22 Mar'

//         },
//         {  
//             id:6354,
//             time:'10:30 AM',
//             date:'22 Mar'

//         }
//     ];
//     // const [orders, setOrders] = useState([]);
//     // useEffect(() => {
//     //     // Fetch data from the API
//     //     fetch('http://localhost:5002/api/order/vendor/1')
//     //         .then(response => response.json())
//     //         .then(data => {
//     //             const formattedOrders = data.address.map(order => {
//     //                 const createdAt = new Date(order.Created_at);
//     //                 const time = createdAt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
//     //                 const date = `${createdAt.getDate()} ${createdAt.toLocaleString('en-US', { month: 'short' })}`;
//     //                 const status = order.Status_id === 1 ? 'Ready to Pickup' : (order.Status_id === 2 ? 'Delivered' : 'Unknown Status');
//     //                 return {
//     //                     id: order.id,
//     //                     time,
//     //                     date,
//     //                     status
//     //                 };
//     //             });
//     //             setOrders(formattedOrders);
//     //         })
//     //         .catch(error => console.error('Error fetching data:', error));
//     // }, []);

//     return(<div className="delivered-main">
//         {orders.map(order => (
//             <div key={order.id} className="delivered-single-order">
//                 <div className='delivered-orderid'><p className='delivered-order-details1'>Order ID:<span className='delivered-order-id'>{order.id}</span></p></div>
//                 <div className='delivered-date-and-time'><p className='delivered-order-details2'>{order.time}</p>
//                     <p className='delivered-order-details3'>{order.date}</p></div>
//                 <button className={`status-btn ${order.status === 'Ready to Pickup' ? 'ready' : 'delivered'}`}>
//                     <p className='status-text'>{order.status}</p>
//                 </button>            </div>
//         ))}
//     </div>
//     );
// }

// export default Delivered;

// import '../../VendorStyles/Delivered.css';
// import React from 'react';

// function Delivered() {
//     const orders = [
//         { id: 6354, time: '10:30 AM', date: '22 Mar', status: 'Ready to Pickup' },
//         { id: 6355, time: '10:30 AM', date: '22 Mar', status: 'Ready to Pickup' },
//         { id: 6356, time: '10:30 AM', date: '22 Mar', status: 'Ready to Pickup' },
//         { id: 6357, time: '10:30 AM', date: '22 Mar', status: 'Delivered' },
//         { id: 6358, time: '10:30 AM', date: '22 Mar', status: 'Delivered' },
//         { id: 6359, time: '10:30 AM', date: '22 Mar', status: 'Delivered' },
//     ];

//     return (
//         <div className="delivered-main">
//             {orders.map(order => {
//                 // console.log(`Rendering order with id: ${order.id}`);
//                 return (
//                     <div key={order.id} className="delivered-single-order">
//                         <div className='delivered-orderid'>
//                             <p className='delivered-order-details1'>
//                                 Order ID:<span className='delivered-order-id'>{order.id}</span>
//                             </p>
//                         </div>
//                         <div className='delivered-date-and-time'>
//                             <p className='delivered-order-details2'>{order.time}</p>
//                             <p className='delivered-order-details3'>{order.date}</p>
//                         </div>
//                         <button className={`status-btn ${order.status === 'Ready to Pickup' ? 'ready' : 'delivered'}`}>
//                             <p className='status-text'>{order.status}</p>
//                         </button>
//                     </div>
//                 );
//             })}
//         </div>
//     );
// }

// export default Delivered;

import '../../VendorStyles/Delivered.css';
import React, { useState,useEffect } from 'react';
import axios from 'axios';
function Delivered(){

    // const vendor_Base_URL = process.env.REACT_APP_VENDOR_API_BASE_URL;
    const vendor_Base_URL = 'http://localhost:5003/api';
    // const orders = [
    //     {  
    //         id:6354,
    //         time:'10:30 AM',
    //         date:'22 Mar'

    //     },
    //     {  
    //         id:6354,
    //         time:'10:30 AM',
    //         date:'22 Mar'

    //     },
    //     {  
    //         id:6354,
    //         time:'10:30 AM',
    //         date:'22 Mar'

    //     },
    //     {  
    //         id:6354,
    //         time:'10:30 AM',
    //         date:'22 Mar'

    //     },
    //     {  
    //         id:6354,
    //         time:'10:30 AM',
    //         date:'22 Mar'

    //     },
    //     {  
    //         id:6354,
    //         time:'10:30 AM',
    //         date:'22 Mar'

    //     },
    //     {  
    //         id:6354,
    //         time:'10:30 AM',
    //         date:'22 Mar'

    //     }
    // ];
    const [orders, setOrders] = useState([]);
    console.log(orders);

    // const deliveredData = async ()=>{
    //     const response =  await axios.get(`${vendor_Base_URL}/order/vendor/1`);
    //     console.log(response.data.allOrdersDetails);
    //     setOrders(response.data.allOrdersDetails);

    // };
    useEffect(() => {

        // deliveredData();
        // Fetch data from the API
        axios.get(`${vendor_Base_URL}/order/vendor/1?pageSize=10&pageNumber=1`)
            .then(response => {
                const formattedOrders = response.data.allOrdersDetails
                    .filter(order => order.Status_id === 6) 
                    .map(order => {
                        const createdAt = new Date(order.Created_at);
                        const time = createdAt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
                        const date = `${createdAt.getDate()} ${createdAt.toLocaleString('en-US', { month: 'short' })}`;
                        const status = order.Status_id === 4 ? 'Ready to Pickup' : 'Delivered';
                        return {
                            id: order.ID,
                            time,
                            date,
                            status
                        };
                    });
                setOrders(formattedOrders);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);
     
    return(<div className="delivered-main"> 
        {orders.map(order => (
            <div key={order.id} className="delivered-single-order">
                <div className='delivered-orderid'><p className='delivered-order-details1'>Order ID:<span className='delivered-order-id'>{order.id}</span></p></div>
                <div className='delivered-date-and-time'><p className='delivered-order-details2'>{order.time}</p>
                    <p className='delivered-order-details3'>{order.date}</p></div>
                    
                <button className='status-btn'>{order.status}</button>            </div>
        ))}
    </div>
    );
}

export default Delivered;
 