import React from 'react';

import './Profilesettings.css';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';

import SwitchAccountBtns from './SwitchAccountBtns';

function Switchaccount() {
    return (
        <>
            <div className="editprofile-top-main">
                <div className="sidebar">
                    <SideMenuBar></SideMenuBar>
                </div>
                <div className="top-bar">
                    <TopMenuBar></TopMenuBar>
                </div>

                <SwitchAccountBtns />
            </div>
        </>
    );
}

export default Switchaccount;
